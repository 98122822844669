import { defineStyle } from '@chakra-ui/react';
import { colors } from '../colors';

export const customLinkStyle = {
	marginLeft: '5px',
	fontWeight: '600',
	fontSize: '16px',
	lineHeight: '100%',
	textDecoration: 'none',
	color: colors.blue,
};

export const passwordFormLinkStyle = {
	cursor: 'pointer',
	textDecoration: 'none',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: '110%',
	color: colors.blue,
	'&:hover': {
		textDecoration: 'none',
	},
};

export const contractHelpLinkStyle = {
	cursor: 'pointer',
	padding: '10.5px 21px',
	textDecoration: 'none',
	fontWeight: 800,
	fontSize: '14px',
	lineHeight: '100%',
	userSelect: 'none',
	color: colors['main-dark'],
	borderRadius: '30px',
	border: '1px solid #d9d8d8',
};

export const navigationLinkStyle = {
	textDecoration: 'none',
	fontFamily: "'Nunito', sans-serif",
	fontWeight: 800,
	fontSize: '20px',
	lineHeight: '100%',
	color: colors['main-dark'],
};

export const dropDownLinkStyle = {
	margin: '10px 0 0',
	padding: '10px 28px',
	display: 'block',
	background: 'transparent',
	textDecoration: 'none',
	transition: 'background 0.35s',
	fontWeight: 600,
	fontSize: '15px',
	lineHeight: '100%',
	color: '#3c3c3c',
	'&:hover': {
		background: '#f4f4f4',
	},
};

export const docsPrimary = defineStyle({
  textDecoration: 'underline',
	fontWeight: 600,
	fontSize: '14px',
	lineHeight: '24px',
	letterSpacing: '-0.014px',
	fontFamily: `'Poppins', sans-serif`,
	color: colors['primary-blue-100'],
	_hover: { textDecoration: 'none' },
});

export const resetPassword = defineStyle({
  textDecoration: 'underline',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '22px',
	fontFamily: `'Poppins', sans-serif`,
	color: colors['primary-blue-100'],
	_hover: { textDecoration: 'none' },
});
