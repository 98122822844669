import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import localization from '../../../utils/localization';

type Props = {
	children: React.ReactNode | JSX.Element | JSX.Element[];
};

function WithLocalization({ children }: Props) {
	const { i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(localization.language);
	}, [i18n]);

	return children;
}

export { WithLocalization };
