import { gql } from '@apollo/client';

export const GET_ALL_LANGUAGES = gql`
	query GetAllLanguages {
		getAllLanguages {
			language_id
			language
			keyword
		}
	}
`;
