import { gql } from '@apollo/client';

export const RESENT_VERIFICATION_EMAIL = gql`
	mutation ResentVerificationEmail {
		operation: resentVerificationEmail {
			success
			error
		}
	}
`;
