import { Box, Flex, Link, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  toggleConfirmRules: () => void;
  isAcceptPolicy: boolean;
  hasPolicyError: boolean;
}

export const InfoWithTermsAndPolicy: FC<Props> = memo(({
  toggleConfirmRules,
  isAcceptPolicy,
  hasPolicyError,
}) => {
  const { t } = useTranslation();

  return (
    <Flex w="100%" direction="column" mb="24px">
      <Flex w="100%" align="center" gap="12px" mb="12px">
        <Box
          cursor="pointer"
          onClick={toggleConfirmRules}
        >
          {
            isAcceptPolicy
              ? FilledCheckBoxIcon()
              : CheckBoxIcon(colors[hasPolicyError ? 'light-text-text-danger' : 'primary-gray-100'])
          }
        </Box>

        <Text
          size="poppins14"
          variant="primary-black-text"
          flex={1}
          lineHeight="24px"
        >
          {t('By countinuing, you are setting up a Passion Creators account and agree to our ')}
          <Link
            href='/info/terms'
            target='_blank'
            rel='noreferrer'
            variant="docsPrimary"
            _hover={{ textDecoration: 'none' }}
          >
            {t('Terms of Use')}
          </Link>

          {t(' and ')}

          <Link
            href='/info/privacy_policy'
            target='_blank'
            rel='noreferrer'
            variant="docsPrimary"
          >
            {t('Privacy Policy')}
          </Link>
        </Text>
      </Flex>

      {hasPolicyError && (
        <Text
          size="poppins14"
          variant="light-text-text-danger"
          flex={1}
          lineHeight="24px"
          ml="36px"
        >
          {t('We need your agreement first')}
        </Text>
      )}
    </Flex>
  );
});
