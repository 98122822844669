import { keyframes } from '@chakra-ui/react';
import { colors } from '../colors';

const appear = keyframes({
	from: { opacity: 0 },
	to: { opacity: 1 },
});

export const modalStyle = {
	baseStyle: {
		dialog: {
			height: '376px',
			padding: '32px 28px',
			opacity: 1,
			borderRadius: '20px',
			animation: `${appear} 0.2s linear`,
		},
		body: {
			fontFamily: 'Nunito Sans',
			fontSize: '16px',
			lineHeight: '22px',
			padding: '0',
		},
		header: {
			fontFamily: 'Nunito Sans',
			fontWeight: 800,
			fontSize: '28px',
			lineHeight: '36px',
			margin: '0 0 16px',
			padding: '0',
		},
		footer: {
			display: 'flex',
			gap: '8px',
			margin: '0',
			padding: '0',
		},
	},
	variants: {
		contentGuidline: {
			dialog: {
				height: '512px',
				w: '600px',
				minW: '600px',
				padding: '0',
				opacity: 1,
				borderRadius: '8px',
				animation: `${appear} 0.2s linear`,
				boxShadow: '0px 5px 15px 0px rgba(36, 28, 88, 0.20)',
			},
			dialogContainer: {
				p: '30px 0 65px',
				'&::-webkit-scrollbar': {
					w: '17px',
					bg: 'trasparent',
				},
				'&::-webkit-scrollbar-track': {
					mt: '8px',
					mb: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '6px',
					borderRightRadius: '9px',
					bg: colors['primary-gray-40'],
					borderLeft: `3px solid transparent`,
					borderRight: `7px solid transparent`,
					backgroundClip: 'content-box',
				},
			},
			body: {
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
				padding: '0',
				'&::-webkit-scrollbar': {
					w: '11px',
				},
				'&::-webkit-scrollbar-track': {
					mt: '5px',
					mb: '5px',
					borderTopRightRadius: '200px',
					borderBottomRightRadius: '200px',
					borderLeft: '1px solid #E8E8E8',
					bg: '#FCFCFC',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '25px',
					p: '2px',
					borderRight: '2px solid transparent',
					borderLeft: '3px solid transparent',
					borderTop: '5px solid transparent',
					borderBottom: '5px solid transparent',
					bg: `#7A7A7A`,
					backgroundClip: 'content-box',
				},
			},
		},
		leaveWithoutSaving: {
			dialog: {
				w: '600px',
				minW: '600px',
				h: 'fit-content',
				padding: '84px 85px 44px',
				opacity: 1,
				borderRadius: '16px',
				animation: `${appear} 0.2s linear`,
				boxShadow: '0px 5px 15px 0px rgba(36, 28, 88, 0.20)',
			},
			body: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
			},
			dialogContainer: {
				'&::-webkit-scrollbar': {
					w: '17px',
					bg: 'trasparent',
				},
				'&::-webkit-scrollbar-track': {
					mt: '8px',
					mb: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '6px',
					borderRightRadius: '9px',
					bg: colors['primary-gray-40'],
					borderLeft: `3px solid transparent`,
					borderRight: `7px solid transparent`,
					backgroundClip: 'content-box',
				},
			},
		},
		storyPreview: {
			dialog: {
				w: '900px',
				minW: '900px',
				minH: '692px',
				maxH: '692px',
				padding: '52px 24px 24px 85px',
				opacity: 1,
				borderRadius: '8px',
				animation: `${appear} 0.2s linear`,
				boxShadow: `0px 5px 15px 0px rgba(36, 28, 88, 0.20)`,
				border: `1px solid ${colors.stroke}`
			},
			body: {
				pr: '80px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
				'&::-webkit-scrollbar': {
					w: '6px',
				},
				'&::-webkit-scrollbar-track': {
					mt: '8px',
					mb: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '24px',
					bg: colors['primary-gray-40'],
					backgroundClip: 'content-box',
				},
			},
		},
		splitView: {
			dialog: {
				w: '93vw',
				minW: '93vw',
				minH: '90vh',
				maxH: '90vh',
				padding: '60px 10px 24px 24px',
				opacity: 1,
				borderRadius: '8px',
				animation: `${appear} 0.2s linear`,
				boxShadow: `0px 5px 15px 0px rgba(36, 28, 88, 0.20)`,
				border: `1px solid ${colors.stroke}`
			},
			body: {
				display: 'flex',
				flexDirection: 'column',
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
				'&::-webkit-scrollbar': {
					w: '14px',
				},
				'&::-webkit-scrollbar-track': {
					mt: '8px',
					mb: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '24px',
					bg: colors['primary-gray-40'],
					backgroundClip: 'content-box',
				},
			},
		},
		locales: {
			dialog: {
				w: '972px',
				minW: '972px',
				minH: '724px',
				maxH: '724px',
				padding: '72px 44px 24px',
				opacity: 1,
				borderRadius: '16px',
				animation: `${appear} 0.2s linear`,
				boxShadow: `0px 5px 15px 0px rgba(36, 28, 88, 0.20)`,
				border: `1px solid ${colors.stroke}`,
			},
			body: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				fontSize: '16px',
				lineHeight: '22px',
				'&::-webkit-scrollbar': {
					w: '14px',
				},
				'&::-webkit-scrollbar-track': {
					mt: '8px',
					mb: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					borderRadius: '24px',
					bg: colors['primary-gray-40'],
					backgroundClip: 'content-box',
				},
			},
		},
		avatarEditor: {
			dialog: {
				w: '648px',
				minW: '648px',
				h: 'fit-content',
				padding: '72px 24px 38px',
				opacity: 1,
				borderRadius: '8px',
				animation: `${appear} 0.2s linear`,
				boxShadow: '0px 5px 15px 0px rgba(36, 28, 88, 0.20)',
			},
			body: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				fontFamily: 'Nunito Sans',
				fontSize: '16px',
				lineHeight: '22px',
			},
		},
	},
};
