import { Button } from '@chakra-ui/react';
import CopyCheckBoxIcon from 'components/Icons/ADMIN/CopyCheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  handleCopyId: () => void;
  baseName?: string;
};

export const CopyIdButton: FC<Props> = ({
  handleCopyId,
  baseName = 'Copy User ID'
}) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [hover, setHover] = useState(false);

  
  const handleCopy = useCallback(() => {
    setCopied(true);
    handleCopyId();

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [handleCopyId]);

  return (
    <Button
      variant="outlineButton"
      color={copied ? colors['primary-blue-100'] : colors['primary-violet-100']}
      borderColor={copied ? colors['primary-blue-100'] : colors['primary-violet-100']}
      onClick={handleCopy}
      gap="12px"
      px="0"
      justifySelf="end"
      _hover={copied
        ? {
          borderColor: colors['primary-blue-100'],
          color: colors['primary-blue-100'],
        }
        : {
          borderColor: colors['button-hover'],
          color: colors['button-hover'],
        }
      }
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >

      {t(copied ? 'Copied' : baseName)}
      {copied
        ? FilledCheckBoxIcon()
        : hover
          ? CopyCheckBoxIcon(colors['button-hover'])
          : CopyCheckBoxIcon()
      }
    </Button>
  );
};
