import { colors } from 'shared/utils/chakra-theme/colors';

export const baseScrollBar = {
  '&::-webkit-scrollbar': {
    w: '17px',
    bg: 'trasparent',
  },
  '&::-webkit-scrollbar-track': {
    mt: '8px',
    mb: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '6px',
    borderRightRadius: '9px',
    bg: colors['primary-gray-40'],
    borderLeft: `3px solid transparent`,
    borderRight: `7px solid transparent`,
    backgroundClip: 'content-box',
  },
};


export const contractScrollBar = {
  '&::-webkit-scrollbar': {
    w: '17px',
    bg: 'trasparent',
  },
  '&::-webkit-scrollbar-track': {
    mt: '86px',
    mb: '8px',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '6px',
    borderRightRadius: '9px',
    bg: colors['primary-gray-40'],
    borderLeft: `3px solid transparent`,
    borderRight: `7px solid transparent`,
    backgroundClip: 'content-box',
  },
};