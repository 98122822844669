import { gql } from '@apollo/client';

export const RESET_USER_PASSWORD = gql`
	mutation CreatePasswordResetFlow($email: String!) {
		result: createPasswordResetFlow(email: $email) {
			error
			success
		}
	}
`;
