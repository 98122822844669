export enum EVENT_TYPES {
  // #region :Show Events:
  LOGIN_SHOWN = 'login_shown',
  SIGNUP_SHOWN = 'signup_shown',
  SIGNUP_NAME_SHOWN = 'signup_name_shown',
  MY_STORIES_SHOWN = 'my_stories_shown',
  MY_STORIES_GUIDELINES = 'my_stories_guidelines',
  MY_STORIES_BOOK_CREATION = 'my_stories_book_creation',
  INCOME_SHOWN = 'income_shown',
  NOTIFICATIONS_SHOWN = 'notifications_shown',
  PROFILE_OPEN = 'profile_open',
  HELP_SHOWN = 'help_shown',
  // #endregion

  // #region :Book Events:
  MY_STORIES_INFO = 'my_stories_info',
  MY_STORIES_EDITOR = 'my_stories_editor',
  MY_STORIES_EDIT_DETAILS = 'my_stories_edit_details',
  MY_STORIES_DELETE_REQUEST_CLICK = 'my_stories_delete_request_click',
  MY_STORIES_SUBMIT_FOR_REVIEW_CLICK = 'my_story_submit_for_review_click',
  SENT_FOR_REVIEW = 'sent_for_review',
  // #endregion

  // #region :Contract Events:
  CONTRACT_SIGNING_START = 'contract_signing_start',
  CONTRACT_SIGNING_STEP = 'contract_signing_step',
  // #endregion

  // #region :Payment Events:
  PAYMENT_INFO_CHANGED = 'payment_info_changed'
  // #endregion
};
