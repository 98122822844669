import { Button, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: () => void;
};

export const CreateStoryButton: FC<Props> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Button
      type="button"
      variant="defaultButton"
      width="fit-content"
      p="12px 34px"
      onClick={onClick}
    >
      {t('Create a story')}

      <Image
        src="/images/hero/book.svg"
        alt="book"
        ml="12px"
      />
    </Button>
  );
};
