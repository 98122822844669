import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  title: string;
}

export const PolicyPoint: FC<Props> = ({
  stage,
  title,
}) => {
  return (
    <Flex gap="4px" mb="24px">
      <Text
        size="poppins16"
        variant="primary-black-text"
        textAlign="left"
        lineHeight="24px"
        fontWeight={600}
      >
        {stage}
      </Text>

      <Text
        size="poppins16"
        variant="primary-black-text"
        textAlign="left"
        lineHeight="24px"
        fontWeight={600}
      >
        {title}
      </Text>
    </Flex>
  );
};
