import { gql } from '@apollo/client';

export const GET_ALL_BOOKS = gql`
	query GetALLBooks(
		$search: String,
		$limit: Int,
		$page: Int,
		$status: String,
		$deleted: Boolean,
	) {
		books: booksAdminSearch(
			limit: $limit
			page: $page
			alias: "books"
			where: [{ books: { status: $status, deleted: $deleted } }]
			search: [{ books: { title: $search } }]
			relations: ["tags", "genres", "language", "chapters", "creator_id"]
			orders: [
				{ field: "books.status", type: "DESC" }
				{ field: "books.title", type: "ASC" }
			]
		) {
			cover_link
			title
			book_id
			language {
				language_id
				keyword
			}
			creator_id {
				user_id
				first_name
				last_name
				pen_name
			}
			status
			deleted
		}
	}
`;

export const GET_BOOKS_ALL = gql`
	query GetBooks($search: String, $limit: Int, $page: Int) {
		booksSearch(
			limit: $limit
			page: $page
			alias: "books"
			where: [{ books: { deleted: false } }]
			search: [{ books: { title: $search } }]
			relations: ["tags", "genres", "language", "chapters"]
			orders: [
				{ field: "books.status", type: "DESC" }
				{ field: "books.title", type: "ASC" }
			]
		) {
			cover_link
			title
			book_id
			language {
				language_id
				keyword
			}
			creator_id {
				user_id
				first_name
				last_name
				pen_name
			}
			status
		}
	}
`;

export const GET_BOOKS_USER = gql`
	query AuthorBooks($limit: Int, $page: Int) {
		books: authorBooks(page: $page, limit: $limit) {
			cover_link
			title
			description
			book_id
			likes
			deleted
			language {
				language_id
				keyword
			}
			read_count
			tags {
				tag_id
				title
			}
			genres {
				genre_id
				title
			}
			chapters {
				chapter_id
			}
			updated_at
			status
			published
			firstChapterId
			book_contract {
				contract_status
				external_contract_description
				contract_link
				reject_reason
				permanent_reject
				isVerified
				type
			}
		}
	}
`;

export const GET_SOURCER_BOOKS = gql`
	query SourcerBooks($limit: Int, $page: Int) {
		books: sourcerBooks(page: $page, limit: $limit) {
			cover_link
			title
			description
			book_id
			likes
			deleted
			language {
				language_id
				keyword
			}
			read_count
			tags {
				tag_id
				title
			}
			genres {
				genre_id
				title
			}
			chapters {
				chapter_id
			}
			updated_at
			status
			published
			firstChapterId
			book_contract {
				contract_status
				external_contract_description
				contract_link
				reject_reason
				permanent_reject
				isVerified
				type
			}
		}
	}
`;
