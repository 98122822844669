import { gql } from '@apollo/client';

export const GET_ALL_GENRES = gql`
	query GetAllGenres {
		getAllGenres {
			genre_id
			title
			link
			icon_link
		}
	}
`;
