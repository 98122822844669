import { setInLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { LoginDataDto } from 'utils/types/user';

export function handleSetData(loginDataDto: LoginDataDto) {
	const { user_id, email, role } = loginDataDto;

	setInLocalStorage('user_id', user_id);
	setInLocalStorage('role', role);

	if (!window.db_userId) window.db_userId = user_id;
	if (!window.userEmail) window.userEmail = email;
}
