import { defineStyle } from '@chakra-ui/react';
import { colors } from '../colors';

export const chapterTable = defineStyle({
  table: {
    tr: {
      borderBottom: `1px solid ${colors.stroke}`,
      display: 'flex',
      width: '100%',
    },
    th: {
      fontFamily: `'Poppins', sans-serif`,
      color: colors['primary-gray-100'],
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '-0.01em',
      padding: '12px 0',
      fontWeight: 400,
      textTransform: 'none'
    },
    td: {
      fontFamily: `'Poppins', sans-serif`,
      color: colors['primary-black-text'],
      fontSize: '18px',
      lineHeight: '34px',
      letterSpacing: '-0.01em',
      fontWeight: 400,
    }
  },
});