import { Navigate, useRoutes } from 'react-router';
import { lazy } from 'react';
import { handleRedirectRoute } from 'utils/helpers/handleRedirectRoute';
import { handleRole } from 'utils/helpers/handleRole';

const StoryPreview = lazy(() => import('pages/WRITER/StoryPreview'));
const EditStoryDetails = lazy(() => import('pages/WRITER/EditStoryDetails'));
const EditChapters = lazy(() => import('pages/WRITER/EditChapters'));
const Story = lazy(() => import('pages/WRITER/Story'));
const Income = lazy(() => import('pages/WRITER/Income'));
const Notifications = lazy(() => import('pages/WRITER/Notifications'));
const Notification = lazy(() => import('pages/WRITER/Notification'));
const HelpCenter = lazy(() => import('pages/WRITER/HelpCenter'));
const SubmitStory = lazy(() => import('pages/WRITER/SubmitStory'));
const Profile = lazy(() => import('pages/WRITER/Profile'));
const ContractPage = lazy(() => import('pages/WRITER/Contract'));
const MyStories = lazy(() => import('pages/WRITER/MyStories'));
const ContentGuidlines = lazy(() => import('pages/WRITER/ContentGuidlines'));
const ChapterUploadGuidline = lazy(() => import('pages/WRITER/ChapterUploadGuidline'));
const CreateStory = lazy(() => import('pages/WRITER/CreateStory'));
const ContractPreview = lazy(() => import('pages/WRITER/ContractPreview'));

const WriterRoutes = () => {
	const routes = useRoutes([
		{
			path: '*',
			element: <Navigate to='/writer/my-stories' />,
		},
		{
			path: 'profile',
			children: [
				{ index: true, element: <Profile /> },
			],
		},
		{
			path: 'income',
			children: [
				{ index: true, element: <Income /> },
			],
		},
		{
			path: 'notifications',
			children: [
				{ index: true, element: <Notifications /> },
				{ path: ':notification_id', element: <Notification /> },
			],
		},
		{
			path: 'help',
			children: [
				{ index: true, element: <HelpCenter /> },
			],
		},
		{
			path: 'my-stories',
			children: [
				{ index: true, element: <MyStories /> },
			],
		},
		{
			path: 'create-story',
			children: [
				{
					path: 'content-guidlines',
					element: <ContentGuidlines />,
				},
				{ index: true, element: <CreateStory /> },
			],
		},
		{
			path: 'story',
			children: [
				{
					path: ':book_id',
					children: [
						{
							path: 'edit-details',
							element: <EditStoryDetails />,
						},
						{
							path: 'read/:chapter_id',
							element: <StoryPreview />,
						},
						{
							path: 'edit/:chapter_id',
							element: <EditChapters />,
						},
						{
							path: 'contract/:contract_type',
							element: <ContractPage />,
						},
						{
							path: 'upload/guidline',
							element: <ChapterUploadGuidline />,
						},
						{
							path: 'view-contract',
							element: <ContractPreview />,
						},
						{
							path: 'submit',
							element: <SubmitStory />,
						},
						{ index: true, element: <Story /> },
					],
				},
			],
		},
	]);

	const isWriter = handleRole().writer;
	const redirectRoute = handleRedirectRoute() || '/auth/log-in';

	return isWriter
		? routes
		: <Navigate to={redirectRoute} />;
};

export default WriterRoutes;
