export const dashboardUpdates = [
  {
    id: 1,
    point: 'Enhanced Book Analytics:',
    description: 'Get a deeper dive into your book\'s performance with detailed information on reads, likes, revenue, and status of reviews.',
  },
  {
    id: 2,
    point: 'Simplified Withdrawal Requests:',
    description: 'Easily request withdrawals with our hassle-free process.',
  },
  {
    id: 3,
    point: 'Built-in Grammar Checker:',
    description: 'Proofread and edit your chapters to ensure a top-notch reading experience for your audience.',
  },
  {
    id: 4,
    point: 'Contract Submission:',
    description: 'Submit your new book for review with our streamlined contract application process.',
  },
];
