import { useMutation } from '@apollo/client';
import LoadingSpinner from 'components/UI/LoadingSpinner';
import { APPLY_FOR_CONTEST_FROM_EMAIL } from 'entites/notifications/notifications.graphql';
import useShowToast from 'hooks/useShowToast';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const REDIRECT_TIMEOUT = 5000;

const ContestApplication = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { token } = useParams();
	const {
		showErrorToast,
		showSuccessToast,
	} = useShowToast();
	const navigate = useNavigate();

	const [applyForContest] = useMutation(APPLY_FOR_CONTEST_FROM_EMAIL);

	const applyUserForContest = useCallback(async () => {
		const { data: {
			result,
		}} = await applyForContest({
			variables: { user_id: token },
		});

		if (result.success) {
      showSuccessToast({
        id: "successfullySendContestApplicationFromEmail",
        title: `The application was successfully sent`,
        description: result.success,
        img: "/images/story/paperplane.png",
      });

			setIsLoading(false);
			setTimeout(() => {
				navigate('writer');
			}, REDIRECT_TIMEOUT);

      return;
    }

    if (result.error) {
      showErrorToast({
        id: "definedErrorSendContestApplicationFromEmail",
        title: "Something went wrong",
        reason: result.error,
      });

			setIsLoading(false);
			setTimeout(() => {
				navigate('writer');
			}, REDIRECT_TIMEOUT);

      return;
    }

    showErrorToast({
      id: "undefinedErrorSendContestApplicationFromEmail",
      title: "Sorry something went wrong",
      reason: "Please, try again later",
    });

		setIsLoading(false);
		setTimeout(() => {
			navigate('writer');
		}, REDIRECT_TIMEOUT);
	}, [applyForContest, token]);

	useEffect(() => {
		if (token) {
			applyUserForContest();
		}
	}, [applyUserForContest, token]);

	return <>{isLoading && <LoadingSpinner />}</>;
};

export default ContestApplication;
