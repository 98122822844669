import { Box, useToast } from '@chakra-ui/react';
import { ErrorToast } from 'components/Toasts/ErrorToast';
import { ChapterAutosaveToast } from '../components/Toasts/ChapterAutosaveToast';
import { VerifyToast } from '../components/Toasts/VerifyToast';

interface BaseToastDto {
  id: string;
}

interface ErrorToastDto extends BaseToastDto {
  title: string;
  reason: string;
  hasSecondToast?: boolean;
}

interface SuccessToastDto extends BaseToastDto {
  title: string;
  description: string;
  img: string;
  hasSecondToast?: boolean;
  isShort?: boolean;
}

function useShowToast() {
  const toast = useToast();

  const showErrorToast = ({
    id,
    title,
    reason,
    hasSecondToast = false,
  }: ErrorToastDto) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        position: 'top',
        render: ({ onClose }) => (
          <Box mt="61px" mb={hasSecondToast ? '-55px' : '0'}>
            <ErrorToast
              onClose={onClose}
              title={title}
              reason={reason}
            />
          </Box>
        ),
        duration: 5000,
      });
    }
  };

  const showChapterAutoSaveToast = ({
    id,
  }: BaseToastDto) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        position: 'top',
        render: () => (
          <Box mt="171px" onClick={() => toast.close(id)}>
            <ChapterAutosaveToast />
          </Box>
        ),
        duration: 2500,
      });
    }
  };

  const showSuccessToast = ({
    id,
    title,
    description,
    img,
    hasSecondToast = false,
    isShort = false,
  }: SuccessToastDto) => {
    if (!toast.isActive(id)) {
      toast({
        id,
        position: 'top',
        render: ({ onClose }) => (
          <Box mt="61px" mb={hasSecondToast ? '-55px' : '0'}>
            <VerifyToast
              onClose={onClose}
              title={title}
              description={description}
              img={img}
              isShort={isShort}
            />
          </Box>
        ),
        duration: 5000,
      });
    }
  };

  return {
    showErrorToast,
    showChapterAutoSaveToast,
    showSuccessToast,
  };
}

export default useShowToast;