import { Heading } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  title: string;
  href: string;
}

export const ContentTableItem: FC<Props> = ({
  stage,
  title,
  href,
}) => {
  return (
    <Heading
      as="a"
      size="poppins18"
      variant="primary-black-text"
      textAlign="left"
      lineHeight="24px"
      href={`#${href}`}
    >
      {stage} <u>{title}</u>
    </Heading>
  );
};
