import { Heading } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  title: string;
}

export const Section: FC<Props> = ({
  stage,
  title,
}) => {
  return (
    <Heading
      size="poppins20"
      variant="primary-black-text"
      textAlign="left"
      mb="16px"
    >
      {stage} <u>{title}</u>
    </Heading>
  );
};
