import { Flex, Text } from '@chakra-ui/react';
import { CSSProperties, FC } from 'react';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  textA?: string;
  textB?: string;
  textC?: string;
  title?: string;
  bookTitle?: string;
  createdBy: string;
  handleReadFull: () => void;
};

export const BodyWithBookAndAuthor: FC<Props> = ({
  textA,
  textB,
  textC,
  bookTitle,
  createdBy,
  title,
  handleReadFull,
}) => {
  return (
    <Flex
    direction="column"
    gap="5px"
  >
    <Text
      variant="primary-black-text"
      size="poppins16"
      lineHeight="22px"
      fontWeight={600}
      letterSpacing="-0.01em"
      _hover={{
        color: colors['button-hover']
      }}
      cursor="pointer"
      onClick={handleReadFull}
      display='-webkit-box'
      sx={{
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
      } as CSSProperties}
      overflow="hidden"
      textOverflow="ellipsis"
      wordBreak="break-word"
    >
      {title}
    </Text>

    <Text
      variant="primary-black-text"
      size="poppins14"
      lineHeight="21px"
      letterSpacing="-0.01em"
      w="100%"
      display='-webkit-box'
      sx={{
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      } as CSSProperties}
      overflow="hidden"
      textOverflow="ellipsis"
      wordBreak="break-word"
    >
      {Boolean(textA) && textA}
      {bookTitle && (
        <>
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins14"
            fontWeight={700}
            lineHeight="21px"
            letterSpacing="-0.01em"
          >
            {bookTitle}
          </Text>
          <br />
        </>
      )}
      {Boolean(textB) && textB}
      <Text
        as="span"
        variant="primary-black-text"
        size="poppins14"
        fontWeight={700}
        lineHeight="21px"
        letterSpacing="-0.01em"
      >
        {createdBy}
      </Text>
      {Boolean(textC) && textC}
    </Text>
  </Flex>
  );
};
