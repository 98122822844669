import { EMAILS } from 'constants/email/Email';

export const EmailTemplates = {
	SIMPLE: `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm`,
	RESET_PASSWORD: `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Reset password&body=Dear Mr/Mrs%0AI am sending you this written notice to reset my password.`,
	WITHDRAW: `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Withdraw`,
	BOOK_CONTRACT: (title: string, id: string) => `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Book contract file&body=Hello%0Aplease add a contract file for my book%0A%0Atitle - ${title}%0Aid - ${id}`,
	CHANGE_EMAIL: (user_id: string, email: string) => `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Email change request&body=Hello%0AI want to change my email for reason:%0A%0A%0AMy id - ${user_id}%0ACurrent email - ${email}`,
	CONTRACT_SUPPORT: (user_id: string) => `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Book contract support&body=Hello%0AI need help with my book contract%0A%0A%0AMy account id - ${user_id}`,
	FINANCIAL_SUPPORT: (user_id: string) => `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Revenue support&body=Hello%0AI need help with revenue on my account%0A%0A%0AMy account id - ${user_id}`,
	PERMISSION_PROBLEMS: (user_id: string) => `https://mail.google.com/mail/u/0/?fs=1&to=${EMAILS.support}&tf=cm&su=Access support&body=Hello%0AI need help with permission to the Passion on my account%0A%0A%0AMy account id - ${user_id}`,
};
