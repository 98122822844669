import { Flex, Image } from '@chakra-ui/react';
import { FC } from 'react';
import { colors } from 'shared/utils/chakra-theme/colors';

type IconProps = {
  isOpened: boolean;
  img: string;
}

export const Icon: FC<IconProps> = ({
  isOpened,
  img,
}) => {
  return (
    <Flex
      h="48px"
      w="48px"
      align="center"
      justify="center"
      borderRadius="50%"
      bg={isOpened ? colors['primary-violet-100'] : 'linear-gradient(180deg, #B86BF8 0%, #7F5DD9 100%)'}
    >
      <Image
        src={img}
        loading="eager"
        alt="notification"
      />
    </Flex>
  );
};

type OneColorIconProps = {
  color: string;
  img: string;
}

export const OneColorIcon: FC<OneColorIconProps> = ({
  color,
  img,
}) => {
  return (
    <Flex
      h="48px"
      w="48px"
      align="center"
      justify="center"
      borderRadius="50%"
      bg={color}
    >
      <Image
        src={img}
        loading="eager"
        alt="notification"
      />
    </Flex>
  );
};