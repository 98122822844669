import { Button, Image } from '@chakra-ui/react';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	loginViaGoogleHandler: () => void;
	isSignUp?: boolean;
	isAcceptPolicy?: boolean;
	handleShowPolicyError?: () => void;
};

export const GoogleButton: FC<Props> = memo(({
	loginViaGoogleHandler,
	isSignUp = false,
	isAcceptPolicy = false,
	handleShowPolicyError = undefined,
}) => {
	const { t } = useTranslation();
	const isClickAllowed = !isSignUp || isSignUp && isAcceptPolicy;

	return (
		<Button
			type='button'
			onClick={isClickAllowed ? loginViaGoogleHandler : handleShowPolicyError}
			variant="googleButton"
			cursor="pointer"
		>
			<Image
				src='/images/authorization/google-logo.svg'
				alt='google-logo-login'
			/>

			{t('Continue with Google')}
		</Button>
	);
});

