import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { UserContext } from 'context/UserContext';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { useNavigate } from 'react-router-dom';
import { FullCustomProps, ShortCustomProps, ShortCustomToastProps } from 'utils/types/notifications/templates';
import { OneColorIcon } from '../Story/Icon';
import { Body } from '../UI/Toast/Body';
import { ToastWrapper } from '../UI/Wrapper/ToastWrapper';
import { removeEscapedText } from 'utils/helpers/escapedText/removeEscapedText';

export const CustomToast: FC<Required<ShortCustomToastProps>> = ({
  id,
  title,
  text,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Body
        text={removeEscapedText(text)}
        title={t(title)}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const CustomShort: FC<ShortCustomProps> = ({
  id,
  isSelected,
  user_readed,
  title,
  text,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {title}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {removeEscapedText(text)}
        </Text>
      </Flex>
    </Flex>
  );
};

export const CustomFull: FC<FullCustomProps> = ({
  title,
  text,
  bookTitle,
  createdAt,
  createdBy,
  createdByEmail,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  const hasBook = Boolean(bookTitle);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['primary-violet-30']}
              img="/images/hero/heart.svg"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {createdBy}
            </Text>
            {` <${createdByEmail}>`}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src={hasBook ? "/images/story/leave-without-saving.png" : '/images/contract/notebook.png'}
        loading="eager"
        alt="icon"
      />

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={400}
      >
        {t(`Hi, ${user?.first_name}!`)}

        {hasBook && (
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins24"
            lineHeight="36px"
            letterSpacing={0}
            fontWeight={400}
            textAlign="center"
          >
            {t(' This is notice about the book ')}
            <Text
              variant="primary-black-text"
              size="poppins24"
              lineHeight="36px"
              letterSpacing={0}
              fontWeight={700}
            >
              {bookTitle}
            </Text>
          </Text>
        )}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="22px"
        letterSpacing="-0.01em"
        fontWeight={600}
      >
        {title}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        maxW="580px"
        mb="20px"
        textAlign="center"
        whiteSpace="pre-wrap"
      >
        {removeEscapedText(text)}
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};