import { gql } from '@apollo/client';

export const DELETE_CHAPTER = gql`
	mutation DeleteChapter($chapter_id: String!) {
		deleteChapter(chapter: { chapter_id: $chapter_id }) {
			chapter_id
			status
			deleted
		}
	}
`;
