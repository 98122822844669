import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { AUTH_STATE } from 'utils/enums/auth-state.enum';
import { LogIn } from '../LogIn';
import { SignUp } from '../SignUp';
import { ResetPassword } from '../ResetPassword';
import { LoginCredentials, SignUpCredentials } from 'utils/types/user';

type Props = {
  authState: AUTH_STATE;
  credentials: LoginCredentials;
  handleSetCredentials: (data: LoginCredentials) => void;
  signUpStep: number;
  isLoginLoading: boolean;
  isUserCreationLoading: boolean;
  changeSignUpStep: (step: number) => void;
  loginViaGoogleHandler: () => void;
  loginViaFacebookHandler: () => void;
  handleLogin: (values: LoginCredentials) => void;
  handleCreateAccount: (values: SignUpCredentials) => void;
};

export const AuthFormLayout: FC<Props> = ({
  authState,
  credentials,
  handleSetCredentials,
  signUpStep,
  isLoginLoading,
  isUserCreationLoading,
  changeSignUpStep,
  loginViaGoogleHandler,
  loginViaFacebookHandler,
  handleLogin,
  handleCreateAccount,
}) => {
  switch (authState) {
		case AUTH_STATE.LOG_IN: {
			return (
				<LogIn
          loginViaGoogleHandler={loginViaGoogleHandler}
          loginViaFacebookHandler={loginViaFacebookHandler}
          handleLogin={handleLogin}
          isLoginLoading={isLoginLoading}
				/>
      );
    }
		case AUTH_STATE.SIGN_UP: {
			return (
				<SignUp
          loginViaGoogleHandler={loginViaGoogleHandler}
          loginViaFacebookHandler={loginViaFacebookHandler}
          credentials={credentials}
          handleSetCredentials={handleSetCredentials}
          signUpStep={signUpStep}
					changeSignUpStep={changeSignUpStep}
          handleCreateAccount={handleCreateAccount}
          isUserCreationLoading={isUserCreationLoading}
        />
			);
		}
		case AUTH_STATE.RESET_PASSWORD: {
			return (
				<ResetPassword />
			);
		}
		default: {
			return <Navigate to="/auth/log-in"/>;
		}
	}
};
