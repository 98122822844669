import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { CSSProperties, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import {
  AdminNotifToastProps,
  FullPaymentPropsWithId,
  ShortPropsAdmin,
} from '../../../../../utils/types/notifications/templates';
import { useNavigate } from 'react-router-dom';
import { OneColorIcon } from '../../Story/Icon';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { BodyWithBookAndAuthor } from '../../UI/Toast/BodyWithBookAndAuthor';

export const NewPaymentDataChangeRequestToast: FC<AdminNotifToastProps> = ({
  id,
  handleClose,
  createdBy
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/admin/inbox/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
          img="/images/notifications/revenue/revenue.png"
        />
      </Box>

      <BodyWithBookAndAuthor
        title={t('Change Payment Information Request')}
        textA={t('from ')}
        createdBy={createdBy}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const NewPaymentDataChangeRequestShort: FC<ShortPropsAdmin> = ({
  id,
  isSelected,
  user_readed,
  createdBy,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
          img="/images/notifications/revenue/revenue.png"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Change Payment Information Request')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('from ')}
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins16normal"
            fontWeight={600}
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {createdBy}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};

export const NewPaymentDataChangeRequestFull: FC<FullPaymentPropsWithId> = ({
  createdAt,
  createdBy,
  createdByEmail,
  user_id,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const navigate = useNavigate();

  const handleOpenRequest = useCallback(() => {
    navigate(`/admin/content/author/${user_id}/edit`);
  }, [user_id]);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      minH="calc(100vh - 90px - 44px)"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={'linear-gradient(180deg, #8DFC8A 0%, #34CE31 100%)'}
              img="/images/notifications/revenue/revenue.png"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {createdBy}
            </Text>
            {` <${createdByEmail}>`}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/notifications/paymentData/card.png"
        loading="eager"
        alt="welcome"
      />

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="22px"
        letterSpacing="-0.01em"
        fontWeight={600}
      >
        {t('Change Payment Information Request')}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={400}
        textAlign="center"
      >
        {t('from ')}
        <Text
          as="span"
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={700}
        >
          {createdBy}
        </Text>
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        maxW="580px"
        mb="20px"
        textAlign="center"
      >
        {t('The author changed the data of the information for payment of fees, which needs verification')}
      </Text>

      <Button
        variant="defaultButton"
        onClick={handleOpenRequest}
      >
        {t('Go to User Profile')}
      </Button>
    </Flex>
  );
};