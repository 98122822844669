import {
	Navigate,
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import { Suspense, useEffect } from 'react';

import Authenticate from './pages/Authenticate';
import LoadingSuspenseSpinner from './components/UI/LoadingSuspenseSpinner';
import WriterRoutes from './routes/Writer';
import AdminRoutes from 'routes/Admin';
import VerificationPage from 'pages/WRITER/VerificationPage';
import AuthorizationPage from 'pages/Authorization';
import { handleRedirectRoute } from 'utils/helpers/handleRedirectRoute';
import WriterLayout from 'pages/WRITER/WriterLayout';
import AdminLayout from 'pages/ADMIN/AdminLayout';
import PermissionDenied from 'pages/PermissionDenied';
import PlatformPolicy from 'pages/PlatformPolicy';
import { identifyIfExists } from 'utils/helpers/amplitude/amplitude';
import ContestApplication from 'pages/WRITER/ContestApplication';

function App() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		identifyIfExists([
			['build_version', 2.02], // ! important to update before releases
		]);
	}, []);

	const redirectRoute = handleRedirectRoute() || '/auth/log-in';

	return (
		<Suspense fallback={<LoadingSuspenseSpinner />}>
			<Routes>
				<Route path='/auth/:authState' element={<AuthorizationPage />} />
				<Route path='/authenticate' element={<Authenticate />} />
				<Route
					path='/email/verification/:token'
					element={<VerificationPage />}
				/>
				<Route
					path='/contest/application/:token'
					element={<ContestApplication />}
				/>
				<Route path='info'>
					<Route
						path='terms'
						element={
							<PlatformPolicy
								title="Terms and Conditions"
								lastUpdate="Last Updated: 19 April 2024"
							/>}
					/>
					<Route
						path='privacy_policy'
						element={
							<PlatformPolicy
								title="Provacy Policy"
								lastUpdate="Effective as of: 19 April 2024"
							/>}
					/>
				</Route>
				<Route path='writer' element={<WriterLayout />}>
					<Route path='*' element={<WriterRoutes />} />
				</Route>
				<Route path='admin' element={<AdminLayout />}>
					<Route path='*' element={<AdminRoutes />} />
				</Route>
				<Route path='permission-denied' element={<PermissionDenied />} />
				<Route path='*' element={<Navigate to={redirectRoute} />} />
			</Routes>
		</Suspense>
	);
}

export default App;
