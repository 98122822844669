import { Heading, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const SecondStep = () => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        size="poppins25"
        variant="primary-black-text"
        mb="12px"
      >
        {t('Check your email')}
      </Heading>

      <Text
        maxW="360px"
        size="poppins14"
        variant="primary-black-text"
        lineHeight="24px"
        textAlign="center"
        mb="96px"
      >
        {t('We have send a password recover instructions to your email')}
      </Text>

      <Image
        src="/images/authorization/reset-start.png"
        alt="arrow-left"
        w="120px"
        h="120px"
      />
    </>
  );
};
