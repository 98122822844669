import { Button, Flex, keyframes } from '@chakra-ui/react';
import { useEffect, useState, useContext, useCallback } from 'react';
import { NotificationToast } from '../../../../components/NotificationsAnatomy/NotificationToast';
import { NotificationsContext } from 'context/NotificationsContext';
import { NOTIFICATION_STATUS } from 'utils/enums/notification-status.enum';
import SimpleBarReact from "simplebar-react";
import { useTranslation } from 'react-i18next';
import CloseCirlcleIcon from 'components/Icons/CloseCirlcleIcon';
import { colors } from 'shared/utils/chakra-theme/colors';
import LoadingSpinner from 'components/UI/LoadingSpinner';
import { Notification } from 'utils/types/notifications';

export const NotificationsLayout = () => {
  const {
    handleClear,
    handleClearBatch,
    notifications,
    loading,
  } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const [visible, setVisible] = useState<Notification[]>([]);
  const [isClearLoading, setIsClearLoading] = useState(false);

  const handleClose = useCallback(async (id: string) => {
    setIsClearLoading(true);
    const filtredNotifs = visible.filter((notification) => notification.notification_id !== id);

    setVisible(filtredNotifs);
    await handleClear(id);
    setIsClearLoading(false);
  }, [visible, handleClear]);

  const handleClearAll = useCallback(async () => {
    setIsClearLoading(true);
    await handleClearBatch(visible.map(item => item.notification_id));
    setIsClearLoading(false);
  }, [visible, handleClearBatch]);

  useEffect(() => {
    if (notifications.length > 0) {
      const notReadedNotifications = notifications
        .filter((notif) => (
          notif.status === NOTIFICATION_STATUS.UPLOADED
        ));

      setVisible(notReadedNotifications);
    }
  }, [notifications, loading]);

  const animationKeyframes = keyframes`
    0% { transform: translateY(100%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
  `;

  const animation = `${animationKeyframes} 1s ease-in-out`;

  return (
    <>
      {visible.length > 0 && (
        <Flex
          pos="absolute"
          bottom={0}
          right={0}
          gap="12px"
          p="24px"
          w="493px"
          direction="column"
          animation={animation}
          zIndex={10}
        >
          {visible.length > 1 && (
            <Button
              variant="closeAllButton"
              alignSelf="flex-end"
              onClick={handleClearAll}
              isDisabled={isClearLoading}
            >
              {t('Clear all')}
              {CloseCirlcleIcon(colors.white)}
            </Button>
          )}

          <SimpleBarReact
            autoHide={true}
            style={{
              minHeight: '96px',
              maxHeight: '312px',
              overflowX: 'hidden',
            }}
          >
            {isClearLoading && <LoadingSpinner />}
            <Flex
              gap="12px"
              direction="column"
              pb="2px"
            >
              {visible.map((notification) => {
                return (
                  <NotificationToast
                    key={notification.notification_id}
                    notification={notification}
                    handleClose={handleClose}
                  />
                );
              })}
            </Flex>
          </SimpleBarReact>
        </Flex>
      )}
    </>
  );
};