import { Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { EmailTemplates } from 'constants/email/EmailTemplates';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { colors } from 'shared/utils/chakra-theme/colors';

const PermissionDenied = () => {
  const { t } = useTranslation();
  const { state } = useLocation();

  const handleCreateEmail = useCallback(() => {
    const link = document.createElement('a');
    link.href = EmailTemplates.PERMISSION_PROBLEMS(state?.user_id);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, []);

  return (
    <Flex
      h="100vh"
      w="100vw"
      position="relative"
      align="center"
      justify="center"
    >
      <Image
        position="absolute"
        src="/images/authorization/reset_bg.png"
        alt="background"
        zIndex={1}
        h="100vh"
        w="100vw"
        objectPosition="center"
        objectFit="cover"
        loading="eager"
      />

      <Flex
        justify="center"
        align="center"
        w="623px"
        p="44px 64px"
        direction="column"
        gap="24px"
        bg={colors.white}
        zIndex={2}
        borderRadius="8px"
        boxShadow="0px 5px 15px 0px rgba(36, 28, 88, 0.20)"
      >
        <Image
          src={`/images/hero/denied.png`}
          alt="permission denied"
          w="120px"
          h="120px"
        />

        <Heading
          size="poppins24"
          lineHeight="32px"
          letterSpacing="-0.01em"
          variant="primary-black-text"
          textAlign="center"
          maxW="423px"
        >
          {t('Ouch! Looks like your profile has been blocked(')}
        </Heading>

        <Text
          size="poppins16normal"
          variant="primary-black-text"
          lineHeight="28px"
          letterSpacing="-0.016px"
          textAlign="center"
          mt="-12px"
        >
          {t('For some reason your user profile has been blocked by our administration. As a result, you will no longer be able to access your account.')}
        </Text>

        <Text
          size="poppins16normal"
          variant="primary-black-text"
          lineHeight="28px"
          letterSpacing="-0.016px"
          textAlign="center"
          mb="12px"
        >
          {t('You can ask for more information and how to restore your account using, button below')}
        </Text>

        <Button
          variant="defaultButton"
          onClick={handleCreateEmail}
        >
          {t('Contact us')}
        </Button>

        <Flex align="center" direction="column">
          <Image
            w="40px"
            h="40px"
            src="/images/hero/heart.svg"
            loading="eager"
            alt="heart"
          />

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
          >
            {t('Best Regards,')}
          </Text>

          <Text
            variant="primary-black-text"
            size="poppins20normal"
            lineHeight="28px"
            fontWeight={500}
          >
            {t('Passion Team')}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PermissionDenied;