import { gql } from '@apollo/client';

export const UPDATE_CHAPTER = gql`
	mutation UpdateChapter($chapter: UpdateChapter!) {
		chapter: updateChapter(chapter: $chapter) {
			chapter_id
			title
			status
			price
			cdn_link
			bucket_link
			updated_at
			chapter_order
			tokensAmount
			text @client(always: true)
			onTimer
			isFree
		}
	}
`;

export const UPDATE_CHAPTER_ADMIN = gql`
	mutation UpdateChapterAdmin($chapter: UpdateChapterAdminFields!) {
		chapter: updateChapterAdmin(chapter: $chapter) {
			chapter_id
			title
			status
			price
			cdn_link
			bucket_link
			updated_at
			chapter_order
			text @client(always: true)
			onTimer
			isFree
		}
	}
`;
