export const colors: Record<string, string> = {
	white: '#ffffff',
	transparent: 'transparent',
	'button-hover': '#5109A9',
	'primary-black-text': '#02012B',
	'primary-blue-100': '#0087FF',
	'primary-blue-120': '#0056FF',
	'primary-blue-20': '#CCE7FF',
	'primary-blue-10': '#E5F3FF',
	'primary-dark-text': '#030006',
	'primary-violet-10': '#F1E9FC',
	'primary-violet-20': '#E4D2F9',
	'primary-violet-30': '#D6BCF6',
	'primary-violet-40': '#C8A5F3',
	'primary-violet-60': '#AD79EC',
	'primary-violet-100': '#761FE0',
	'primary-gray-100': '#9F9EAD',
	'primary-gray-90': '#A9A8B5',
	'primary-gray-70': '#BCBBC6',
	'primary-gray-60': '#C5C5CE',
	'primary-gray-40': '#D9D8DE',
	'primary-gray-30': '#E2E2E6',
	'primary-gray-10': '#ECECEF',
	'primary-gray-20': '#F5F5F7',
	'primary-green': '#07A004',
	'primitive-red-light-red-100': '#FECBD9',
	'primitive-red-light-red-50': '#FFE9EF',
	'primitive-red-dark-red-50': "#FDE8ED",
	'primitive-rose-light-rose-600': '#D71898',
	'pink-100': '#F478B8',
	'light-text-text-danger': '#FC2762',
	'button-black': '#323232',
	facebook: '#3578EA',
	stroke: '#EEEEF2',
	'notification-new': '#F6EFFF',
	'light-red-700': '#B31C46',
	'primary-orange-100': '#FF9142',
	'light-rose-300': '#FE6FCF',
	'light-rose-100': '#FEC8ED',
	'primary-emerald-120': '#4FCB4C',
	'primary-emerald-100': '#55D352',
	'primary-emerald-80': '#C9F3C3',
	'primary-emerald-60': '#D4F8D3',
	separator: '#E3E8EE',
	'soft-violet': '#EDE8FF',
	grey: '#484964',
	'grey-bg': '#F9F7FF',

	'main-dark': '#0b080f',
	blue: '#0052dd',
	red: '#dc6566',
	'dropdown-bg': '#fdfdfd',
	'light-grey': '#f8f8f8',
	'dark-tone': '#242533',
	'main-gray': '#f9f9f9',
	pink100: '#D0006E',
	'light-pink': '#fff3fa',
	gray: '#484964',
	gray4b: '#4b4b4b',
	black: '#000',
	options: '#5e5e5e',
	error: '#ea4335',
	inactive: '#b7b9bc',
	cell: '#f3f3f7',
};
