import { Box, Button, Stack, Text } from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { Form, Formik, FormikErrors } from 'formik';
import { newPasswordValidationSchema } from 'pages/Authorization/models';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateYupSchemaMultiErrors } from 'utils/helpers/yup/yupUtils';
import { NewPasswords } from 'utils/types/user/reset-password/NewPasswords';
import { PasswordField } from '../../LogIn/LogInForm/PasswordField';
import { ResetPasswordResponce } from 'utils/types/user/reset-password/ResetPasswordResult';

type Props = {
  passwordResetResult: ResetPasswordResponce | undefined;
  handleFinalyzeResetPassword: (password: string) => void;
};

export const NewPasswordForm: FC<Props> = ({
  passwordResetResult,
  handleFinalyzeResetPassword,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [repeatedShown, setRepeatedShown] = useState(false);

  const togglePasswordShow = useCallback(() => {
    setPasswordShown(curr => !curr);
  }, []);

  const toggleRepeatedShow = useCallback(() => {
    setRepeatedShown(curr => !curr);
  }, []);

  const handleErrorsCheck = useCallback((errors: FormikErrors<NewPasswords>) => {
    if (errors.password) {
      setError(errors.password[0]);
    } else if (errors.repeated) {
      setError(errors.repeated[0]);
    }
  }, []);

  const handleRemoveError = useCallback(() => {
    setError('');
  }, []);

  useEffect(() => {
    if (passwordResetResult && passwordResetResult.result.error) {
      setError(passwordResetResult.result.error);
    }
  }, [passwordResetResult]);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        password: '',
        repeated: '',
      }}
      validateOnBlur={true}
      validate={values =>
        validateYupSchemaMultiErrors(values, newPasswordValidationSchema(values))
      }
      onSubmit={({ password }) => {
        handleFinalyzeResetPassword(password);
      }}
    >
    {({ errors, values }) => (<Form style={{ width: '100%' }}>
      <Stack
        width="100%"
        spacing="24px"
        mb="32px"
      >
        <PasswordField
          passwordShown={passwordShown}
          togglePasswordShow={togglePasswordShow}
          htmlForId="password"
          name="password"
          handleRemoveError={handleRemoveError}
        />
        
        <PasswordField
          passwordShown={repeatedShown}
          togglePasswordShow={toggleRepeatedShow}
          htmlForId="repeated"
          name="repeated"
          handleRemoveError={handleRemoveError}
        />
      </Stack>

      <Box
        mb="12px"
        onClick={() => handleErrorsCheck(errors)}
        pos="relative"
      >
        <Button
          type="submit"
          variant="defaultButton"
          w="100%"
          isDisabled={!values.password || !values.repeated}
        >
          {t('Reset')}
        </Button>

          {error && <Text
            size="poppins14"
            variant="light-text-text-danger"
            lineHeight='20px'
            pos="absolute"
            bottom="-12px"
            left="50%"
            transform="translate(-50%, 100%)"
            w="100%"
            textAlign="center"
          >
            {t(error)}
          </Text>}
      </Box>
    </Form>)}
  </Formik>
  );
};
