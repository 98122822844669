import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import hashPassword from 'utils/helpers/hash/hashPassword';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_USER } from 'entites/user/create-user.graphql';
import { LOGIN_USER } from 'entites/user/login-user.graphql';
import { handleSetTokens } from 'utils/helpers/log-in-log-out/handleSetTokens';
import { AuthLayout } from './components/AuthLayout';
import { AUTH_STATE } from 'utils/enums/auth-state.enum';
import { handleRedirectRoute } from 'utils/helpers/handleRedirectRoute';
import { GET_USER_INFO } from 'entites/user/get-user-info.graphql';
import { handleSetData } from 'utils/helpers/log-in-log-out/handleSetData';
import useShowToast from 'hooks/useShowToast';
import { handleLogOut } from 'utils/helpers/log-in-log-out/handleLogOut';
import { LoginCredentials, SignUpCredentials } from 'utils/types/user';
import { initialLogInValues } from 'constants/user';
import useQuery from 'hooks/useQuery';
import { GoogleRedirect } from 'utils/enums/google-redirect.enum';
import { EMAIL_STATUS } from 'utils/enums/email-status.enum';
import { USER_ROLE } from 'utils/enums/user-role.enum';
import { ampSetUserId, ampSetUserProperty } from 'utils/helpers/amplitude/amplitude';

const AuthorizationPage = () => {
	const { authState } = useParams();
  const [credentials, setCredentials] = useState<LoginCredentials>(initialLogInValues);
  const { type, email } = useQuery();
	const [signUpStep, setSignUpStep] = useState(1);
	const navigate = useNavigate();
	const { showErrorToast } = useShowToast();

	const [getUserInfo] = useLazyQuery(GET_USER_INFO, {
		fetchPolicy: 'network-only',
	});

	const [createUser, { loading: isUserCreationLoading }] = useMutation(
		CREATE_USER,
		{},
	);
	const [loginUser, { loading: isLoginLoading }] = useMutation(LOGIN_USER);

	const changeSignUpStep = useCallback((step: number) => {
		setSignUpStep(step);
	}, []);

	const handleSetCredentials = useCallback((data: LoginCredentials) => {
		setCredentials(data);
	}, []);

	const handleUserLogin = useCallback(
		async ({ email, password }: LoginCredentials) => {
			try {
				const { data } = await loginUser({
					variables: {
						credentials: {
							email,
							password,
						},
					},
				});

				if (data) {
					handleSetTokens({
						access_token: data.loginUser.access_token,
						refresh_token: data.loginUser.refresh_token,
					});
				}

				const { data: userData } = await getUserInfo();

				if (userData.getUserInfo.isBlocked) {
					handleLogOut(false);
					
					navigate('/permission-denied', { state: { user_id: userData.getUserInfo.user_id}});
					return;
				}

				if (userData) {
					handleSetData({
						user_id: userData.getUserInfo.user_id,
						email: userData.getUserInfo.email,
						role: userData.getUserInfo.role,
					});

					if (userData.getUserInfo.role === USER_ROLE.WRITER
						|| userData.getUserInfo.role === USER_ROLE.SOURCER) {
						ampSetUserId(userData.getUserInfo.user_id);
						ampSetUserProperty('email', userData.getUserInfo.email);
					}
				}

				const flowPath = handleRedirectRoute();

				navigate(flowPath);
			} catch (error) {
				showErrorToast({
          id: "logInError",
          title: "Sorry something went wrong",
          reason: "Log in error",
        });
			}
		},
		[loginUser],
	);

	const handleLogin = useCallback(
		async (values: LoginCredentials) => {
			const hashedPassword = await hashPassword(values.password);

			await handleUserLogin({
				email: values.email,
				password: hashedPassword,
			});
		},
		[handleUserLogin],
	);

	const loginViaGoogleHandler = useCallback(() => {
		window.open(`${process.env.REACT_APP_SERVER_URL}api/auth/google`, '_top');
	}, []);

	const loginViaFacebookHandler = useCallback(() => { 
		window.open(`${process.env.REACT_APP_SERVER_URL}api/auth/facebook`, '_top');
	}, []);

	const handleCreateAccount = useCallback(
		async (values: SignUpCredentials) => {
			try {
				const hashedPassword: string = await hashPassword(credentials.password);
				const status =
					type === GoogleRedirect.REGISTER && email === credentials.email
						? EMAIL_STATUS.APPROVED
						: EMAIL_STATUS.UNAPPROVED;

				await createUser({
					variables: {
						user: {
							email: credentials.email,
							first_name: values.first_name,
							last_name: values.last_name,
							pen_name: values.pen_name,
							password: hashedPassword,
							status,
						},
					},
				});
				await handleUserLogin({
					email: credentials.email,
					password: hashedPassword,
				});
			} catch (error) {
				showErrorToast({
          id: "CreateAccountError",
          title: "Sorry something went wrong",
          reason: "Create account error",
        });
			}
		},
		[createUser, handleUserLogin, credentials],
	);

	useEffect(() => {
		if (email) {
			setCredentials({
				...initialLogInValues,
				email,
			});
		}
	}, [email]);

	return (
		<AuthLayout
			authState={authState as AUTH_STATE}
			credentials={credentials}
			handleSetCredentials={handleSetCredentials}
			signUpStep={signUpStep}
			changeSignUpStep={changeSignUpStep}
			handleLogin={handleLogin}
			isLoginLoading={isLoginLoading}
			isUserCreationLoading={isUserCreationLoading}
			loginViaGoogleHandler={loginViaGoogleHandler}
			loginViaFacebookHandler={
				loginViaFacebookHandler
			}
			handleCreateAccount={handleCreateAccount}
		/>
	);
};

export default AuthorizationPage;
