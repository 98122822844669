import { gql } from '@apollo/client';

export const CREATE_CHAPTER = gql`
	mutation CreateChapter($chapter: CreateChapter!) {
		chapter: createChapter(chapter: $chapter) {
			chapter_id
			title
			status
			price
			cdn_link
			bucket_link
			updated_at
			chapter_order
			onTimer
			isFree
			text @client(always: true)
		}
	}
`;
