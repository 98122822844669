import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
	query GetUserInfo {
		getUserInfo {
			user_id
			status
			created_at
			updated_at
			description
			email
			first_name
			gender
			last_name
			pen_name
			login_provider
			photo_link
			provider_id
			published_books_count
			role
			facebook
			instagram
			reddit
			quora
			isBlocked
		}
	}
`;
