import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
	mutation LoginUser($credentials: AutenticateUserDto!) {
		loginUser(credentials: $credentials) {
			access_token
			refresh_token
		}
	}
`;
