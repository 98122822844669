import { gql } from '@apollo/client';

export const GET_AUTHOR_CHAPTERS = gql`
	query AuthorChapters($book_id: String!) {
		chapters: authorChapters(book: { book_id: $book_id }) {
			chapter_id
			title
			status
			cdn_link
			bucket_link
			onTimer
			isFree
			price
			chapter_order
			updated_at
			tokensAmount
		}
	}
`;

export const UPDATE_CHAPTERS_ORDER = gql`
	mutation UpdateChaptersOrder($chapters: [UpdateChapterOrder!]!) {
		chapters: updateChapterOrder(chapters: $chapters) {
			chapter_id
			chapter_order
		}
	}
`;

export const UPDATE_CHAPTERS_PRICE = gql`
	mutation UpdateAdminBatch($chapters: [UpdateChapterAdminFields!]!) {
		result: updateAdminBatch(chapters: $chapters)
	}
`;
