import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
	query Users($limit: Int, $page: Int) {
		users(
			limit: $limit
			page: $page
			alias: "users"
			orders: [
				{ field: "users.first_name", type: "ASC" }
			]
		) {
			user_id
			status,
			created_at
			updated_at
			email
			first_name
			last_name
			pen_name
			photo_link
			published_books_count
		}
	}
`;

export const ALL_USERS = gql`
	query AllUsers {
		users: allUsers {
			user_id
			first_name
			last_name
			email
		}
	}
`;