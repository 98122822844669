import { Box, Button, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { resetEmailValidationSchema } from 'pages/Authorization/models';
import { FC } from 'react';
import { validateYupSchemaMultiErrors } from 'utils/helpers/yup/yupUtils';
import { EmailField } from '../../LogIn/LogInForm/EmailField';
import { useTranslation } from 'react-i18next';

type Props = {
  handleStartResetPassword: (email: string) => void;
  error: string;
};

export const ResetPasswordEmailForm: FC<Props> = ({
  handleStartResetPassword,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: '',
      }}
      validateOnBlur={true}
      validate={values =>
        validateYupSchemaMultiErrors(values, resetEmailValidationSchema)
      }
      onSubmit={({ email }) => {
        handleStartResetPassword(email);
      }}
    >
    {({ values }) => (<Form style={{ width: '100%' }}>
      <Box
        width="100%"
        mb="32px"
      >
        <EmailField
          htmlForId="email"
          name="email"
        />
      </Box>

      <Box mb="12px" pos="relative">
        <Button
          type="submit"
          variant="defaultButton"
          w="100%"
          isDisabled={!values.email}
        >
          {t('Request a reset link')}
        </Button>

        {error && <Text
          size="poppins14"
          variant="light-text-text-danger"
          lineHeight='20px'
          w="100%"
          pos="absolute"
          bottom="-12px"
          left="50%"
          transform="translate(-50%, 100%)"
        >
          {t(error)}
        </Text>}
      </Box>
    </Form>)}
  </Formik>
  );
};
