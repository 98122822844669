import { STORY_MODULES } from 'utils/enums/story-modules.enum';

export const ITEMS_BATCH = 10;

export const ContentButtons = [
  {
    id: '1',
    icon: '/images/admin/creators.png',
    title: 'Creators',
    description: 'All authors',
  },
  {
    id: '2',
    icon: '/images/contract/books.png',
    title: 'Stories',
    description: 'All books',
  },
  {
    id: '3',
    icon: '/images/admin/contracts.png',
    title: 'Contracts',
    description: 'All approved contracts',
  },
];

export const storyModules = [
  {
    name: 'Story details',
    shortcut: STORY_MODULES.DETAILS,
    passive: (fill = "#02012B") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M12.5 22.0795C12.2 22.0795 11.9 22.0095 11.65 21.8695C9.78 20.8495 6.49 19.7695 4.43 19.4995L4.14 19.4595C2.83 19.2995 1.75 18.0695 1.75 16.7395V4.65952C1.75 3.86952 2.06 3.14952 2.63 2.62952C3.2 2.10952 3.94 1.85952 4.72 1.92952C6.92 2.09952 10.24 3.19952 12.12 4.37952L12.36 4.51952C12.43 4.55952 12.58 4.55952 12.64 4.52952L12.8 4.42952C14.68 3.24952 18 2.12952 20.21 1.93952C20.23 1.93952 20.31 1.93952 20.33 1.93952C21.06 1.86952 21.81 2.12952 22.37 2.64952C22.94 3.16952 23.25 3.88952 23.25 4.67952V16.7495C23.25 18.0895 22.17 19.3095 20.85 19.4695L20.52 19.5095C18.46 19.7795 15.16 20.8695 13.33 21.8795C13.09 22.0195 12.8 22.0795 12.5 22.0795ZM4.48 3.41952C4.16 3.41952 3.87 3.52952 3.64 3.73952C3.39 3.96952 3.25 4.29952 3.25 4.65952V16.7395C3.25 17.3295 3.76 17.8995 4.33 17.9795L4.63 18.0195C6.88 18.3195 10.33 19.4495 12.33 20.5395C12.42 20.5795 12.55 20.5895 12.6 20.5695C14.6 19.4595 18.07 18.3195 20.33 18.0195L20.67 17.9795C21.24 17.9095 21.75 17.3295 21.75 16.7395V4.66952C21.75 4.29952 21.61 3.97952 21.36 3.73952C21.1 3.50952 20.77 3.39952 20.4 3.41952C20.38 3.41952 20.3 3.41952 20.28 3.41952C18.37 3.58952 15.29 4.61952 13.61 5.66952L13.45 5.77952C12.9 6.11952 12.12 6.11952 11.59 5.78952L11.35 5.64952C9.64 4.59952 6.56 3.57952 4.6 3.41952C4.56 3.41952 4.52 3.41952 4.48 3.41952Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M12.5 21.2402C12.09 21.2402 11.75 20.9002 11.75 20.4902V5.49023C11.75 5.08023 12.09 4.74023 12.5 4.74023C12.91 4.74023 13.25 5.08023 13.25 5.49023V20.4902C13.25 20.9102 12.91 21.2402 12.5 21.2402Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M8.25 9.24023H6C5.59 9.24023 5.25 8.90023 5.25 8.49023C5.25 8.08023 5.59 7.74023 6 7.74023H8.25C8.66 7.74023 9 8.08023 9 8.49023C9 8.90023 8.66 9.24023 8.25 9.24023Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M9 12.2402H6C5.59 12.2402 5.25 11.9002 5.25 11.4902C5.25 11.0802 5.59 10.7402 6 10.7402H9C9.41 10.7402 9.75 11.0802 9.75 11.4902C9.75 11.9002 9.41 12.2402 9 12.2402Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
    active: (fill = "#761FE0") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M12.5 22.0795C12.2 22.0795 11.9 22.0095 11.65 21.8695C9.78 20.8495 6.49 19.7695 4.43 19.4995L4.14 19.4595C2.83 19.2995 1.75 18.0695 1.75 16.7395V4.65952C1.75 3.86952 2.06 3.14952 2.63 2.62952C3.2 2.10952 3.94 1.85952 4.72 1.92952C6.92 2.09952 10.24 3.19952 12.12 4.37952L12.36 4.51952C12.43 4.55952 12.58 4.55952 12.64 4.52952L12.8 4.42952C14.68 3.24952 18 2.12952 20.21 1.93952C20.23 1.93952 20.31 1.93952 20.33 1.93952C21.06 1.86952 21.81 2.12952 22.37 2.64952C22.94 3.16952 23.25 3.88952 23.25 4.67952V16.7495C23.25 18.0895 22.17 19.3095 20.85 19.4695L20.52 19.5095C18.46 19.7795 15.16 20.8695 13.33 21.8795C13.09 22.0195 12.8 22.0795 12.5 22.0795ZM4.48 3.41952C4.16 3.41952 3.87 3.52952 3.64 3.73952C3.39 3.96952 3.25 4.29952 3.25 4.65952V16.7395C3.25 17.3295 3.76 17.8995 4.33 17.9795L4.63 18.0195C6.88 18.3195 10.33 19.4495 12.33 20.5395C12.42 20.5795 12.55 20.5895 12.6 20.5695C14.6 19.4595 18.07 18.3195 20.33 18.0195L20.67 17.9795C21.24 17.9095 21.75 17.3295 21.75 16.7395V4.66952C21.75 4.29952 21.61 3.97952 21.36 3.73952C21.1 3.50952 20.77 3.39952 20.4 3.41952C20.38 3.41952 20.3 3.41952 20.28 3.41952C18.37 3.58952 15.29 4.61952 13.61 5.66952L13.45 5.77952C12.9 6.11952 12.12 6.11952 11.59 5.78952L11.35 5.64952C9.64 4.59952 6.56 3.57952 4.6 3.41952C4.56 3.41952 4.52 3.41952 4.48 3.41952Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M12.5 21.2402C12.09 21.2402 11.75 20.9002 11.75 20.4902V5.49023C11.75 5.08023 12.09 4.74023 12.5 4.74023C12.91 4.74023 13.25 5.08023 13.25 5.49023V20.4902C13.25 20.9102 12.91 21.2402 12.5 21.2402Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M8.25 9.24023H6C5.59 9.24023 5.25 8.90023 5.25 8.49023C5.25 8.08023 5.59 7.74023 6 7.74023H8.25C8.66 7.74023 9 8.08023 9 8.49023C9 8.90023 8.66 9.24023 8.25 9.24023Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M9 12.2402H6C5.59 12.2402 5.25 11.9002 5.25 11.4902C5.25 11.0802 5.59 10.7402 6 10.7402H9C9.41 10.7402 9.75 11.0802 9.75 11.4902C9.75 11.9002 9.41 12.2402 9 12.2402Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
  },
  {
    name: 'Recommendations',
    shortcut: STORY_MODULES.RECOMMENDATIONS,
    passive: (fill = "#292D32") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17.6601 22.6703C17.1301 22.6703 16.4501 22.5003 15.6001 22.0003L12.6101 20.2303C12.3001 20.0503 11.7001 20.0503 11.4001 20.2303L8.40012 22.0003C6.63012 23.0503 5.59012 22.6303 5.12012 22.2903C4.66012 21.9503 3.94012 21.0803 4.41012 19.0803L5.12012 16.0103C5.20012 15.6903 5.04012 15.1403 4.80012 14.9003L2.32012 12.4203C1.08012 11.1803 1.18012 10.1203 1.35012 9.60031C1.52012 9.08031 2.06012 8.16031 3.78012 7.87031L6.97012 7.34031C7.27012 7.29031 7.70012 6.97031 7.83012 6.70031L9.60012 3.17031C10.4001 1.56031 11.4501 1.32031 12.0001 1.32031C12.5501 1.32031 13.6001 1.56031 14.4001 3.17031L16.1601 6.69031C16.3001 6.96031 16.7301 7.28031 17.0301 7.33031L20.2201 7.86031C21.9501 8.15031 22.4901 9.07031 22.6501 9.59031C22.8101 10.1103 22.9101 11.1703 21.6801 12.4103L19.2001 14.9003C18.9601 15.1403 18.8101 15.6803 18.8801 16.0103L19.5901 19.0803C20.0501 21.0803 19.3401 21.9503 18.8801 22.2903C18.6301 22.4703 18.2301 22.6703 17.6601 22.6703ZM12.0001 18.5903C12.4901 18.5903 12.9801 18.7103 13.3701 18.9403L16.3601 20.7103C17.2301 21.2303 17.7801 21.2303 17.9901 21.0803C18.2001 20.9303 18.3501 20.4003 18.1301 19.4203L17.4201 16.3503C17.2301 15.5203 17.5401 14.4503 18.1401 13.8403L20.6201 11.3603C21.1101 10.8703 21.3301 10.3903 21.2301 10.0603C21.1201 9.73031 20.6601 9.46031 19.9801 9.35031L16.7901 8.82031C16.0201 8.69031 15.1801 8.07031 14.8301 7.37031L13.0701 3.85031C12.7501 3.21031 12.3501 2.83031 12.0001 2.83031C11.6501 2.83031 11.2501 3.21031 10.9401 3.85031L9.17012 7.37031C8.82012 8.07031 7.98012 8.69031 7.21012 8.82031L4.03012 9.35031C3.35012 9.46031 2.89012 9.73031 2.78012 10.0603C2.67012 10.3903 2.90012 10.8803 3.39012 11.3603L5.87012 13.8403C6.47012 14.4403 6.78012 15.5203 6.59012 16.3503L5.88012 19.4203C5.65012 20.4103 5.81012 20.9303 6.02012 21.0803C6.23012 21.2303 6.77012 21.2203 7.65012 20.7103L10.6401 18.9403C11.0201 18.7103 11.5101 18.5903 12.0001 18.5903Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
    active: (fill = "#761FE0") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.1001 22.0003C16.9501 22.5003 17.6301 22.6703 18.1601 22.6703C18.7301 22.6703 19.1301 22.4703 19.3801 22.2903C19.8401 21.9503 20.5501 21.0803 20.0901 19.0803L19.3801 16.0103C19.3101 15.6803 19.4601 15.1403 19.7001 14.9003L22.1801 12.4103C23.4101 11.1703 23.3101 10.1103 23.1501 9.59031C22.9901 9.07031 22.4501 8.15031 20.7201 7.86031L17.5301 7.33031C17.2301 7.28031 16.8001 6.96031 16.6601 6.69031L14.9001 3.17031C14.1001 1.56031 13.0501 1.32031 12.5001 1.32031C11.9501 1.32031 10.9001 1.56031 10.1001 3.17031L8.33012 6.70031C8.20012 6.97031 7.77012 7.29031 7.47012 7.34031L4.28012 7.87031C2.56012 8.16031 2.02012 9.08031 1.85012 9.60031C1.68012 10.1203 1.58012 11.1803 2.82012 12.4203L5.30012 14.9003C5.54012 15.1403 5.70012 15.6903 5.62012 16.0103L4.91012 19.0803C4.44012 21.0803 5.16012 21.9503 5.62012 22.2903C6.09012 22.6303 7.13012 23.0503 8.90012 22.0003L11.9001 20.2303C12.2001 20.0503 12.8001 20.0503 13.1101 20.2303L16.1001 22.0003Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
  },
  {
    name: 'Replace creator',
    shortcut: STORY_MODULES.CREATOR,
    passive: (fill = "#02012B") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M9.15957 11.62C9.12957 11.62 9.10957 11.62 9.07957 11.62C9.02957 11.61 8.95957 11.61 8.89957 11.62C5.99957 11.53 3.80957 9.25 3.80957 6.44C3.80957 3.58 6.13957 1.25 8.99957 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18957 11.62C9.17957 11.62 9.16957 11.62 9.15957 11.62ZM8.99957 2.75C6.96957 2.75 5.30957 4.41 5.30957 6.44C5.30957 8.44 6.86957 10.05 8.85957 10.12C8.91957 10.11 9.04957 10.11 9.17957 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99957 2.75Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M16.5394 11.75C16.5094 11.75 16.4794 11.75 16.4494 11.74C16.0394 11.78 15.6194 11.49 15.5794 11.08C15.5394 10.67 15.7894 10.3 16.1994 10.25C16.3194 10.24 16.4494 10.24 16.5594 10.24C18.0194 10.16 19.1594 8.96 19.1594 7.49C19.1594 5.97 17.9294 4.74 16.4094 4.74C15.9994 4.75 15.6594 4.41 15.6594 4C15.6594 3.59 15.9994 3.25 16.4094 3.25C18.7494 3.25 20.6594 5.16 20.6594 7.5C20.6594 9.8 18.8594 11.66 16.5694 11.75C16.5594 11.75 16.5494 11.75 16.5394 11.75Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M18.3397 20.75C17.9897 20.75 17.6797 20.51 17.6097 20.15C17.5297 19.74 17.7897 19.35 18.1897 19.26C18.8197 19.13 19.3997 18.88 19.8497 18.53C20.4197 18.1 20.7297 17.56 20.7297 16.99C20.7297 16.42 20.4197 15.88 19.8597 15.46C19.4197 15.12 18.8697 14.88 18.2197 14.73C17.8197 14.64 17.5597 14.24 17.6497 13.83C17.7397 13.43 18.1397 13.17 18.5497 13.26C19.4097 13.45 20.1597 13.79 20.7697 14.26C21.6997 14.96 22.2297 15.95 22.2297 16.99C22.2297 18.03 21.6897 19.02 20.7597 19.73C20.1397 20.21 19.3597 20.56 18.4997 20.73C18.4397 20.75 18.3897 20.75 18.3397 20.75Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
    active: (fill = "#761FE0") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M9.5 2C6.88 2 4.75 4.13 4.75 6.75C4.75 9.32 6.76 11.4 9.38 11.49C9.46 11.48 9.54 11.48 9.6 11.49C9.62 11.49 9.63 11.49 9.65 11.49C9.66 11.49 9.66 11.49 9.67 11.49C12.23 11.4 14.24 9.32 14.25 6.75C14.25 4.13 12.12 2 9.5 2Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M14.58 14.1499C11.79 12.2899 7.23996 12.2899 4.42996 14.1499C3.15996 14.9999 2.45996 16.1499 2.45996 17.3799C2.45996 18.6099 3.15996 19.7499 4.41996 20.5899C5.81996 21.5299 7.65996 21.9999 9.49996 21.9999C11.34 21.9999 13.18 21.5299 14.58 20.5899C15.84 19.7399 16.54 18.5999 16.54 17.3599C16.53 16.1299 15.84 14.9899 14.58 14.1499Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M20.4899 7.3401C20.6499 9.2801 19.2699 10.9801 17.3599 11.2101C17.3499 11.2101 17.3499 11.2101 17.3399 11.2101H17.3099C17.2499 11.2101 17.1899 11.2101 17.1399 11.2301C16.1699 11.2801 15.2799 10.9701 14.6099 10.4001C15.6399 9.4801 16.2299 8.1001 16.1099 6.6001C16.0399 5.7901 15.7599 5.0501 15.3399 4.4201C15.7199 4.2301 16.1599 4.1101 16.6099 4.0701C18.5699 3.9001 20.3199 5.3601 20.4899 7.3401Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M22.4902 16.5904C22.4102 17.5604 21.7902 18.4004 20.7502 18.9704C19.7502 19.5204 18.4902 19.7804 17.2402 19.7504C17.9602 19.1004 18.3802 18.2904 18.4602 17.4304C18.5602 16.1904 17.9702 15.0004 16.7902 14.0504C16.1202 13.5204 15.3402 13.1004 14.4902 12.7904C16.7002 12.1504 19.4802 12.5804 21.1902 13.9604C22.1102 14.7004 22.5802 15.6304 22.4902 16.5904Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
  },
  {
    name: 'Change chapters price',
    shortcut: STORY_MODULES.PRICE,
    passive: (fill = "#292D32") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
        <path d="M17.5 20.75H12.5C12.09 20.75 11.75 20.41 11.75 20C11.75 19.59 12.09 19.25 12.5 19.25H17.5C20.36 19.25 21.75 17.86 21.75 15V9C21.75 6.14 20.36 4.75 17.5 4.75H7.5C4.64 4.75 3.25 6.14 3.25 9V11C3.25 11.41 2.91 11.75 2.5 11.75C2.09 11.75 1.75 11.41 1.75 11V9C1.75 5.35 3.85 3.25 7.5 3.25H17.5C21.15 3.25 23.25 5.35 23.25 9V15C23.25 18.65 21.15 20.75 17.5 20.75Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M12.5 15.25C10.71 15.25 9.25 13.79 9.25 12C9.25 10.21 10.71 8.75 12.5 8.75C14.29 8.75 15.75 10.21 15.75 12C15.75 13.79 14.29 15.25 12.5 15.25ZM12.5 10.25C11.54 10.25 10.75 11.04 10.75 12C10.75 12.96 11.54 13.75 12.5 13.75C13.46 13.75 14.25 12.96 14.25 12C14.25 11.04 13.46 10.25 12.5 10.25Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M19 15.25C18.59 15.25 18.25 14.91 18.25 14.5V9.5C18.25 9.09 18.59 8.75 19 8.75C19.41 8.75 19.75 9.09 19.75 9.5V14.5C19.75 14.91 19.41 15.25 19 15.25Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M9 18.69C8.59 18.69 8.25 18.35 8.25 17.94V16.66C8.25 16.44 8.07003 16.25 7.84003 16.25H2.5C2.09 16.25 1.75 15.91 1.75 15.5C1.75 15.09 2.09 14.75 2.5 14.75H7.84003C8.89003 14.75 9.75 15.61 9.75 16.66V17.94C9.75 18.35 9.41 18.69 9 18.69Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M3.71994 17.4697C3.52994 17.4697 3.33997 17.3997 3.18997 17.2497L1.96994 16.0298C1.67994 15.7398 1.67994 15.2597 1.96994 14.9697L3.18997 13.7497C3.47997 13.4597 3.95997 13.4597 4.24997 13.7497C4.53997 14.0397 4.53997 14.5197 4.24997 14.8097L3.55997 15.4997L4.24997 16.1897C4.53997 16.4797 4.53997 16.9597 4.24997 17.2497C4.09997 17.3997 3.90994 17.4697 3.71994 17.4697Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M9 21.5298H3.65997C2.60997 21.5298 1.75 20.6698 1.75 19.6198V18.3398C1.75 17.9298 2.09 17.5898 2.5 17.5898C2.91 17.5898 3.25 17.9298 3.25 18.3398V19.6198C3.25 19.8398 3.42997 20.0298 3.65997 20.0298H9C9.41 20.0298 9.75 20.3698 9.75 20.7798C9.75 21.1898 9.41 21.5298 9 21.5298Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M7.77976 22.75C7.58976 22.75 7.39973 22.68 7.24973 22.53C6.95973 22.24 6.95973 21.7599 7.24973 21.4699L7.93973 20.78L7.24973 20.09C6.95973 19.8 6.95973 19.32 7.24973 19.03C7.53973 18.74 8.01972 18.74 8.30972 19.03L9.52976 20.25C9.81976 20.54 9.81976 21.02 9.52976 21.31L8.30972 22.53C8.16972 22.68 7.96976 22.75 7.77976 22.75Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
    active: (fill = "#761FE0") => (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M17 4H7C4 4 2 5.5 2 9V12.56C2 12.93 2.38 13.16 2.71 13.01C3.69 12.56 4.82 12.39 6.01 12.6C8.64 13.07 10.57 15.51 10.5 18.18C10.49 18.6 10.43 19.01 10.32 19.41C10.24 19.72 10.49 20.01 10.81 20.01H17C20 20.01 22 18.51 22 15.01V9C22 5.5 20 4 17 4ZM12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5ZM19.25 14C19.25 14.41 18.91 14.75 18.5 14.75C18.09 14.75 17.75 14.41 17.75 14V10C17.75 9.59 18.09 9.25 18.5 9.25C18.91 9.25 19.25 9.59 19.25 10V14Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
        <path d="M5 14C3.75 14 2.62 14.59 1.89 15.49C1.33 16.18 1 17.05 1 18C1 20.21 2.8 22 5 22C6.74 22 8.23 20.89 8.77 19.33C8.92 18.92 9 18.47 9 18C9 15.8 7.21 14 5 14ZM7.36 19.74C7.34 19.8 7.3 19.86 7.26 19.9L6.54 20.61C6.45 20.71 6.33 20.75 6.2 20.75C6.07 20.75 5.94 20.71 5.85 20.61C5.69 20.46 5.67 20.22 5.77 20.04H3.76C3.12 20.04 2.6 19.52 2.6 18.87V18.77C2.6 18.49 2.82 18.28 3.09 18.28C3.36 18.28 3.58 18.49 3.58 18.77V18.87C3.58 18.98 3.66 19.07 3.77 19.07H5.78C5.68 18.88 5.7 18.65 5.86 18.49C6.05 18.3 6.36 18.3 6.54 18.49L7.26 19.21C7.3 19.25 7.34 19.31 7.37 19.37C7.41 19.48 7.41 19.62 7.36 19.74ZM7.4 17.23C7.4 17.51 7.18 17.72 6.91 17.72C6.64 17.72 6.42 17.51 6.42 17.23V17.13C6.42 17.02 6.34 16.93 6.23 16.93H4.23C4.33 17.12 4.31 17.35 4.15 17.51C4.06 17.6 3.94 17.65 3.8 17.65C3.68 17.65 3.55 17.6 3.46 17.51L2.74 16.79C2.7 16.75 2.66 16.69 2.63 16.63C2.59 16.51 2.59 16.38 2.63 16.26C2.66 16.21 2.69 16.14 2.74 16.1L3.46 15.39C3.65 15.19 3.96 15.19 4.14 15.39C4.3 15.54 4.32 15.78 4.22 15.96H6.23C6.87 15.96 7.39 16.48 7.39 17.13V17.23H7.4Z" fill={fill} style={{ transition: 'fill 0.4s ease' }} />
      </svg>
    ),
  },
];
