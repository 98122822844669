import amplitude from 'amplitude-js';
import { EVENT_TYPES } from 'utils/enums/event-type.enum';

export const ampInst = amplitude.getInstance();
ampInst.init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');

const ampEvent = (event: EVENT_TYPES) => {
	ampInst.logEvent(event);
};

const ampEventWithEventProperty = (
	event: EVENT_TYPES,
	props?: Record<string, any>,
) => {
	ampInst.logEvent(event, { url: window.location.href, ...props });
};

const ampSetUserProperty = (key: string, value: any) => {
	const identify = new amplitude.Identify().set(key, value);
	ampInst.identify(identify);
};

const ampSetUserId = (id: string) => {
	ampInst.setUserId(id);
};

export const identifyIfExists = (properties: [string, any][]): void => {
	const identify = new amplitude.Identify(); // Correct way to instantiate Identify

	properties.forEach(([key, value]) => {
		if (value !== undefined && value !== null) {
			identify.set(key, value);
		}
	});

	ampInst.identify(identify); // Assuming default instance
};

export {
	ampEvent,
	ampEventWithEventProperty,
	ampSetUserId,
	ampSetUserProperty,
};

