import { Box, Button, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { loginValidationSchema } from 'pages/Authorization/models';
import { FC, useCallback, useEffect, useState } from 'react';
import { validateYupSchemaMultiErrors } from 'utils/helpers/yup/yupUtils';
import { PasswordField } from './PasswordField';
import { EmailField } from './EmailField';
import { ResetPasswordLink } from './ResetPasswordLink';
import { useTranslation } from 'react-i18next';
import { LoginCredentials } from 'utils/types/user';
import { ampEvent } from 'utils/helpers/amplitude/amplitude';
import { EVENT_TYPES } from 'utils/enums/event-type.enum';

type Props = {
  handleLogin: (values: LoginCredentials) => void;
};

export const LogInForm: FC<Props> = ({
  handleLogin,
}) => {
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordShow = useCallback(() => {
    setPasswordShown(curr => !curr);
  }, []);

  useEffect(() => {
    const loginButton = document.querySelector<HTMLButtonElement>('.login-button');
    const passwordField = document.getElementById('password');

    const checkEnterPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (loginButton) {
          passwordField?.blur();
          loginButton.click();
        }
      }
    };


    if (passwordField) {
      passwordField.addEventListener('keypress', checkEnterPress);
    }

    return () => {
      passwordField?.removeEventListener('keypress', checkEnterPress);
    };
  });

  useEffect(() => {
    ampEvent(EVENT_TYPES.LOGIN_SHOWN);
  }, []);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        email: '',
        password: '',
      }}
      validateOnBlur={true}
      validate={values =>
        validateYupSchemaMultiErrors(values, loginValidationSchema(values))
      }
      onSubmit={values => {
        handleLogin(values);
      }}
    >
      <Form style={{ width: '100%' }}>
        <Stack
          spacing="33px"
          width="100%"
          mb="30px"
        >
          <EmailField
            htmlForId="email"
            name="email"
          />

          <PasswordField
            passwordShown={passwordShown}
            togglePasswordShow={togglePasswordShow}
            htmlForId="password"
            name="password"
            showError={true}
          />
        </Stack>

        <ResetPasswordLink />

        <Box mb="12px" pos="relative">
          <Button
            className='login-button'
            type="submit"
            variant="defaultButton"
            w="100%"
          >
            {t('Log In')}
          </Button>
        </Box>
      </Form>
    </Formik>
  );
};
