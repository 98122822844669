import { gql } from '@apollo/client';

export const FINALYZE_RESET_USER_PASSWORD = gql`
	mutation FinalizePasswordResetFlow($input: ResetPasswordDto!) {
		result: finalizePasswordResetFlow(input: $input) {
			error
			success
		}
	}
`;
