import { Text } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  description: string;
}

export const GeneralPoint: FC<Props> = ({
  stage,
  description,
}) => {
  return (
    <Text
      variant="primary-black-text"
      size="poppins16normal"
      letterSpacing="-0.016px"
      lineHeight="28px"
      textAlign="justify"
    >
      <Text
        as="span"
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mr="16px"
      >
        {stage}
      </Text>

      {description}
    </Text>
  );
};
