import { gql } from '@apollo/client';

export const CREATE_MODERATION_REQUEST = gql`
	mutation CreateModerationRequest($book_id: String!) {
		createBookModerationRequest(request: { book: { book_id: $book_id } }) {
			id
			status
		}
	}
`;

export const CANCEL_MODERATION_REQUEST = gql`
	mutation CreateModerationRequest($book_id: String!) {
		cancelBookModerationRequestByBook(
			request: { book: { book_id: $book_id } }
		) {
			id
			status
		}
	}
`;
