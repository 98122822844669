import { SearchHistory } from 'utils/types/search';

export const InitialSearchHistory: SearchHistory = {
  REQUEST_CONTRACT: {
    query: [],
  },
  REQUEST_STORIES: {
    query: [],
  },
  REVENUE: {
    query: [],
  },
  CREATOR: {
    query: [],
  },
  CREATOR_ID: {
    query: [],
  },
  STORY: {
    query: [],
  },
  STORY_ID: {
    query: [],
  },
  LOCAL_STORY: {
    query: [],
  },
  LOCAL_STORY_ID: {
    query: [],
  },
  GENRES: {
    query: [],
  },
  TAGS: {
    query: [],
  },
  SECTIONS: {
    query: [],
  },
  SECTIONS_ID: {
    query: [],
  },
  RECOMMENDATIONS: {
    query: [],
  },
  REPLACE_CREATOR: {
    query: [],
  },
  CONTRACT: {
    query: [],
  }
};
