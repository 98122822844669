import { validateYupSchema, setIn, getIn } from 'formik';

function yupToFormErrors(yupError, validationSchemaOptions) {
	let errors = {};
	if (yupError.inner.length === 0) {
		return setIn(errors, yupError.path, yupError.message);
	}
	// if showMultipleFieldErrors is enabled, set the error value
	// to an array of all errors for that field
	if (validationSchemaOptions.showMultipleFieldErrors) {
		for (const err of yupError.inner) {
			let fieldErrors = getIn(errors, err.path);
			if (!fieldErrors) {
				fieldErrors = [];
			}
			fieldErrors.push(err.message);
			errors = setIn(errors, err.path, fieldErrors);
		}
	} else {
		for (const err of yupError.inner) {
			if (!errors[err.path]) {
				errors = setIn(errors, err.path, err.message);
			}
		}
	}
	return errors;
}

export const validateYupSchemaMultiErrors = async (values, schema) => {
	try {
		await validateYupSchema(values, schema);
		return {};
	} catch (e) {
		return yupToFormErrors(e, { showMultipleFieldErrors: true });
	}
};
