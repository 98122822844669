import { Flex, Heading, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { colors } from 'shared/utils/chakra-theme/colors';
import { Definitions } from '../Definitions';
import { EmailTemplates } from 'constants/email/EmailTemplates';
import { ContentTableItem } from '../ContentTableItem';
import { PolicySection } from '../PolicySection';
import { policyTableOfContent } from 'constants/platformPolicy';
import { PolicyPoint } from '../PolicyPoint';

export const PrivacyPolicy = () => {
  const handleCreateEmail = () => {
    const link = document.createElement('a');
    link.href = EmailTemplates.SIMPLE;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      bg={colors.white}
      p="48px 96px 44px"
      borderRadius="8px"
      w="100%"
      direction="column"
      mb="48px"
      border={`1px solid ${colors['primary-gray-20']}`}
    >
      <Heading
        size="poppins24"
        variant="primary-black-text"
        textAlign="center"
        mb="24px"
      >
        PASSION CREATORS WRITER’S PLATFORM
      </Heading>

      <Heading
        size="poppins24"
        variant="primary-black-text"
        textAlign="center"
        mb="24px"
      >
        IMPORTANT PRIVACY INFORMATION
      </Heading>

      <Flex direction="column" w="100%" gap="24px" mb="32px">
        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          When you access our Platform we automatically collect from
          your device language settings, IP address, time zone, type
          and model of a device, device settings, operating system,
          Internet service provider, mobile carrier, and hardware ID.
          We need this data to provide you access to the Platform and
          analyze how you use the Platform.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          For improving the Platforn, we use third party solutions.
          As a result, we may process data using solutions developed by{' '}
          <strong>Amazon, Amplitude</strong>. Therefore, some of the data
          is stored and processed on the servers of such third parties.
          This enables us to analyze different interactions with the Platfrom.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          In order to create an account on the Platfrom, we will ask you
          to provide your email, first and last name and pen name. We will
          use this data to provide you with access to the Platform, communicate
          with you during our cooperation and make payouts to you. Additionally,
          for the purposes of making payouts to you, you will be asked to
          provide your billing details. To conclude License Agreement with
          you, you will be asked to provide your name, ID number, address
          and contact email, as well as upload your ID card for KYC purposes.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          Please read our Privacy Policy below to know more about what we
          do with data (
          <Text
            as="a"
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            href="#section_3"
          >
            Section 3
          </Text>
          ), what data privacy rights are available
          to you (
          <Text
            as="a"
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            href="#section_6"
          >
            Section 6
          </Text>
          ) and who will be the data controller (
          <Text
            as="a"
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            href="#section_1"
          >
            Section 1
          </Text>
          ). If any questions will remain unanswered, please contact
          us at{' '}
          <Text
            as="span"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            onClick={handleCreateEmail}
          >
            support@passionapp.co
          </Text>
          .
        </Text>
      </Flex>

      <Heading
        size="poppins24"
        variant="primary-black-text"
        textAlign="center"
        mb="32px"
      >
        PRIVACY POLICY
      </Heading>

      <Flex direction="column" w="100%" gap="24px" mb="32px">
        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          This Privacy Policy explains what personal data is collected
          when you use the website located at:{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/"
            target="_blank"
          >
            https://passioncreators.co/
          </Text>
          {' '}(the <strong>“Platform”</strong>) and how such personal data
          will be processed.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          BY USING THE PLATFORM, YOU PROMISE US THAT (I) YOU HAVE READ,
          UNDERSTAND AND AGREE TO THIS PRIVACY POLICY, AND (II) YOU ARE
          OVER 18 YEARS OF AGE. If you do not agree, or are unable to
          make this promise, you must not use the Platform. In such case,
          you must (a) contact us and request deletion of your data; (b)
          leave the Platform and not access or use it.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          Any translation from English version is provided for your
          convenience only. In the event of any difference in meaning
          or interpretation between the English language version of this
          Privacy Policy available at{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/privacy_policy"
            target="_blank"
          >
            https://passioncreators.co/info/privacy_policy
          </Text>
          , and any translation, the English language version will prevail.
          The original English text shall be the sole legally binding version.
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          <Definitions
            definition="“GDPR”"
            description="means the General Data Protection
            Regulation (EU) 2016/679 of the European Parliament and of
            the Council of 27 April 2016 on the protection of natural
            persons with regard to the processing of personal data and
            on the free movement of such data."
          />

          <br /> <br />

          <Definitions
            definition="“EEA”"
            description="includes all current member states
            to the European Union and the European Free Trade Association.
            For the purpose of this policy EEA shall include the United
            Kingdom of Great Britain and Northern Ireland."
          />

          <br /> <br />

          <Definitions
            definition="“Process”"
            description=", in respect of personal data, includes to collect
            store, and disclose to others."
          />
        </Text>
      </Flex>

      {/* { TABLE OF CONTENTS } */}

      <Heading
        size="poppins20"
        variant="primary-black-text"
        mb="16px"
      >
        TABLE OF CONTENTS
      </Heading>

      <Flex direction="column" w="100%" gap="16px" mb="32px">
        {policyTableOfContent.map(({ stage, title, href }) => {
          return (
            <ContentTableItem
              key={stage}
              stage={stage}
              title={title}
              href={href}
            />
          );
        })}
      </Flex>

      {/* { PERSONAL DATA CONTROLLER } */}

      <PolicySection
        stage="1."
        title="PERSONAL DATA CONTROLLER"
        id="section_1"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        HW Fantasy Limited, a company registered under the laws
        of the Republic of Cyprus, having its registered office at
        46 Lykavitou, Egkomi, 2401, Nicosia, Cyprus, will be the
        controller of your personal data.
      </Text>

      {/* { CATEGORIES OF PERSONAL DATA WE COLLECT } */}

      <PolicySection
        stage="2."
        title="CATEGORIES OF PERSONAL DATA WE COLLECT"
        id="section_2"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        We collect data you give us voluntarily (for example, email
        address, pen name). We also may receive data about you from
        third parties (for example, when you sign in via Google). We
        also collect data automatically (for example, your IP address).
      </Text>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="2.1."
          title="Data you give us"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          In order to create an account on the Platform, we will
          ask you to provide your email, first and last name, pen
          name. We will use this data to provide you with access
          to the Platform, communicate with you during our cooperation
          and make payouts to you. Additionally, for the purposes of
          making payouts to you, you will be asked to provide your billing
          details. To conclude License Agreement with you, you will be asked
          to provide your name, ID number, address and contact email, as
          well as upload your ID card for KYC purposes. You may also
          provide us with your photo and social media information.
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="2.2."
          title="Data provided by third parties"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          When you decide to log in using Google, we receive personal
          data from your Google Account: name, email address, profile
          picture associated with your Google Account. You can revoke
          access provided to us on{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://myaccount.google.com/permissions"
            target="_blank"
          >
            Apps Permissions page
          </Text>
          . To know more
          about how Google processes your data, visit its{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://policies.google.com/privacy"
            target="_blank"
          >
            Privacy Policy
          </Text>
          .
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="2.3."
          title="Data we collect automatically:"
        />

        <Flex ml="38px" mb="24px" direction="column">
          <PolicyPoint
            stage="2.3.1."
            title="Data about how you found us"
          />

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textAlign="justify"
            ml="26px"
          >
            We collect data about your referring app or URL (that is,
            the app or place on the Web where you were when you
            tapped/clicked on our ad).
          </Text>
        </Flex>

        <Flex ml="38px" mb="24px" direction="column">
          <PolicyPoint
            stage="2.3.2."
            title="Device and Location data"
          />

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textAlign="justify"
            ml="26px"
          >
            We collect data from your mobile device. Examples
            of such data include: language settings, IP address,
            time zone, type and model of a device, device settings,
            operating system, Internet service provider, mobile carrier,
            and hardware ID.
          </Text>
        </Flex>

        <Flex ml="38px" mb="24px" direction="column">
          <PolicyPoint
            stage="2.3.3."
            title="Usage data"
          />

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textAlign="justify"
            ml="26px"
          >
            We record how you interact with our Platform.
            For example, we log what pages you have viewed,
            the features and content you interact with, how
            often you use the Platform, how long you are
            on the Platform.
          </Text>
        </Flex>
      </Flex>

      {/* { FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA } */}

      <PolicySection
        stage="3."
        title="FOR WHAT PURPOSES WE PROCESS YOUR PERSONAL DATA"
        id="section_3"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        We process your personal data:
      </Text>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.1."
          title="To provide access to the Platform"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          This includes enabling you to use the Platform
          in a seamless manner and preventing or addressing
          service errors or technical issues. As a result of
          such processing, we will use your email, for example,
          for creating your account, communicating with you
          during our cooperation and resolving any support cases.
        </Text>

        <br />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          To host personal data and enable our Service to operate
          and be distributed we use <strong>Amazon Web Services</strong>,
          which is a hosting and backend service provided by Amazon.
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.2."
          title="To provide you with technical support"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We process your personal data to respond to your requests
          for technical support, Platform information or to any
          other communication you initiate. For this purpose,
          we may send you, for example, notifications or emails
          about, actions made on the Platform in course of our
          cooperation, books publication, security, payments, notices
          regarding our{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/terms"
            target="_blank"
          >
            Terms
          </Text>
          {' '}or this Privacy Policy.
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.3."
          title="To communicate with you regarding your use of Platform"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We communicate with you, for example, by emails. These
          may include for example, emails with information about
          the Platform. To opt out of receiving emails, you need
          to follow the instructions in the footer of the emails.
          Please note that you will not be able to opt-out of
          transactional emails, i.e. emails we need to send you
          need to send in order to facilitate your use of the Platform.
        </Text>

        <br />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          The services that we use for these purposes may collect
          data concerning the date and time when the message was
          viewed by our Service’s users, as well as when they
          interacted with it, such as by tapping/clicking on links
          included in the message.
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.4."
          title="To provide you with technical support"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          This helps us to better understand our business, analyze
          our operations, maintain, improve, innovate, plan, design,
          and develop the Platform and our new products. We also use
          such data for statistical analysis purposes, to test and
          improve our practices of cooperation with creators. As a
          consequence, we often decide how to improve the Platform
          based on the results obtained from this processing.
        </Text>

        <br />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          <strong>Amplitude</strong> is an analytics service provided by
          Amplitude Inc. We use this tool to understand how customers use
          our Service. Amplitude collects various technical information,
          in particular, time zone, type of device (phone, tablet or laptop),
          unique identifiers (including advertising identifiers). Amplitude
          also allows us to track various interactions that occur in our Platform.
          As a result, Amplitude helps us to decide what features we
          should focus on. Amplitude provides more information on how
          they process data in its{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://amplitude.com/privacy"
            target="_blank"
          >
            Privacy Policy
          </Text>
          .
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.5."
          title="To make payments to you"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          In result of our cooperation, you may receive certain
          payouts. For purpose of making payments to you, we use
          billing information provided by you via the Platform.
          As a result of this processing, we will be able to make
          a payment to you.
        </Text>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="3.6."
          title="To enforce our Terms and to prevent and combat fraud"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We use personal data to enforce our agreements
          and contractual commitments, to detect, prevent,
          and combat fraud. As a result of such processing,
          we may share your information with others,
          including law enforcement agencies (in particular,
          if a dispute arises in connection with our{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/terms"
            target="_blank"
          >
            Terms
          </Text>
          ).
        </Text>
      </Flex>

      <Flex ml="76px" mb="32px" direction="column">
        <PolicyPoint
          stage="3.7."
          title="To comply with legal obligations"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We may process, use, or share your data when the law
          requires it, in particular, if a law enforcement agency
          requests your data by available legal means.
        </Text>
      </Flex>

      {/* { UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA } */}

      <PolicySection
        stage="4."
        title="UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA"
        id="section_4"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        In this section, we are letting you know what legal
        basis we use for each particular purpose of processing.
        For more information on a particular purpose, please refer to{' '}
        <Text
          as="a"
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          href="#section_3"
        >
          Section 3
        </Text>
        . This section applies only to EEA-based users.
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        We process your personal data under the following legal bases:
      </Text>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="4.1."
          title="to perform our contract with you;"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          Under this legal basis we:
        </Text>

        <br />

        <UnorderedList ml="52px">
          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Provide access to the Platform (in accordance with our Terms);
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Provide you with technical support;
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Communicate with you regarding your use of our Platform;
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Make payments to you.
            </Text>
          </ListItem>
        </UnorderedList>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="4.2."
          title="for our (or others') legitimate interests, unless
          those interests are overridden by your interests or fundamental
          rights and freedoms that require protection of personal data;"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We rely on legitimate interests:
        </Text>

        <br />

        <UnorderedList ml="52px">
          <ListItem mb="24px">
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              to communicate with you regarding your use of our Platform
            </Text>

            <br />

            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              This includes, for example, sending you email to notify
              you that your story has been successfully submitted
              for the review etc. The legitimate interest we rely on
              for this purpose is our interest to encourage you to
              use our Platform.
            </Text>
          </ListItem>

          <ListItem mb="24px">
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              to research and analyze your use of the Platform
            </Text>

            <br />

            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Our legitimate interest for this purpose is our
              interest in improving our Platform so that we understand
              creators’ preferences and are able to provide you with
              a better experience (for example, to make the use of the
              Platform easier and more enjoyable, or to introduce and
              test new features).
            </Text>
          </ListItem>

          <ListItem mb="24px">
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              to enforce our{' '}
              <Text
                as="a"
                variant="primary-violet-100"
                size="poppins16normal"
                letterSpacing="-0.016px"
                lineHeight="28px"
                textDecoration="underline"
                cursor="pointer"
                href="https://passioncreators.co/info/terms"
                target="_blank"
              >
                Terms
              </Text>
              {' '}and to prevent and combat fraud
            </Text>


            <br />

            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              Our legitimate interests for this purpose are enforcing
              our legal rights, preventing and addressing fraud and
              unauthorised use of the Platform, non-compliance with our{' '}
              <Text
                as="a"
                variant="primary-violet-100"
                size="poppins16normal"
                letterSpacing="-0.016px"
                lineHeight="28px"
                textDecoration="underline"
                cursor="pointer"
                href="https://passioncreators.co/info/terms"
                target="_blank"
              >
                Terms
              </Text>
              .
            </Text>
          </ListItem>
        </UnorderedList>
      </Flex>

      <Flex ml="76px" mb="32px" direction="column">
        <PolicyPoint
          stage="4.3."
          title="to comply with legal obligations."
        />
      </Flex>

      {/* { WITH WHOM WE SHARE YOUR PERSONAL DATA } */}

      <PolicySection
        stage="5."
        title="WITH WHOM WE SHARE YOUR PERSONAL DATA"
        id="section_5"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        We share information with third parties that help us operate,
        provide, improve, integrate, customize, support, and market
        our Service. We may share some sets of personal data, in
        particular, for purposes and with parties indicated in{' '}
        <Text
          as="a"
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          href="#section_3"
        >
          Section 3
        </Text>
        {' '}of this Privacy Policy. The types of third parties we
        share information with include, in particular:
      </Text>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="5.1."
          title="Service providers"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We share personal data with third parties that we
          hire to provide services or perform business functions
          on our behalf, based on our instructions. We may share
          your personal information with the following types of
          service providers:
        </Text>

        <br />

        <UnorderedList ml="52px">
          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              cloud storage providers (Amazon)
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              data analytics providers (Amplitude)
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              measurement partners
            </Text>
          </ListItem>

          <ListItem>
            <Text
              variant="primary-black-text"
              size="poppins16normal"
              letterSpacing="-0.016px"
              lineHeight="28px"
              textAlign="justify"
              ml="26px"
            >
              communication service providers
            </Text>
          </ListItem>
        </UnorderedList>
      </Flex>

      <Flex ml="76px" mb="24px" direction="column">
        <PolicyPoint
          stage="5.2."
          title="Law enforcement agencies and other public authorities"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          We may use and disclose personal data to enforce our{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/terms"
            target="_blank"
          >
            Terms
          </Text>
          , to protect our rights, privacy, safety, or property,
          and/or that of our affiliates, you or others, and
          to respond to requests from courts, law enforcement
          agencies, regulatory agencies, and other public an
          government authorities, or in other cases provided for by law.
        </Text>
      </Flex>

      <Flex ml="76px" mb="32px" direction="column">
        <PolicyPoint
          stage="5.3."
          title="Third parties as part of a merger or acquisition"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
          ml="26px"
        >
          As we develop our business, we may buy or sell assets
          or business offerings. We may also share such information
          with any affiliated entity (e.g. parent company or subsidiary)
          and may transfer such information in the course of a
          corporate transaction, such as the sale of our business,
          a divestiture, merger, consolidation, or asset sale, or
          in the unlikely event of bankruptcy.
        </Text>
      </Flex>

      {/* { HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS } */}

      <PolicySection
        stage="6."
        title="HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS"
        id="section_6"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        To be in control of your personal data, you have the following rights:
        <br /> <br />
        <strong>Accessing / reviewing / updating / correcting your personal data.</strong>{' '}
        You may review, edit, or change the personal data that you
        had previously provided on the Platform.
        <br /> <br />
        <strong>Deleting your personal data.</strong>{' '}
        You can request erasure of your personal data as permitted by law.
        <br /> <br />
        When you request deletion of your personal data, we will
        use reasonable efforts to honor your request. In some cases,
        we may be legally required to keep some of the data for a
        certain time; in such event, we will fulfill your request
        after we have complied with our obligations.
        <br /> <br />
        <strong>Objecting to or restricting the use of your personal data.</strong>
        You can ask us to stop using all or some of your personal
        data or limit our use thereof.
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        ml="76px"
        mb="24px"
      >
        <u><b>Additional information for EEA-based users:</b></u>
        <br /> <br />
        If you are based in the EEA, you have the following
        rights in addition to the above:
        <br /> <br />
        <b>The right to lodge a complaint with supervisory authority.</b>{' '}
        We would love you to contact us directly, so we could address
        your concerns. Nevertheless, you have the right to lodge a
        complaint with a competent data protection supervisory
        authority, in particular in the EU Member State where you
        reside, work or where the alleged infringement has taken place.
        <br /> <br />
        <b>The right to data portability.</b>{' '}
        If you wish to receive your personal data in a machine-readable
        format, you can send respective request to us as described below.
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        To exercise any of your privacy rights,
        please send a request to {' '}
        <Text
          as="span"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleCreateEmail}
        >
          support@passionapp.co
        </Text>
        .
      </Text>

      {/* { AGE LIMITATION } */}

      <PolicySection
        stage="7."
        title="AGE LIMITATION"
        id="section_7"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        We do not knowingly process personal data from
        persons under 18 years of age. If you learn that
        anyone younger than 18 has provided us with personal
        data, please contact us.
      </Text>

      {/* { INTERNATIONAL DATA TRANSFERS } */}

      <PolicySection
        stage="8."
        title="INTERNATIONAL DATA TRANSFERS"
        id="section_8"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="24px"
        ml="38px"
      >
        We may transfer personal data to countries other than the
        country in which the data was originally collected in order
        to provide the access to the Platform as set forth in the{' '}
        <Text
          as="a"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          href="https://passioncreators.co/info/terms"
          target="_blank"
        >
          Terms
        </Text>
        and for purposes indicated in this Privacy Policy. If
        these countries do not have the same data protection laws
        as the country in which you initially provided the information,
        we deploy special safeguards.
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        In particular, if we transfer personal data originating from the
        EEA to countries with not adequate level of data protection,
        we use one of the following legal bases: (i) Standard Contractual
        Clauses approved by the European Commission (details available{' '}
        <Text
          as="a"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en"
          target="_blank"
        >
          here
        </Text>
        ), or (ii) the European Commission adequacy decisions about certain
        countries (details available{' '}
          <Text
          as="a"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en"
          target="_blank"
        >
          here
        </Text>
        ).
      </Text>

      {/* { CHANGES TO THIS PRIVACY POLICY } */}

      <PolicySection
        stage="9."
        title="CHANGES TO THIS PRIVACY POLICY"
        id="section_9"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        We may modify this Privacy Policy from time to time.
        If we decide to make material changes to this Privacy
        Policy, you will be notified by available means such
        as email and will have an opportunity to review the
        revised Privacy Policy. By continuing to access or use
        the Service after those changes become effective, you agree
        to be bound by the revised Privacy Policy.
      </Text>

      {/* { DATA RETENTION } */}

      <PolicySection
        stage="10."
        title="DATA RETENTION"
        id="section_10"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        We will store your personal data for as long as it is
        reasonably necessary for achieving the purposes set
        forth in this Privacy Policy (including providing the
        access to Platform to you). We will also retain and use
        your personal data as necessary to comply with our legal
        obligations, resolve disputes, and enforce our agreements.
      </Text>

      {/* { HOW “DO NOT TRACK” REQUESTS ARE HANDLED } */}

      <PolicySection
        stage="11."
        title="HOW “DO NOT TRACK” REQUESTS ARE HANDLED"
        id="section_11"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        This Platform does not support “Do Not Track” requests.
        To determine whether any of the third-party services it
        uses honor the “Do Not Track” requests, please read their
        privacy policies.
      </Text>

      {/* { CONTACT US } */}

      <PolicySection
        stage="12."
        title="CONTACT US"
        id="section_12"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
        ml="38px"
      >
        You may contact us at any time for details regarding
        this Privacy Policy and its previous versions. For any
        questions concerning your account or your personal data
        please contact us at{' '}

        <Text
          as="span"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleCreateEmail}
        >
          support@passionapp.co
        </Text>
        .
      </Text>
    </Flex>
  );
};
