import { COVER_ACTION } from 'utils/enums/cover-action.enum';

export const MAX_BOOK_TITLE_LENGTH = 60;
export const MIN_BOOK_DESCRIPTION_LENGTH = 200;
export const MAX_BOOK_DESCRIPTION_LENGTH = 4000;
export const MIN_BOOK_CHAPTERS_AMOUNT = 10;
export const BOOK_LIMIT = 10;
export const SOURCERS_BOOK_LIMIT = 50;
export const MAX_DISPLAY_TAGS_COUNT = 2;
export const BOOK_CREATE_THROTTLE_DELAY = 1000;
export const MAX_TAGS_COUNT = 10;
export const MAX_COVER_WEIGHT = 10485760;
export const COVER_FORMATS = 'png jpeg jpg';
export const HUNDRED = 100;
export const MAX_HEIGHT = 3;
export const MAX_WIDTH = 2;
export const ASPECT_RATIO = MAX_WIDTH / MAX_HEIGHT * 100;
export const EMPTY_CHAPTER_TEXT = '<p></p>';
export const MIN_CHAPTERS_COUNT = 21;

interface Status {
	displayName: string;
	value: string;
}

interface StoryStatus {
	[x: string]: Status;
}

export const BookStatus: StoryStatus = Object.freeze({
	APPROVED: {
		displayName: 'Published',
		value: 'APPROVED',
	},
	DRAFT: {
		displayName: 'Draft',
		value: 'DRAFT',
	},
	ON_MODERATION: {
		displayName: 'In review',
		value: 'ON_MODERATION',
	},
	TO_MODERATION: {
		displayName: 'Waiting for review',
		value: 'TO_MODERATION',
	},
	REJECTED: {
		displayName: 'Rejected',
		value: 'REJECTED',
	},
});

interface LanguageName {
	[x: string]: string;
}

export const LanguageNames: LanguageName = {
	en: 'English',
	fil: 'Fillipino'
};

export const CoverActions = [
	{
		title: 'Awesome! Let`s do it',
		action: COVER_ACTION.GENERATE,
	},
	{
		title: 'My book has a cover',
		action: COVER_ACTION.UPLOAD,
	},
];
