import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { AdminNotifToastProps, ShortPropsWithName, UpdatesFullProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';
import { useLazyQuery } from '@apollo/client';
import { APPLY_FOR_CONTEST } from 'entites/notifications/notifications.graphql';
import useShowToast from 'hooks/useShowToast';

export const WritersContestToast: FC<AdminNotifToastProps> = ({
  id,
  handleClose,
  createdBy
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Body
        text={<>from <span style={{ fontWeight: 600 }}>{createdBy}</span></>}
        title={t('Become a Scriptwriter and Win $5000!')}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const WritersContestShort: FC<ShortPropsWithName> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
  createdBy,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={bg}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Become a Scriptwriter and Win $5000!')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          <>from <span style={{ fontWeight: 600 }}>{createdBy}</span></>
        </Text>
      </Flex>
    </Flex>
  );
};

export const WritersContestFull: FC<UpdatesFullProps> = ({
  createdAt,
  createdBy,
  createdByEmail,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  const { showErrorToast, showSuccessToast } = useShowToast();

  
  const [applyForContest] = useLazyQuery(APPLY_FOR_CONTEST, {
    fetchPolicy: 'network-only',
  });

  const handleApply = async () => {
    const { data: {
      result
    }} = await applyForContest();

    if (result.success) {
      showSuccessToast({
        id: "successfullySendContestApplication",
        title: `The application was successfully sent`,
        description: result.success,
        img: "/images/story/paperplane.png",
      });

      return;
    }

    if (result.error) {
      showErrorToast({
        id: "definedErrorSendContestApplication",
        title: "Something went wrong",
        reason: result.error,
      });

      return;
    }

    showErrorToast({
      id: "undefinedErrorSendContestApplication",
      title: "Sorry something went wrong",
      reason: "Please, try again later",
    });
  };

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['primary-violet-30']}
              img="/images/hero/heart.svg"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {createdBy}
            </Text>
            {` <${createdByEmail}>`}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="247px"
        h="130px"
        src="/images/notifications/custom/contest.png"
        loading="eager"
        alt="changes"
      />

      <Box maxW="600px">
        <Text
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={400}
          textAlign="center"
        >
          {t(`Hi, ${user?.first_name}!`)}
        </Text>
      </Box>

      <Box maxW="600px">
        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
          mb="44px"
        >
          {t('Are you ready to take your writing to the next level? We\'re excited to announce our first-ever Scriptwriting Contest!')}
        </Text>

        <Box w="100%" mb="44px">
          <Text
            variant="primary-violet-100"
            size="poppins24"
            lineHeight="32px"
            fontWeight={700}
            textAlign="center"
          >
            🤑{t('Win $5000')}🤑
          </Text>
          <Text
            variant="primary-black-text"
            size="poppins24"
            lineHeight="32px"
            fontWeight={600}
            textAlign="center"
          >
            {t('and have your script brought to life!')}
          </Text>
          <Text
            variant="primary-black-text"
            size="poppins20normal"
            lineHeight="32px"
            textAlign="center"
          >
            {t('All you need is a beat sheet or outline to qualify!')}
          </Text>
        </Box>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
          mb="32px"
        >
          {t('If you\'re interested in participating, simply push the ')}
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins20normal"
            lineHeight="32px"
            fontWeight={500}
            mb="32px"
          >
            {t('"Interested"')}
          </Text>
          {t(' button below and we\'ll send you all the details.')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
          mb="20px"
        >
          {t('Don\'t miss this opportunity to showcase your talent and become a part of our creative community!')}
        </Text>
      </Box>

      <Button
        variant="defaultButton"
        onClick={handleApply}
      >
        {t('Interested')}
      </Button>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};

