import { defineStyle } from '@chakra-ui/react';
import { colors } from '../colors';

export const guidline = defineStyle({
  item: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '28px',
    fontFamily: `'Poppins', sans-serif`,
    color: colors['primary-black-text'],
    letterSpacing: '-0.016px',
    ml: '8px',
    "&::marker": {
      fontSize: '12px',
    }
  },
});
