import {
	closeAllButton,
	defaultButton,
	facebookButton,
	googleButton,
	outlineButton,
	textButton,
} from './button';
import { formLabelStyles } from './formLabel';
import { headingStyle } from './heading';
import { menuStyles } from './menu';
import { selectStyles } from './select';
import { textStyle } from './text';
import { modalStyle } from './modal';
import { createStory, danger } from './tooltip';
import { docsPrimary, resetPassword } from './link';
import { contractField, contractFieldError, loginField, loginFieldError, paywallField, storyTitleField, tagField, titleField, titleFieldError } from './input';
import { guidline } from './list';
import { deleteReason, description, descriptionError } from './textarea';
import { chapterTable } from './table';

export const components = {
	Table: {
		variants: {
			chapterTable,
		}
	},
	Button: {
		variants: {
			facebookButton,
			googleButton,
			defaultButton,
			outlineButton,
			closeAllButton,
			textButton,
		}
	},
	Tooltip: {
		variants: {
			createStory,
			danger,
		}
	},
	Menu: menuStyles,
	Text: textStyle,
	Heading: headingStyle,
	Input: {
		variants: {
			loginField,
			loginFieldError,
			titleField,
			titleFieldError,
			tagField,
			storyTitleField,
			contractField,
			contractFieldError,
		}
	},
	NumberInput: {
		variants: {
			paywallField,
		}
	},
	Select: selectStyles,
	FormLabel: formLabelStyles,
	Modal: modalStyle,
	Link: {
		variants: {
			docsPrimary,
			resetPassword,
		}
	},
	List: {
		variants: {
			guidline,
		}
	},
	Textarea: {
		variants: {
			description,
			descriptionError,
			deleteReason,
		}
	}
};
