import { menuAnatomy as parts } from '@chakra-ui/anatomy';

import { mode } from '@chakra-ui/theme-tools';

const baseStyleList = () => {
	return {
		boxShadow:
			'0px 4px 10px rgba(0, 0, 0, 0.1), 0px 0px 1px rgba(67, 90, 111, 0.47)',
		color: 'inherit',
		bg: '#FDFDFD',
		maxW: '232px',
		maxH: '220px',
		p: '12px 8px',
		zIndex: 1,
		borderRadius: '8px',
	};
};

const baseStyleItem = (props: any) => {
	return {
		py: '9px',
		px: '8px',
		transitionProperty: 'background',
		transitionDuration: 'ultra-fast',
		transitionTimingFunction: 'ease-in',
		borderRadius: '6px',
		fontWeight: 600,
		fontSize: '13px',
		lineHeight: '22px',
		letterSpacing: '-0.408px',
		color: '#0B080F',
		_focus: {
			outline: 'none',
		},
		_active: {
			bg: '#F8F8F8',
		},
		_expanded: {
			bg: mode('gray.100', 'whiteAlpha.100')(props),
		},
		_disabled: {
			opacity: 0.4,
			cursor: 'not-allowed',
		},
	};
};

const baseStyleGroupTitle = {
	mx: 4,
	my: 2,
	fontWeight: 'semibold',
	fontSize: 'sm',
};

const baseStyleCommand = {
	opacity: 0.6,
};

const baseStyleDivider = {
	border: 0,
	borderBottom: '1px solid',
	borderColor: 'inherit',
	my: '0.5rem',
	opacity: 0.6,
};

const baseStyleButton = {
	transitionProperty: 'common',
	transitionDuration: 'normal',
};

const baseStyle = (props: any) => ({
	button: baseStyleButton,
	list: baseStyleList(),
	item: baseStyleItem(props),
	groupTitle: baseStyleGroupTitle,
	command: baseStyleCommand,
	divider: baseStyleDivider,
});

export const menuStyles = {
	parts: parts.keys,
	baseStyle,
};
