import { colors } from '../colors';

export const headingStyle = {
	baseStyle: {
		fontWeight: 800,
	},
	sizes: {
		poppins18: {
			fontWeight: 600,
			fontSize: '18px',
			lineHeight: '38px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins20: {
			fontWeight: 600,
			fontSize: '20px',
			lineHeight: '22px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins24: {
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: '22px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins25: {
			fontWeight: 600,
			fontSize: '25px',
			lineHeight: '34px',
			letterSpacing: '-0.024px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins28: {
			fontWeight: 600,
			fontSize: '28px',
			lineHeight: '36px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins38: {
			fontWeight: 600,
			fontSize: '38px',
			lineHeight: '78px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		nunito50: {
			fontSize: '50px',
			fontFamily: `'Nunito', sans-serif`,
			lineHeight: '100%',
		},
	},
	variants: {
		'primary-black-text': {
			color: colors['primary-black-text'],
		},
		'primary-blue-100': {
			color: colors['primary-blue-100'],
		},
		'light-text-text-danger': {
			color: colors['light-text-text-danger']
		},
		white: {
			color: '#f4f4f4',
		},
	},
	defaultProps: {
		size: 'nunito50',
		variant: 'white',
	},
};
