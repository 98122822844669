export const policyTableOfContent = [
  {
    stage: '1.',
    title: 'PERSONAL DATA CONTROLLER',
    href: 'section_1',
  },
  {
    stage: '2.',
    title: 'CATEGORIES OF PERSONAL DATA WE COLLECT',
    href: 'section_2',
  },
  {
    stage: '3.',
    title: 'FOR WHAT PURPOSES WE PROCESS PERSONAL DATA',
    href: 'section_3',
  },
  {
    stage: '4.',
    title: 'UNDER WHAT LEGAL BASES WE PROCESS YOUR PERSONAL DATA (Applies only to EEA-based users)',
    href: 'section_4',
  },
  {
    stage: '5.',
    title: 'WITH WHOM WE SHARE YOUR PERSONAL DATA',
    href: 'section_5',
  },
  {
    stage: '6.',
    title: 'HOW YOU CAN EXERCISE YOUR PRIVACY RIGHTS',
    href: 'section_6',
  },
  {
    stage: '7.',
    title: 'AGE LIMITATION',
    href: 'section_7',
  },
  {
    stage: '8.',
    title: 'INTERNATIONAL DATA TRANSFERS',
    href: 'section_8',
  },
  {
    stage: '9.',
    title: 'CHANGES TO THIS PRIVACY POLICY',
    href: 'section_9',
  },
  {
    stage: '10.',
    title: 'DATA RETENTION',
    href: 'section_10',
  },
  {
    stage: '11.',
    title: 'HOW “DO NOT TRACK” REQUESTS ARE HANDLED',
    href: 'section_11',
  },
  {
    stage: '12.',
    title: 'CONTACT US',
    href: 'section_12',
  },
];
