import { useLayoutEffect } from 'react';

const useLockBodyScroll = (lock = true) => {
	useLayoutEffect(() => {
		const originalStyle = window.getComputedStyle(document.body).overflow;
		if (lock) {
			document.body.style.overflow = 'hidden';
		}
		return () => {
			document.body.style.overflow = originalStyle;
			document.body.style.overflow = 'auto';
		};
	}, [lock]);
};

export default useLockBodyScroll;
