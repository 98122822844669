import { Flex, Heading, Text } from '@chakra-ui/react';
import { colors } from 'shared/utils/chakra-theme/colors';
import { Subpoint } from '../Subpoint';
import { GeneralPoint } from '../GeneralPoint';
import { Section } from '../Section';
import { Definitions } from '../Definitions';
import { EmailTemplates } from 'constants/email/EmailTemplates';

export const Terms = () => {
  const handleCreateEmail = () => {
    const link = document.createElement('a');
    link.href = EmailTemplates.SIMPLE;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Flex
      bg={colors.white}
      p="48px 96px 44px"
      borderRadius="8px"
      w="100%"
      direction="column"
      mb="48px"
      border={`1px solid ${colors['primary-gray-20']}`}
    >
      <Heading
        size="poppins24"
        variant="primary-black-text"
        textAlign="center"
        mb="24px"
      >
        PASSION CREATORS WRITER’S PLATFORM
      </Heading>

      <Heading
        size="poppins24"
        variant="primary-black-text"
        textAlign="center"
        mb="24px"
      >
        TERMS AND CONDITIONS
      </Heading>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
      >
        These Passion Creators Writer’s Platform Terms
        and Conditions (the <strong>“Terms”</strong>) govern
        the relationship between you (<strong>“Creator”</strong>) and
        Passion (<strong>"we" "us" "our"</strong>) regarding
        your cooperation with Passion and use of the
        Platform (as defined below) in order to provide
        Creator’s Content (as defined below) to Passion,
        manage Creator’s Content though the Platform and
        withdraw your compensation.
      </Text>

      {/* { DEFINITIONS } */}

      <Section
        stage="1."
        title="DEFINITIONS"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
      >
        <Definitions
          definition="Creator"
          description="means you, an author of the story and/or book
          who wish to cooperate with Passion and use the Platform."
        />
        <br /> <br />
        <Definitions
          definition="Creator’s Content"
          description="means certain original
          book(s)/stories which are created by and uploaded to
          the Platform by Creator and are eligible for publishing
          on Passion Properties. The criteria of eligibility are
          specified on the Platform."
        />
        <br /> <br />
        <Definitions
          definition="Content"
          description="means any data, applications, files,
          information, or materials on the Platform except for
          Creator’s Content."
        />
        <br /> <br />
        <Definitions
          definition="End Users"
          description="means any data, applications, files,
          information, or materials on the Platform except for
          Creator’s Content."
        />
        <br /> <br />
        <Definitions
          definition="License Agreement"
          description="means agreement between
          Creator (Licensor) and Passion (Licensee) regarding granting
          of license to the Creator’s Content as part of the
          cooperation between Creator and Passion."
        />
        <br /> <br />
        <Definitions
          definition="Passion"
          description="means HW Fantasy Limited (registration
          number: ΗΕ 450256, registered office: 46 Lykavitou,
          Egkomi, 2401, Nicosia, Cyprus) and its Affiliates. For the
          purposes of this Agreement, Affiliates mean companies directly
          or indirectly controlling, controlled by, or under common
          control with HW Fantasy Limited."
        />
        <br /> <br />
        <Definitions
          definition="Passion Properties"
          description="mean certain mobile
          application(s) and/or website(s) owned and/or operated by
          Passion where the Creator’s Content is published and made
          available to End Users in accordance with respective License Agreement."
        />
        <br /> <br />
        <Definitions
          definition="Platform"
          description="means Pasion Creators Writer’s
          platform operated by Passion and available via"
        />
        {' '}
        <Text
          as="a"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          href="https://passioncreators.co/"
          target="_blank"
        >
          https://passioncreators.co/
        </Text>
        .
      </Text>

      {/* { ACCEPTANCE OF TERMS } */}

      <Section
        stage="2."
        title="ACCEPTANCE OF TERMS"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="2.1."
          description="These terms, when accepted by you after you
          click the respective button or checkbox at the time you
          register an account, will create a binding and legally
          enforceable contract between you and Passion. PLEASE READ
          THESE TERMS CAREFULLY AND CONSULT WITH YOUR OWN BUSINESS
          AND LEGAL ADVISORS BEFORE CLICKING “I AGREE” BUTTON OR
          CHECKBOX."
        />

        <br />

        <GeneralPoint
          stage="2.2."
          description="The “Effective date” of these Terms is the
          date on which you click the respective button or checkbox
          at the time you register an account on the Platform."
        />

        <br />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textAlign="justify"
            mr="16px"
          >2.3.</Text>
          Please review also our{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/privacy_policy"
            target="_blank"
          >
            Privacy Policy
          </Text>
          . The terms of the Privacy Policy and other supplemental terms,
          policies or documents that may be posted on the Platform
          from time to time are hereby expressly incorporated herein by
          reference.
        </Text>

        <br />

        <GeneralPoint
          stage="2.4."
          description='We reserve the right, in our sole discretion,
          to make changes or modifications to these Terms at any time
          and for any reason. Unless otherwise expressly provided
          herein, we will alert you about any changes by updating
          the "Last updated" date of these Terms and you waive any
          right to receive specific notice of each such change.'
        />

        <br />

        <GeneralPoint
          stage="2.5."
          description="IF YOU DO NOT AGREE WITH ANY PART OF THESE
          TERMS, THEN DO NOT ACCESS OR USE THE PLATFORM."
        />
      </Flex>

      {/* { ACCOUNT } */}

      <Section
        stage="3."
        title="ACCOUNT"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="3.1."
          description="In order to use the Platform, Creator must register
          an account. To create an account, Creator will be required to
          provide certain information (i.e. email) and establish a password.
          Alternatively, Creator may sign up using its Google account.
          Creator must be eighteen years of age or older to use the
          Platform and sign License Agreement with Passion."
        />

        <br />

        <GeneralPoint
          stage="3.2."
          description="Creator agrees to provide accurate, current and
          complete information during the registration process and
          to update such information to keep it accurate, current
          and complete."
        />

        <br />

        <GeneralPoint
          stage="3.3."
          description="Passion reserves the right to suspend or
          terminate Creator’s account if any information provided
          during the registration process or thereafter is reasonably
          suspected by Passion to be inaccurate, not up-to-date or
          incomplete."
        />

        <br />

        <GeneralPoint
          stage="3.4."
          description="Creator is responsible for maintaining the
          security of its account, and passwords. Creator agrees not
          to disclose its password to any third party and to take
          sole responsibility for any activities or actions under
          its account, whether or not Creator has authorized such
          activities or actions."
        />

        <br />

        <GeneralPoint
          stage="3.5."
          description="Creator shall immediately notify Passion of
          any unauthorized use of its account."
        />

        <br />

        <GeneralPoint
          stage="3.6."
          description="Creation of the account does not guarantee
          that Creator’s Content will be accepted by Passion once
          uploaded to the Platform and/or that Creator’s Content will
          be published on Passion Properties. Passion may or may not
          accept uploaded Creator’s Content at Passion's sole discretion.
          Furthermore, you may apply for License Agreement only if
          Creator’s Content satisfies eligibility criteria communicated
          to you via Platform. Passion reserves the right to decline
          your application for License Agreement (і) in case Creator’s
          Content does not satisfy eligibility criteria and/or (іі) i
          case of incompliance with Passion’s requirements and guidelines
          and/or (ііі) if the conclusion of the License Agreement will
          contradict the applicable laws and regulations."
        />
      </Flex>

      {/* { CREATOR’S COOPERATION WITH PASSION. CREATOR’S CONTENT } */}

      <Section
        stage="4."
        title="CREATOR’S COOPERATION WITH PASSION. CREATOR’S CONTENT"
      />

      <Flex mb="32px" direction="column">
        <Text
          variant="primary-black-text"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textAlign="justify"
        >
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textAlign="justify"
            mr="16px"
          >4.1.</Text>
          Your registration information and Creator’s Content
          remain your intellectual property, and Passion does not
          claim any ownership of the copyright or other proprietary
          rights in such registration information and the Creator’s Content.
          You are solely responsible for any and all registration
          information and Creator’s Content. Notwithstanding the foregoing,
          you agree that Passion may retain copies of the registration
          information and use it as reasonably necessary for or incidental
          to its operation of the Platform and as described in these Terms and{' '}
          <Text
            as="a"
            variant="primary-violet-100"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
            textDecoration="underline"
            cursor="pointer"
            href="https://passioncreators.co/info/privacy_policy"
            target="_blank"
          >
            the Privacy Policy
          </Text>
          .
        </Text>

        <br />

        <GeneralPoint
          stage="4.2."
          description="Your grant of a license to use the Creator’s
          Content shall be governed by respective License Agreements."
        />

        <br />

        <GeneralPoint
          stage="4.3."
          description="Passion will publish Creator’s Content and
          it will start generating income for you, only after signing
          of License Agreement and subject to eligibility criteria
          communicated to you through the Platform."
        />

        <br />

        <GeneralPoint
          stage="4.4."
          description="Notwithstanding anything to the contrary contained
          in these Terms, Passion shall have no obligation to publish
          any Creator’s Content provided by Creator through the Platform."
        />

        <br />

        <GeneralPoint
          stage="4.5."
          description="Creator’s Content shall be in accordance with
          requirements and guidelines of Passion communicated to you
          through the Platform. Non-compliance of Creator’s Content
          with requirements and guidelines constitutes a breach of
          these Terms."
        />

        <br />

        <GeneralPoint
          stage="4.6."
          description="Passion cannot predict or guarantee the types
          of Creator’s Content that End Users will be interested in,
          and Passion makes no representation or warranty as to the
          amount of compensation that Creator will earn as the result
          of entering into License Agreement, once eligible for applying."
        />

        <br />

        <GeneralPoint
          stage="4.7."
          description="Passion will undertake at its sole discretion
          any marketing and promotional activities as it deems fit relate
          to distribution of Creator’s Content through Passion Properties
          and may without limitation, market and promote Creator’s Content
          by making chapters or portions of Creator’s Content available
          to prospective End Users for free, and by permitting prospective
          End Users to see excerpts of Creator’s Content in response to
          search queries. Creator acknowledges that Passion has no
          obligation to market any Creator’s Content. For the avoidance
          of doubt, Creator will not receive License Fee in case Creator’s
          Content is used for promotional purposes as described herein."
        />

        <br />

        <GeneralPoint
          stage="4.8."
          description="Passion may from time to time make available to
          Creators bonus programs or other optional programs though the
          Platform. Terms and conditions for such bonus and other optional
          programs will be provided when such programs are offered and
          shall be deemed a part of this Terms."
        />

        <br />

        <GeneralPoint
          stage="4.9."
          description="To the extent not prohibited by applicable laws,
          Passion has sole and absolute discretion to set prices at whic
          Creator’s Content will be available to End Users through the
          Properties."
        />
      </Flex>

      {/* { USE OF THE PLATFORM } */}

      <Section
        stage="5."
        title="USE OF THE PLATFORM"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="5.1."
          description="Subject to these Terms, Passion grants you a
          non-transferable, non-exclusive, revocable license
          (without the right to sublicense) to access and use the
          Platform solely for the purposes of uploading Creator’s
          Content to it, managing Creator’s Content and withdrawing
          your income that may be derived from Creator’s Content upon
          publication on the Passion Properties as per License Agreement."
        />

        <br />

        <GeneralPoint
          stage="5.2."
          description="You agree, represent and warrant, that your
          use of the Platform, or any portion thereof, will be
          consistent with the foregoing license, covenants and
          restrictions and will neither infringe nor violate the
          rights of any other party or breach any contract or legal
          duty to any other parties. In addition, you agree that
          you will comply with all applicable laws, regulations
          and ordinances relating to the Platform or your use of
          it, and you will be solely responsible for your own
          individual violations of any such laws."
        />

        <br />

        <GeneralPoint
          stage="5.3."
          description="You acknowledge that all the text, images,
          marks, logos, compilations (meaning the collection,
          arrangement and assembly of information), data, other
          content, software and materials displayed on the Platform
          or used by Passion to operate the Platform (including the
          Content and excluding any Creator’s Content) is
          proprietary to us or to the third parties."
        />

        <br />

        <GeneralPoint
          stage="5.4."
          description="Passion expressly reserves all rights, including
          all intellectual property rights, in all of the foregoing,
          and except as expressly permitted by these Terms, any use,
          redistribution, sale, decompilation, reverse engineering,
          disassembly, translation or other exploitation of such
          intellectual property rights is strictly prohibited.
          The provision of the access to the Platform does not
          transfer to you or any third party any rights, title or
          interest in or to such intellectual property rights
          to the Platform."
        />

        <br />

        <GeneralPoint
          stage="5.5."
          description="If you use the Platform, you represent and
          warrant to Passion that: (i) all required information you
          submit is truthful and accurate; (ii) your use of the Platform
          does not violate any applicable law or regulation or these Terms."
        />

        <br />

        <GeneralPoint
          stage="5.6."
          description="Passion reserves the right to suspend or terminate
          your use of or access to the Platform at its sole discretion,
          with or without notice to you, in the event that Passion has
          grounds to believe that you are in breach of these Terms or
          the applicable legislation."
        />

        <br />

        <GeneralPoint
          stage="5.7."
          description="Passion retains the right to implement any changes
          to the Platform at any time, with or without notice. You
          acknowledge that a variety of Passion's actions may impair
          or prevent you from accessing the Platform at certain times
          and/or in the same way, for limited periods or permanently,
          and agree that Passion has no responsibility or liability
          as a result of any such actions or results, including, without
          limitation, for the deletion of, or failure to make available
          to you, any content."
        />

        <br />

        <GeneralPoint
          stage="5.8."
          description="Your access to and use of the Platform is at your
          own risk. To the extent permitted by law, Passion will have
          no responsibility for any harm to your computing system,
          loss of data, or other harm to you or any third party, including,
          without limitation, any bodily harm, that results from your
          access to or use of the Platform, or reliance on any information
          or advice."
        />

        <br />

        <GeneralPoint
          stage="5.9."
          description="Passion has no obligation to provide you with
          customer support of any kind. However, Passion may provide
          you with customer support from time to time, at Passion's
          sole discretion."
        />
      </Flex>

      {/* { REPRESENTATION AND WARRANTIES. DISCLAIMER OF WARRANTIES } */}

      <Section
        stage="6."
        title="REPRESENTATION AND WARRANTIES. DISCLAIMER OF WARRANTIES"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="6.1."
          description="Creator represents and warrants that:"
        />

        <Subpoint
          stage="6.1.1."
          description="it has the legal capacity to enter into and agrees
          to comply with these Terms;"
        />

        <Subpoint
          stage="6.1.2."
          description="it is not under the age of 18;"
        />

        <Subpoint
          stage="6.1.3."
          description="it will not access the Platform through
          automated or non-human means, whether through a bot,
          script or otherwise;"
        />

        <Subpoint
          stage="6.1.4."
          description="it will not use the Platform for any illegal
          or unauthorized purpose;"
        />

        <Subpoint
          stage="6.1.5."
          description="it is not under any sanction, prohibition,
          blacklist or other similar measure imposed by USA, any
          state (excluding russia and the Republic of Belarus), EU,
          UN, and supranational or international organization;"
        />

        <Subpoint
          stage="6.1.6."
          description="its use of the Service will not violate any
          applicable law or regulation; and"
        />

        <Subpoint
          stage="6.1.7."
          description="Creator’s Content and all elements thereof are (a)
          owned or controlled solely and exclusively by Creator, and
          he/she has prior written permission from the rightful owner
          of the content (if any) included in Creator’s Content;
          and (b) Passion’s use of Creator’s Content as described or
          contemplated herein does not and will not infringe on the
          copyrights, trademark rights, publicity rights or other
          rights of any person or entity, violate any law, regulation
          or right of any kind whatsoever, or otherwise give rise to
          any actionable claim or liability, including without limitation
          rights of publicity and privacy, and defamation."
        />

        <br />

        <GeneralPoint
          stage="6.2."
          description='THE PLATFORM AND THE CONTENT ARE PROVIDED
          "AS IS" AND "AS AVAILABLE". THE PLATFORM AND THE CONTENT
          ARE PROVIDED WITHOUT REPRESENTATION OR WARRANTY OF ANY
          KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
          THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, INTEGRATION,
          MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND
          ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE
          OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. PASSION AND
          ITS AFFILIATES, LICENSORS AND SUPPLIERS DO NOT WARRANT THAT:
          (I) THE PLATFORM, CONTENT OR OTHER INFORMATION WILL BE TIMELY,
          ACCURATE, RELIABLE OR CORRECT; (II) THE PLATFORM WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR PLACE; (III) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (IV) THE PLATFORM WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (V) ANY RESULT OR OUTCOME CAN BE ACHIEVED.'
        />
      </Flex>

      {/* { LIMITATION OF LIABILITY } */}

      <Section
        stage="7."
        title="LIMITATION OF LIABILITY"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="7.1."
          description="IN NO EVENT SHALL PASSION (AND ITS AFFILIATES) BE
          LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR
          PUNITIVE DAMAGES ARISING FROM THESE TERMS OR YOUR USE OF,
          OR INABILITY TO USE, THE PLATFORM (INCLUDING THE CONTENT),
          OR THIRD-PARTY ADS, EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE
          PLATFORM, CONTENT AND THIRD-PARTY ADS ARE AT YOUR OWN
          DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE
          FOR ANY DAMAGE TO YOUR COMPUTING SYSTEM OR LOSS OF DATA
          RESULTING THEREFROM."
        />

        <br />

        <GeneralPoint
          stage="7.2."
          description="SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION
          OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL
          DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY
          TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY
          FROM JURISDICTION TO JURISDICTION."
        />
      </Flex>

      {/* { INDEMNITY } */}

      <Section
        stage="8."
        title="INDEMNITY"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
      >
        You agree to indemnify and hold Passion, its successors,
        subsidiaries, Affiliates, any related companies, its suppliers,
        licensors and partners, and the officers, directors, employees,
        agents and representatives of each of them harmless, including
        costs and attorneys' fees, from any claim or demand made by
        any third party due to or arising out of (i) your use of the
        Platform, (ii) Creator’s Content, or (iii) your violation of
        these Terms. Passion reserves the right, at your expense,
        to assume the exclusive defense and control of any matter for
        which you are required to indemnify Passion and you agree to
        cooperate with Passion’s defense of these claims. You agree
        not to settle any matter without the prior written consent of
        Passion. Passion will use reasonable efforts to notify you of
        any such claim, action or proceeding upon becoming aware of it.
      </Text>

      {/* { GOVERNING LAW } */}

      <Section
        stage="9."
        title="GOVERNING LAW"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="9.1."
          description="The laws of England and Wales, excluding its
          conflicts of law principles, govern these Terms."
        />

        <br />

        <GeneralPoint
          stage="9.2."
          description="Any controversy or claim arising out of or relating
          to these Terms, or the breach thereof, shall be determined by
          arbitration administered by the International Centre for Dispute
          Resolution in accordance with its International Arbitration Rules.
          The International Expedited Procedures of the International Centre
          for Dispute Resolution shall apply regardless of the amount in
          dispute. The place of arbitration shall be Nicosia, Cyprus,
          however the Parties agree that in case an oral hearing is
          necessary, it shall be conducted via video, audio, or other
          electronic means. The language of the arbitration shall be
          English. Except as may be required by law, neither a Party nor
          its representatives may disclose the existence, content, or
          results of any arbitration hereunder without the prior written
          consent of both Parties."
        />
      </Flex>

      {/* { MISCELLANEOUS PROVISIONS } */}

      <Section
        stage="10."
        title="MISCELLANEOUS PROVISIONS"
      />

      <Flex mb="32px" direction="column">
        <GeneralPoint
          stage="10.1."
          description="No delay or omission by us in exercising any of our
          rights occurring upon any noncompliance or default by you with
          respect to these Terms will impair any such right or be construed
          to be a waiver thereof, and a waiver by Passion of any of the
          covenants, conditions or agreements to be performed by you will
          not be construed to be a waiver of any succeeding breach thereof
          or of any other covenant, condition or agreement hereof contained."
        />

        <br />

        <GeneralPoint
          stage="10.2."
          description="If any provision of these Terms is found to be invalid
          or unenforceable, then these Terms will remain in full force and
          effect and that provision shall be reformulated to be valid and
          enforceable while reflecting the intent of the parties to the
          greatest extent permitted by law."
        />

        <br />

        <GeneralPoint
          stage="10.3."
          description="All information communicated on the Platform is
          considered an electronic communication. When you communicate
          with us through or on the Platform or via other forms of
          electronic media, such as e-mail, you are communicating with
          us electronically. You agree that we may communicate
          electronically with you and that such communications,
          as well as notices, disclosures, agreements, and other
          communications that we provide to you electronically, are
          equivalent to communications in writing and shall have the
          same force and effect as if they were in writing and signed
          by the party sending the communication."
        />

        <br />

        <GeneralPoint
          stage="10.4."
          description="In no event shall Passion be liable for any
          failure to comply with these Terms to the extent that such
          failure arises from factors outside the Passion's
          reasonable control."
        />
      </Flex>

      {/* { CONTACT } */}

      <Section
        stage="11."
        title="CONTACT"
      />

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mb="32px"
      >
        If you want to send any notice under these Terms or
        have any questions regarding the Platform, you may
        contact us at:{' '}
        <Text
          as="span"
          variant="primary-violet-100"
          size="poppins16normal"
          letterSpacing="-0.016px"
          lineHeight="28px"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleCreateEmail}
        >
          support@passionapp.co
        </Text>
        .
      </Text>
    </Flex>
  );
};
