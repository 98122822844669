import { useLazyQuery } from '@apollo/client';
import { GET_USER_INFO } from 'entites/user/get-user-info.graphql';
import React, { useEffect, useState } from 'react';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { handleSetData } from 'utils/helpers/log-in-log-out/handleSetData';
import { UserInfoWithSocial } from 'utils/types/user';

type Props = {
  children: React.ReactNode;
};

type Context = {
  handleGetUserInfo: () => void;
  user: UserInfoWithSocial | null;
  isLoadingUserData: boolean;
  showAutosaveMessage: boolean;
  handleShowAutosave: () => void;
};

export const UserContext = React.createContext<Context>({
  handleGetUserInfo: () => {},
  user: null,
  isLoadingUserData: false,

  showAutosaveMessage: true,
  handleShowAutosave: () => {},
});

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<UserInfoWithSocial | null>(null);
  const access_token = getFromLocalStorage('access_token');
  const [showAutosaveMessage, setShowAutosaveMessage] = useState(true);

  const handleShowAutosave = () => {
    setShowAutosaveMessage(false);
  };

	const [getUserInfo, {loading: isLoadingUserData}] = useLazyQuery(GET_USER_INFO, {
		fetchPolicy: 'network-only',
	});

  const handleGetUserInfo = async () => {
    const { data: userData } = await getUserInfo();

    if (userData) {
      const {
        user_id,
        first_name,
        last_name,
        photo_link,
        pen_name,
        email,
        status,
        description,
        role,
        gender,
        facebook,
        quora,
        instagram,
        reddit,
      } =
				userData.getUserInfo;

      handleSetData({ user_id, email, role });

      setUser({
        first_name,
        last_name,
				photo_link,
				pen_name,
        email,
        gender,
        emailStatus: status,
        description,
        facebook,
        quora,
        instagram,
        reddit,
			});
    }
  };

  useEffect(() => {
    if (access_token) {
      if (!user) {
        handleGetUserInfo();
      }
    }
  }, [access_token]);

  const contextValue = {
    user,
    isLoadingUserData,
    handleGetUserInfo,
    showAutosaveMessage,
    handleShowAutosave,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};