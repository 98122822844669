import { Flex, Heading, Image, Text } from '@chakra-ui/react';
import { contractScrollBar } from 'constants/scrollbar';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Terms } from './Terms';
import { useLocation } from 'react-router-dom';
import { PrivacyPolicy } from './PrivacyPolicy';

type Props = {
  title: string;
  lastUpdate: string;
}

const PlatformPolicy: FC<Props> = ({
  title,
  lastUpdate,
}) => {
  const location = useLocation();

  const { t } = useTranslation();
  const pdfViewerRef = useRef<HTMLDivElement>(null);
  const isTerms = location.pathname.includes('terms');

  useEffect(() => {
		const previewWrapper = document.querySelector('#policy-preview-wrapper');

		if (previewWrapper) {
      setTimeout(() => previewWrapper.scrollTo(-10000, -10000), 1000);
		}
	}, [pdfViewerRef.current]);

  return (
    <Flex
      bg="linear-gradient(135deg, #F3F1FF 0%, #F0FBFF 100%)"
      position="relative"
      direction="column"
      sx={contractScrollBar}
      overflow="auto"
      h="100vh"
      w="100%"
      id="policy-preview-wrapper"
    >
      <Flex
        p="24px 52px"
        gap="30px"
      >
        <Image
          src="/images/guidline/notification.png"
          alt="notification"
        />

        <Flex
          direction="column"
        >
          <Heading
            size="poppins38"
            variant="primary-black-text"
            lineHeight="48px"
            mb="13px"
          >
            {t(title)}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            letterSpacing="-0.016px"
            lineHeight="28px"
          >
            {t(lastUpdate)}
          </Text>
        </Flex>
      </Flex>

      <Flex
        p="0 31px 48px 162px"
        h="fit-content"
      >
        {isTerms ? <Terms /> : <PrivacyPolicy />}
      </Flex>
    </Flex>
  );
};

export default PlatformPolicy;