import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { InitialSearchHistory } from 'constants/admin/searchHistory';
import { GET_ALL_GENRES } from 'entites/genres/get-all-genres';
import { GET_ALL_LANGUAGES } from 'entites/languages/get-all-languages';
import { ADD_APP_LOCALE, GET_APP_LOCALES, GET_ISO_LOCALES, REMOVE_APP_LOCALE } from 'entites/localization/localization.graphql';
import { GET_SENDED_NOTIFICATIONS } from 'entites/notifications/notifications.graphql';
import { GET_ALL_TAGS } from 'entites/tags/get-all-tags';
import { ALL_USERS } from 'entites/users/get-all-users.graphql';
import React, { useCallback, useEffect, useState } from 'react';
import { getFromLocalStorage, setInLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { Genre } from 'utils/types/books/Genre';
import { Language } from 'utils/types/books/Language';
import { Tag } from 'utils/types/books/Tag';
import { AppLocalesFull, LocaleIdentity } from 'utils/types/localizations';
import { AdminSendedNotif } from 'utils/types/notifications';
import { Query, SearchHistory } from 'utils/types/search';
import { NotificationUser } from 'utils/types/user';
import * as EmailValidator from 'email-validator';

type Props = {
  children: React.ReactNode;
};

type Context = {
  // delete request modal - once per session
  handleAddRequest: (id: string) => void;
  viewedRequests: string[];

  // book parts
  languages: Language[];
  genres: Genre[];
  tags: Tag[];

  // search history
  searchHistory: SearchHistory;
  addSearchQuery: (list: string, search: string) => void;
  removeSearchQuery: (list: string, search: string) => void;

  // application locales
  locales: string[];
  isoLocales: Record<string, string>;
  isAppLocalesLoading: boolean;
  isAddingLocaleLoading: boolean;
  isDeleteLocaleLoading: boolean;
  handleAddLocale: (locale: AppLocalesFull) => Promise<boolean>;
  handleRemoveLocale: (locale: AppLocalesFull) => Promise<boolean>;
  users: NotificationUser[];
  isAllUsersLoading: boolean;
  triggerUsersLoad: () => void;
  sendedNotifs: AdminSendedNotif[];
  isSendedLoading: boolean;
}

export const AdminContext = React.createContext<Context>({
  handleAddRequest: () => {},
  viewedRequests: [],
  languages: [],
  genres: [],
  tags: [],
  searchHistory: InitialSearchHistory,
  addSearchQuery: () => {},
  removeSearchQuery: () => {},
  locales: [],
  isoLocales: {},
  isAppLocalesLoading: false,
  isAddingLocaleLoading: false,
  isDeleteLocaleLoading: false,
  handleAddLocale: async () => false,
  handleRemoveLocale: async () => false,
  users: [],
  isAllUsersLoading: false,
  triggerUsersLoad: async () => Promise<void>,
  sendedNotifs: [],
  isSendedLoading: false,
});

export const AdminProvider: React.FC<Props> = ({ children }) => {
  const [viewedRequests, setViewedRequests] = useState<string[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [genres, setGenres] = useState<Genre[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [searchHistory, setSearchHistory] = useState<SearchHistory>(InitialSearchHistory);
  const [locales, setLocales] = useState<string[]>([]);
  const [isoLocales, setIsoLocales] = useState<Record<string, string>>({});
  const [users, setUsers] = useState<NotificationUser[]>([]);
  const [sendedNotifs, setSendedNotifs] = useState<AdminSendedNotif[]>([]);

  // #region Admin sended notifs
    const {
      data: notifs,
      loading: isSendedLoading
    } = useQuery(GET_SENDED_NOTIFICATIONS, {
      fetchPolicy: 'cache-and-network',
    });

    
    useEffect(() => {
      if (notifs && !isSendedLoading) {
        setSendedNotifs(notifs.items || []);
      }
    }, [notifs, isSendedLoading]);
  // #endregion

  // #region AllUsers
  const [loadUsers, { data: allUsers, loading: isAllUsersLoading }] = useLazyQuery(ALL_USERS);

  const triggerUsersLoad = useCallback(async () => {
    if (users.length === 0) {
      await loadUsers();
    }
  }, [users]);

  useEffect(() => {
    if (allUsers && !isAllUsersLoading) {
      setUsers(allUsers.users.filter((user: NotificationUser) => user.email && EmailValidator.validate(user.email)));
    }
  }, [allUsers, isAllUsersLoading]);

  // #endregion

  // #region SearchBarHistory

  const addSearchQuery = useCallback((list: string, search: string) => {
    setSearchHistory(curr => {
      const query = search.trim();
      const newHistory = JSON.parse(JSON.stringify(curr));
      const index = newHistory[list].query
        .findIndex((data: Query) => data.search === query);

      if (index < 0) {
        newHistory[list].query.unshift({
          search: query,
        });

        setInLocalStorage('search_history', JSON.stringify(newHistory));
        return newHistory;
      }

      const element = newHistory[list].query.splice(index, 1);

      newHistory[list].query.unshift(element[0]);

      return newHistory;
    });
  }, []);

  const removeSearchQuery = useCallback((list: string, search: string) => {
    setSearchHistory(curr => {
      const query = search.trim();
      const newHistory = JSON.parse(JSON.stringify(curr));

      newHistory[list].query = newHistory[list].query
        .filter((data: Query) => data.search !== query);

      setInLocalStorage('search_history', JSON.stringify(newHistory));

      return newHistory;
    });
  }, []);

  useEffect(() => {
    const localHistory = getFromLocalStorage('search_history');

    if (localHistory) {
      setSearchHistory({...InitialSearchHistory, ...JSON.parse(localHistory)});
    }
  }, []);

  // #endregion

  // #region Delete Modal Show

  const handleAddRequest = (id: string) => {
    setViewedRequests(curr => [...curr, id]);
  };

  // #endregion

  // #region BookData

  const { data: allLanguages, loading: isLanguagesLoading } = useQuery(
    GET_ALL_LANGUAGES,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    if (allLanguages && !isLanguagesLoading) {
      setLanguages(allLanguages.getAllLanguages);
    }
  }, [isLanguagesLoading]);

  const { data: allGenres, loading: isGenresLoading } = useQuery(
    GET_ALL_GENRES,
    {
      fetchPolicy: 'cache-first',
    },
  );

  useEffect(() => {
    if (allGenres && !isGenresLoading) {
      setGenres(allGenres.getAllGenres);
    }
  }, [isGenresLoading]);

  const { data: allTags, loading: isTagsLoading } = useQuery(
    GET_ALL_TAGS,
    {
      fetchPolicy: 'cache-first',
    }
  );

  useEffect(() => {
    if (allTags && !isTagsLoading) {
      setTags(allTags.getAllTags);
    }
  }, [isTagsLoading]);

  // #endregion

  // #region locales data

  const { data: allIsoLocales, loading: isAllIsoLocalesLoading } = useQuery(
    GET_ISO_LOCALES,
    {
      fetchPolicy: 'cache-first',
    }
  );

  const { data: appLocales, loading: isAppLocalesLoading } = useQuery(
    GET_APP_LOCALES,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [
    addLocale,
    { loading: isAddingLocaleLoading }
  ] = useMutation(
    ADD_APP_LOCALE,
    {
      refetchQueries: [GET_APP_LOCALES]
    }
  );

  const [
    deleteLocale,
    { loading: isDeleteLocaleLoading }
  ] = useMutation(
    REMOVE_APP_LOCALE,
    {
      refetchQueries: [GET_APP_LOCALES]
    }
  );

  const handleAddLocale = useCallback(async (
    locale: AppLocalesFull
  ): Promise<boolean> => {
    const {
      data: {
        item
      } } = await addLocale({
        variables: {
          locale,
        }
      });

    return item.code === locale.code;
  }, []);

  const handleRemoveLocale = useCallback(async (
    locale: AppLocalesFull
  ): Promise<boolean> => {
    const {
      data: {
        result
      } } = await deleteLocale({
        variables: {
          locale: {
            code: locale.code,
          },
        }
      });

    return result;
  }, []);

  useEffect(() => {
    if (appLocales && !isAppLocalesLoading) {
      setLocales(appLocales.data.map((item: LocaleIdentity) => item.code));
    }
  }, [isAppLocalesLoading]);

  useEffect(() => {
    if (allIsoLocales && !isAllIsoLocalesLoading) {
      setIsoLocales(allIsoLocales.data.locales);
    }
  }, [isAllIsoLocalesLoading]);

  // #endregion

  const contextValue = {
    handleAddRequest,
    viewedRequests,
    languages,
    genres,
    tags,
    searchHistory,
    addSearchQuery,
    removeSearchQuery,
    locales,
    isoLocales,
    isAppLocalesLoading,
    isAddingLocaleLoading,
    isDeleteLocaleLoading,
    handleAddLocale,
    handleRemoveLocale,
    users,
    isAllUsersLoading,
    triggerUsersLoad,
    sendedNotifs,
    isSendedLoading,
  };

  return (
    <AdminContext.Provider value={contextValue}>
      {children}
    </AdminContext.Provider>
  );
};