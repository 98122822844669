import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { CSSProperties, FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { BaseFullProps, BaseShortProps, NotifToastProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { CreateStoryButton } from 'components/CreateStoryButton';
import { EMAILS } from 'constants/email/Email';
import { EmailTemplates } from 'constants/email/EmailTemplates';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';

export const WelcomeLetterToast: FC<NotifToastProps> = ({
  id,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Body
        text={t('Greetings, Esteemed Author! Congratulations on successfully signing up with Passion Creators! Your enthusiasm for our platform is genuinely valued! ...')}
        title={t('Welcoming letter')}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const WelcomeLetterShort: FC<BaseShortProps> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={bg}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Welcoming letter')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('Greetings, Esteemed Author! Congratulations on successfully signing up with Passion Creators! Your enthusiasm for our platform is genuinely valued!...')}
        </Text>
      </Flex>
    </Flex>
  );
};

export const WelcomeLetterFull: FC<BaseFullProps> = ({
  createdAt,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = notificationDateFormatter(createdAt);

  const handleCreateEmail = () => {
    const link = document.createElement('a');
    link.href = EmailTemplates.SIMPLE;
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRedirectToGuidlines = () => {
    navigate('/writer/create-story/content-guidlines');
  };

  const handleGoToHelp = () => {
    navigate('/writer/help');
  };

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['primary-violet-30']}
              img="/images/hero/heart.svg"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/story/cancel.png"
        loading="eager"
        alt="welcome"
        mb="20px"
      />

      <Box maxW="600px" mb="20px">
        <Text
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={400}
          textAlign="center"
        >
          {t('Greetings, Esteemed Author!')}
        </Text>

        <Text
          variant="primary-violet-100"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          textAlign="center"
        >
          {t('Congratulations on successfully signing up with Passion Creators! ')}
        </Text>
      </Box>

      <Box maxW="800px">
        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          mb="32px"
          textAlign="center"
        >
          {t(' We are very happy to welcome you in our platform! Our goal is provide our authors with the finest promotion and earning opportunities.  Now that you\'re part of our community, you have the chance to share your stories with readers all over the world!')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          mb="32px"
          textAlign="center"
        >
          {t('To find out more information about bonuses, revenue shares, and the terms of both exclusive and non-exclusive contracts, please explore our ')}
          <Text
            as="span"
            variant="primary-violet-100"
            size="poppins20normal"
            lineHeight="32px"
            textDecoration="underline"
            fontWeight={500}
            cursor="pointer"
            onClick={handleGoToHelp}
          >
            {t('Help')}
          </Text>
          {t(' section.')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
        >
          {t('We can\'t wait to see what amazing stories you\'ll create! Feel free to start writing or upload your work right here:')}
        </Text>
      </Box>

      <CreateStoryButton
        onClick={handleRedirectToGuidlines}
      />

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        textAlign="center"
        mb="20px"
      >
        {t('If you have any questions, please feel free to reach out to us at')}
        <br />
        <Text
          as="span"
          variant="primary-violet-100"
          size="poppins20normal"
          lineHeight="32px"
          textDecoration="underline"
          cursor="pointer"
          onClick={handleCreateEmail}
        >
          {EMAILS.support}
        </Text>
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};
