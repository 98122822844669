import { Box, Flex } from '@chakra-ui/react';
import CloseCirlcleIcon from 'components/Icons/CloseCirlcleIcon';
import { FC, useState } from 'react';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  children: React.ReactNode | JSX.Element | JSX.Element[];
  handleClose: () => void;
}

export const ToastWrapper: FC<Props> = ({ children, handleClose }) => {
  const [hover, setHover] = useState(false);

  return (
    <Flex
      w="444px"
      minH="95px"
      p="12px 34px 12px 12px"
      border={`1px solid ${colors['primary-blue-100']}`}
      bg="linear-gradient(253deg, rgba(128, 195, 255, 0.25) -7.53%, rgba(204, 231, 255, 0.25) 97.04%)"
      backdropFilter="blur(10px)"
      borderRadius="8px"
      pos="relative"
      align="center"
    >
      {children}

      <Box
        pos="absolute"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClose}
        top="12px"
        right="11px"
        cursor="pointer"
      >
        {hover ? CloseCirlcleIcon(colors['button-hover']) : CloseCirlcleIcon()}
      </Box>
    </Flex>
  );
};
