import App from './App';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import WithApollo from 'components/hoc/WithApollo';
import { WithLocalization } from './components/hoc/LocalizationProvider';
import { ChakraProvider } from '@chakra-ui/react';
import chakraTheme from 'shared/utils/chakra-theme';
import './styles/styles.scss';
import './utils/localization';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

root.render(
	<BrowserRouter>
		<WithLocalization>
			<ChakraProvider theme={chakraTheme} toastOptions={{ defaultOptions: { containerStyle: { display: 'flex', justifyContent: 'center' } } }}>
				<WithApollo>
						<App />
				</WithApollo>
			</ChakraProvider>
		</WithLocalization>
	</BrowserRouter>
);
