import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import {
  Flex,
  Input,
  Text,
} from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';

type Props = {
	htmlForId: string;
	name: string;
	[x: string]: any;
};

export const EmailField: FC<Props> = ({
  htmlForId,
  ...props
}) => {
	const [field, meta, helpers] = useField(props);
	const [, passwordMeta] = useField('password');
	const { t } = useTranslation();
	const [focus, setFocus] = useState(false);
	const isInvalid = useMemo(() => {
		return meta.touched && meta.error || passwordMeta.error;
	}, [meta]);

	const handleFocus = useCallback(() => {
		helpers.setError(undefined);
		setFocus(curr => !curr);
	}, [helpers]);

	return (
		<Flex
      pos='relative'
      direction='column'
    >
      <Input
				type="text"
				placeholder='Email'
				variant={isInvalid && !focus ? 'loginFieldError' : 'loginField'}
				id={htmlForId}
				onFocus={handleFocus}
				{...field}
				{...props}
				onBlur={(e) => {
					handleFocus();
					field.onBlur(e);
				}}
			/>

			{isInvalid && !focus && (
				<Text
					size="poppins14"
					variant="light-text-text-danger"
					lineHeight='20px'
					pos="absolute"
					bottom="-4px"
					transform="translateY(100%)"
					data-testid="logIn__email-error"
				>
					{t(meta.error ? meta.error[0] : '')}
				</Text>
			)}
		</Flex>
	);
};
