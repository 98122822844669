import { colors } from '../colors';

export const webKitStyle = {
	webkitBoxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
	boxShadow: `0 0 0 30px ${colors['primary-violet-10']} inset !important`,
};

export const autofillStyle = {
	webkitBoxShadow: '0 0 0 30px #F1E9FC inset !important',
	boxShadow: '0 0 0 30px white inset !important',
};

export const inputStyles = {
	baseStyle: {},
	variants: {
		default: {
			field: {
				p: '0 0 4px',
				h: 'fit-content',
				width: '100%',
				fontSize: '14px',
				lineHeight: '110%',
				borderRadius: 'none',
				color: '#0b080f',
				borderBottom: '1px solid #dbdbdb',
				_hover: {
					outline: 'none',
					borderBottom: '1px solid var(--color--blue)',
				},
				_autofill: webKitStyle,
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
			},
		},
		editor: {
			field: {
				p: '20px',
				h: '100%',
				width: '100%',
				fontWeight: 800,
				fontSize: '22px',
				lineHeight: '29px',
				textAlign: 'center',
				border: 'none',
				borderRadius: 'none',
				color: colors['main-dark'],
				borderBottom: '1px solid rgba(75, 75, 75, 0.1)',
				_hover: {
					outline: 'none',
				},
				_webkitAutofill: {
					autofillStyle,
					_hover: autofillStyle,
					_focus: autofillStyle,
					_active: autofillStyle,
				},
				_focus: {
					outline: 'none',
				},
			},
		},
		admin: {
			field: {
				h: 'auto',
				w: 'auto',
				fontFamily: `'Nunito Sans', sans-serif`,
				p: '12px 36px',
				bg: '#eeeef2',
				boxSizing: 'border-box',
				fontWeight: 400,
				fontSize: '15px',
				lineHeight: '16px',
				border: '1px solid #eeeef2',
				borderRadius: '20px',
				color: colors.gray,
				caretColor: colors.pink100,
				_hover: {
					outline: 'none',
				},
				_focus: {
					outline: 'none',
					borderColor: colors.pink100,
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
};

export const loginField = {
	field: {
		padding: '8px 18px',
		width: '100%',
		height: '54px',
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '-0.018px',
		paddingVertical: 0,
		borderRadius: '8px',
		backgroundColor: colors['primary-violet-10'],
		color: colors['primary-black-text'],
		border: `1px solid transparent`,
		fontFamily: `'Poppins', sans-serif`,
		_hover: {
			outline: 'none',
		},
		_placeholder: {
			color: colors['primary-violet-60'],
			fontFamily: `'Poppins', sans-serif`,
			fontSize: '18px',
			letterSpacing: '-0.018px',
		},
		_autofill: webKitStyle,
		_focus: {
			outline: 'none',
			borderColor: colors['primary-violet-100'],
		},
	},
};

export const loginFieldError = {
	field: {
		...loginField.field,
		border: `1px solid ${colors['light-text-text-danger']}`,
	}
};

export const titleField = {
	field: {
		...loginField.field,
		height: '72px',
		_focus: {
			...loginField.field._focus,
			caretColor: colors['primary-violet-100'],
		},
		_placeholder: {
			...loginField.field._placeholder,
			lineHeight: '32px',
			color: colors['primary-gray-70'],
		},
	}
};

export const titleFieldError = {
	field: {
		...titleField.field,
		border: `1px solid ${colors['light-text-text-danger']}`,
	}
};

export const contractField = {
	field: {
		...loginField.field,
		height: '72px',
		_focus: {
			...loginField.field._focus,
			caretColor: colors['primary-black-text'],
		},
		_placeholder: {
			...loginField.field._placeholder,
			lineHeight: '32px',
			color: colors['primary-gray-100'],
		},
	}
};

export const contractFieldError = {
	field: {
		...titleField.field,
		border: `1px solid ${colors['light-text-text-danger']}`,
	}
};

export const tagField = {
	field: {
		padding: 0,
		height: '48px',
		fontSize: '18px',
		lineHeight: '48px',
		letterSpacing: '-0.018px',
		borderRadius: '8px',
		backgroundColor: colors['primary-violet-10'],
		color: colors['primary-black-text'],
		fontFamily: `'Poppins', sans-serif`,
		_hover: {
			outline: 'none',
		},
		_placeholder: {
			color: colors['primary-gray-70'],
			fontFamily: `'Poppins', sans-serif`,
			fontSize: '18px',
			letterSpacing: '-0.018px',
		},
		_autofill: webKitStyle,
		_focus: {
			outline: 'none',
		},
	},
};

export const storyTitleField = {
	field: {
		padding: 0,
		height: '32px',
		fontSize: '24px',
		lineHeight: '32px',
		letterSpacing: '-0.024px',
		fontWeight: 600,
		backgroundColor: 'transparent',
		color: colors['primary-black-text'],
		fontFamily: `'Poppins', sans-serif`,
		_hover: {
			outline: 'none',
		},
		_placeholder: {
			color: colors['primary-gray-90'],
			fontFamily: `'Poppins', sans-serif`,
			fontSize: '24px',
			letterSpacing: '-0.018px',
			fontWeight: 600,
		},
		_autofill: webKitStyle,
		_focus: {
			outline: 'none',
		},
		_disabled: {
			color: colors['primary-black-text'],
			cursor: 'initial',
			opacity: 1,
		}
	},
};

export const paywallField = {
	field: {
		padding: '8px 18px',
		width: '100%',
		height: '54px',
		fontSize: '18px',
		lineHeight: '32px',
		letterSpacing: '-0.018px',
		paddingVertical: 0,
		borderRadius: '8px',
		backgroundColor: colors['primary-violet-10'],
		color: colors['primary-black-text'],
		border: `1px solid transparent`,
		fontFamily: `'Poppins', sans-serif`,
		_hover: {
			outline: 'none',
		},
		_placeholder: {
			color: colors['primary-violet-60'],
			fontFamily: `'Poppins', sans-serif`,
			fontSize: '18px',
			letterSpacing: '-0.018px',
		},
		_autofill: webKitStyle,
		_focus: {
			outline: 'none',
			borderColor: colors['primary-violet-100'],
		},
	},
};
