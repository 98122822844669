import { Heading } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  title: string;
  id: string;
}

export const PolicySection: FC<Props> = ({
  stage,
  title,
  id,
}) => {
  return (
    <Heading
      size="poppins20"
      variant="primary-black-text"
      textAlign="left"
      mb="16px"
      id={id}
    >
      {stage}{' '}{title}
    </Heading>
  );
};


