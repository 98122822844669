import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { NotificationsLayout } from './NotificationsLayout';
import { Flex } from '@chakra-ui/react';
import { NotificationsProvider } from 'context/NotificationsContext';
import { StoriesProvider } from 'context/StoriesContext';
import { UserProvider } from 'context/UserContext';

const WriterLayout: FC = () => {
  return (
    <UserProvider>
      <StoriesProvider>
        <NotificationsProvider>
          <Flex
            w="100vw"
            h="100vh"
            pos="relative"
            overflow="hidden"
          >
            <Outlet />

            <NotificationsLayout />
          </Flex>
        </NotificationsProvider>
      </StoriesProvider>
    </UserProvider>
  );
};

export default WriterLayout;