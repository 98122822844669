import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { Form, Formik, FormikErrors } from 'formik';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { validateYupSchemaMultiErrors } from 'utils/helpers/yup/yupUtils';
import { TextInput } from './TextInut';
import { createAccountValidationSchema } from 'pages/Authorization/models';
import { SignUpCredentials } from 'utils/types/user';
import { ampEvent } from 'utils/helpers/amplitude/amplitude';
import { EVENT_TYPES } from 'utils/enums/event-type.enum';

type Props = {
  handleCreateAccount: (values: SignUpCredentials) => void;
};

export const PersonalInfoForm: FC<Props> = ({
  handleCreateAccount,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  const handleErrorsCheck = useCallback((
    errors: FormikErrors<SignUpCredentials>,
    values: SignUpCredentials,
  ) => {
    if (Object.entries(errors).length > 0 || Object.entries(values).some(item => !item[1])) {
      setError(true);

      return;
    }

    setError(false);
  }, []);

  const handleRemoveError = useCallback(() => {
    setError(false);
  }, []);

  useEffect(() => {
    ampEvent(EVENT_TYPES.SIGNUP_NAME_SHOWN);
  }, []);

  return (
    <Formik
      validateOnChange={false}
      initialValues={{
        first_name: '',
        last_name: '',
        pen_name: '',
      }}
      validateOnBlur={true}
      validate={values =>
        validateYupSchemaMultiErrors(values, createAccountValidationSchema)
      }
      onSubmit={(values) => {
        handleCreateAccount({
          ...values,
        });
      }}
    >
      {({ errors, values }) => (
        <Form style={{ width: '100%' }}>
          <Text
            mb="12px"
            size="poppins16normal"
            variant="primary-black-text"
            textAlign="center"
          >
            {t('Tell us your real name')}
          </Text>

          <Stack
            spacing="12px"
            width="100%"
            mb="12px"
          >
            <TextInput
              htmlForId="first_name"
              name="first_name"
              placeholder="First name"
              handleRemoveError={handleRemoveError}
            />

            <TextInput
              htmlForId="last_name"
              name="last_name"
              placeholder="Last name"
              handleRemoveError={handleRemoveError}
            />
          </Stack>

          <Flex
            w="100%"
            gap="2px"
            mb="12px"
            align="center"
            justify="center"
          >
            <Text
              size="poppins16normal"
              variant="primary-black-text"
              textAlign="center"
            >
              {t('And your pen name')}
            </Text>

            <Tooltip
              label={
                <>
                  {t('Books will be published on the platform using your pen name.')}
                  <br />
                  {t('Please, keep in mind that you should only use a unique name')}
                </>
              }
              variant="createStory"
              placement="right-start"
            >
              <Box
                w="24px"
                h="24px"
              >
                <Image
                  src="/images/story/info-circle.svg"
                  alt="info-circle"
                />
              </Box>
            </Tooltip>
          </Flex>

          <Box mb="34px">
            <TextInput
              htmlForId="pen_name"
              name="pen_name"
              placeholder="Pen name"
              handleRemoveError={handleRemoveError}
            />
          </Box>

          {/* <Text
            mb="24px"
            size="poppins16normal"
            variant="primary-black-text"
            textAlign="center"
          >
            {t('And your gender, please')}
          </Text>

          <GenderSelector
            name="gender"
          /> */}

          <Box
            mb="38px"
            pos="relative"
            onClick={() => handleErrorsCheck(errors, values)}
          >
            <Button
              type="submit"
              variant="defaultButton"
              w="100%"
            >
              {t('Sign Up')}
            </Button>

            {error && <Text
              size="poppins14"
              variant="light-text-text-danger"
              lineHeight='20px'
              pos="absolute"
              bottom="-12px"
              left="50%"
              transform="translate(-50%, 100%)"
              w="100%"
              textAlign="center"
            >
              {t('Please fill in all fields first.')}
            </Text>}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
