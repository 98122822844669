import { Box, Flex, Heading, Image, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { AdminNotifToastProps, ShortPropsWithName, UpdatesFullProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { Body } from '../../UI/Toast/Body';
import { dashboardUpdates } from 'constants/notifications/dashboard-updates';

export const DashboardsUpdatesToast: FC<AdminNotifToastProps> = ({
  id,
  handleClose,
  createdBy
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Body
        text={<>from <span style={{ fontWeight: 600 }}>{createdBy}</span></>}
        title={t('Our Creator Dashboard Just Got a Whole Lot Better!')}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const DashboardsUpdatesShort: FC<ShortPropsWithName> = ({
  id,
  isSelected,
  user_readed,
  createdAt,
  toggleSelect,
  handleOpenNotification,
  createdBy,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={bg}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          color={colors['primary-violet-30']}
          img="/images/hero/heart.svg"
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Our Creator Dashboard Just Got a Whole Lot Better!')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          <>from <span style={{ fontWeight: 600 }}>{createdBy}</span></>
        </Text>
      </Flex>
    </Flex>
  );
};

export const DashboardsUpdatesFull: FC<UpdatesFullProps> = ({
  createdAt,
  createdBy,
  createdByEmail,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              color={colors['primary-violet-30']}
              img="/images/hero/heart.svg"
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {createdBy}
            </Text>
            {` <${createdByEmail}>`}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="350px"
        h="168px"
        src="/images/notifications/custom/changes.png"
        loading="eager"
        alt="changes"
        mb="-11px"
      />

      <Box maxW="600px">
        <Text
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={400}
          textAlign="center"
        >
          {t(`Hi, ${user?.first_name}!`)}
        </Text>
      </Box>

      <Box maxW="600px">
        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
          mb="44px"
        >
          {t('We\'re excited to announce a major update to our Creator Dashboard! We\'ve packed it with new features to help you track your progress, manage your books, and streamline your workflow.')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          fontWeight={600}
        >
          {t('What\'s new:')}
        </Text>

        <UnorderedList mb="44px">
          {dashboardUpdates.map((item) => {
            return (
              <ListItem key={item.id}>
                <Text
                  as="span"
                  variant="primary-black-text"
                  size="poppins20normal"
                  lineHeight="32px"
                  fontWeight={500}
                >
                  {item.point}
                </Text>
                {' '}
                <Text
                  as="span"
                  variant="primary-black-text"
                  size="poppins20normal"
                  lineHeight="32px"
                >
                  {item.description}
                </Text>
              </ListItem>
            );
          })}
          
        </UnorderedList>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          textAlign="center"
          mb="8px"
        >
          {t('We hope these new features make your experience as a creator even better.')}
        </Text>
      </Box>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        textAlign="center"
        fontWeight={600}
        maxW="600px"
      >
        {t('Happy creating!')}
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};

