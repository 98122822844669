import { Box, Button, Heading, Image, Text } from '@chakra-ui/react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const FinalStep: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogIn = useCallback(() => {
    navigate('/auth/log-in');
  }, []);

  return (
    <>
      <Heading
        size="poppins25"
        variant="primary-black-text"
        mb="12px"
      >
        {t('Password changed')}
      </Heading>

      <Text
        maxW="360px"
        size="poppins16normal"
        variant="primary-black-text"
        lineHeight="28px"
        letterSpacing="-0.016px"
        textAlign="center"
        mb="44px"
      >
        {t('Your password has been successfully changed')}
      </Text>

      
      <Box
        mb="76px"
        width="100%"
      >
        <Button
          type="button"
          variant="defaultButton"
          w="100%"
          onClick={handleLogIn}
        >
          {t('Log In')}
        </Button>
      </Box>

      <Image
        src="/images/authorization/reset-success.png"
        alt="arrow-left"
        w="120px"
        h="120px"
      />
    </>
  );
};
