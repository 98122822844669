import { gql } from '@apollo/client';

export const GET_WITHDRAW_REQUESTS = gql`
	query WithdrawRequests($limit: Int!, $page: Int!) {
		requests: balanceWithdrawActiveRequests(limit: $limit, page: $page) {
			balance_withdraw_id
			user {
				user_id
				first_name
				last_name
			}
			status
			amount
			created_at
			updated_at
			performed_by {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const GET_WITHDRAW_REQUESTS_HISTORY = gql`
	query WithdrawHistory($limit: Int!, $page: Int!) {
		requests: balanceWithdrawRequestsHistory(limit: $limit, page: $page) {
			balance_withdraw_id
			user {
				user_id
				first_name
				last_name
			}
			status
			amount
			created_at
			updated_at
			performed_by {
				user_id
				first_name
				last_name
			}
		}
	}
`;

export const FULFILL_WITHDRAW_REQUEST = gql`
	mutation FulfillWithdrawRequest($request: FulfillUsersBalanceWithdrawDto!) {
		request: fulfillBalanceWithdrawRequest(request: $request) {
			balance_withdraw_id
			user {
				user_id
				first_name
				last_name
			}
			status
			type
			performed_by {
				user_id
				first_name
				last_name
			}
			created_at
			updated_at
			amount
		}
	}
`;

export const CREATE_WITHDRAW_REQUEST = gql`
	mutation CreateWithdrawRequest {
		request: createBalanceWithdrawRequest {
			balance_withdraw_id
		}
	}
`;

export const GET_USER_WITHDRAW_HISTORY = gql`
	query WithdrawHistory {
		history: userBalanceWithdrawHistory {
			balance_withdraw_id
			user {
				user_id
			}
			status
			amount
			created_at
			updated_at
		}
	}
`;

export const GET_USER_INCOME_HISTORY = gql`
	query IncomeHistory {
		history: userBalanceLogsHistory {
			user {
				user_id
			}
			previous_balance
			current_balance
			created_at
		}
	}
`;
