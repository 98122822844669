import React, { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import { UserBook } from 'utils/types/books/Books';
import { BOOK_LIMIT, BookStatus, MIN_CHAPTERS_COUNT, SOURCERS_BOOK_LIMIT } from 'constants/book';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_BOOKS_USER, GET_SOURCER_BOOKS } from 'entites/books/qraphql';
import { CAN_DELETE_BOOK, CREATE_BOOK, DELETE_BOOK, DISCARD_BOOK_CHANGES, GET_BOOK, RESTORE_DRAFT_BOOK, UPDATE_BOOK } from 'entites/book/qraphql';
import { CreateBookData, UpdateBookData } from 'utils/types/books/create-book/NewBookData';
import { GET_ALL_LANGUAGES } from 'entites/languages/get-all-languages';
import { GET_ALL_GENRES } from 'entites/genres/get-all-genres';
import { GET_ALL_TAGS } from 'entites/tags/get-all-tags';
import { Genre } from 'utils/types/books/Genre';
import { Language } from 'utils/types/books/Language';
import { Tag } from 'utils/types/books/Tag';
import { GET_AUTHOR_CHAPTERS, UPDATE_CHAPTERS_ORDER } from 'entites/chapters/graphql';
import { BaseChapter, ChaptersData, UpdateChapter } from 'utils/types/chapters/AuthorChapter';
import { DELETE_CHAPTER } from 'entites/chapter/delete.graphql';
import { CREATE_CHAPTER } from 'entites/chapter/create.graphql';
import { UPDATE_CHAPTER } from 'entites/chapter/update.graphql';
import { CANCEL_MODERATION_REQUEST, CREATE_MODERATION_REQUEST } from 'entites/moderation/create-request.graphql';
import { CANCEL_BOOK_CONTRACT } from 'entites/contract/cancel-contract-request.graphql';
import { BOOK_STATUS } from 'utils/enums/book-status.enum';
import { CONTRACT_STATUS } from 'utils/enums/contract-status.enum';
import { client } from 'components/hoc/WithApollo';
import { CAN_CREATE_BOOK_MODERATION_REQUEST } from 'entites/moderation/can-create-request.graphql';
import { Permissions, StoriesPermissions } from 'utils/types/permissions';
import { GET_NEW_USER_NOTIFICATIONS } from 'entites/notifications/notifications.graphql';
import useShowToast from 'hooks/useShowToast';
import { ChapterOrder } from 'utils/types/chapters/ChapterOrder';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { USER_ROLE } from 'utils/enums/user-role.enum';

type Props = {
  children: React.ReactNode;
};

type Context = {
  // allbooks
  loadMoreBooks: () => void;
  handleChangePage: () => void;
  books: UserBook[];
  isLoading: boolean;
  allBooksLoaded: boolean;
  loadingBook: boolean;

  // delete
  handleDeleteStory: (book_id: string, title: string, reason?: string) => Promise<boolean>;
  deleteStoryLoading: boolean;

  // book manipulations
  handleBookById: (book_id: string) => Promise<UserBook | null>;
  handleBookCreate: (book: CreateBookData) => Promise<string>;
  handleBookUpdate: (bookData: Partial<UpdateBookData>) => Promise<void>;
  bookCreationLoading: boolean;
  handleUpdateBookCover: (book_id: string, cdn_link: string) => void;
  refreshBookData: (book_id: string) => Promise<void>;

  // book parts
  languages: Language[];
  genres: Genre[];
  tags: Tag[];

  // chapters
  handleChaptersByBookId: (book_id: string) => Promise<BaseChapter[]>;
  chapters: Partial<ChaptersData>;
  deleteChapterSilent: (chapter_id: string) => Promise<void>;
  updateChapters: (book_id: string) => Promise<BaseChapter[]>;
  chapterCreationLoading: boolean;
  chapterDeleteLoading: boolean;
  orderUpdateLoading: boolean;
  handleCreateChapter: (book_id: string) => Promise<string>;
  handleUpdateChaptersOrder: (data: BaseChapter[], book_id: string) => Promise<void>;
  deleteChapterById: (chapter_id: string, book_id: string) => Promise<void>;
  isUpdating: boolean;
  handleUpdateChapter: (data: UpdateChapter, book_id: string) => Promise<void>;

  // moderation
  cancelReviewLoading: boolean;
  cancelContractReviewLoading: boolean;
  cancelBookReview: (book: UserBook) => Promise<void>;
  submitForReviewLoading: boolean;
  submitStoryForReview: (book_id: string) => Promise<void>;

  // permissions
  permissions: Partial<StoriesPermissions>,
  getStoryPermission: (book: UserBook) => Promise<Permissions | null>,
  submitPermissionLoading: boolean;
  deletePermissionLoading: boolean;

  // discard/reset changes
  handleDiscardBookChanges: (book_id: string) => Promise<void>;
  handleRestoreDraftBook: (book_id: string) => Promise<void>;
}

export const StoriesContext = React.createContext<Context>({
  loadMoreBooks: async () => {},
  handleChangePage: async () => false,
  books: [],
  isLoading: false,
  allBooksLoaded: false,
  loadingBook: false,
  handleDeleteStory: async () => false,
  deleteStoryLoading: false,
  handleBookById: async () => null,
  handleBookCreate: async () => '',
  handleBookUpdate: async () => {},
  bookCreationLoading: false,
  handleUpdateBookCover: () => {},
  refreshBookData: async () => {},
  languages: [],
  genres: [],
  tags: [],
  handleChaptersByBookId: async () => [],
  chapters: {},
  deleteChapterSilent: async () => {},
  updateChapters: async () => [],
  chapterCreationLoading: false,
  chapterDeleteLoading: false,
  handleCreateChapter: async () => '',
  handleUpdateChaptersOrder: async () => {},
  deleteChapterById: async () => {},
  isUpdating: false,
  handleUpdateChapter: async () => {},
  cancelReviewLoading: false,
  cancelContractReviewLoading: false,
  cancelBookReview: async () => {},
  submitForReviewLoading: false,
  submitStoryForReview: async () => {},
  getStoryPermission: async () => null,
  permissions: {},
  submitPermissionLoading: false,
  deletePermissionLoading: false,
  handleDiscardBookChanges: async () => {},
  handleRestoreDraftBook: async () => {},
  orderUpdateLoading: false,
});

export const StoriesProvider: React.FC<Props> = ({ children }) => {
  const [page, setPage] = useState(1);
	const [books, setBooks] = useState<UserBook[]>([]);
	const [loadingBook, setLoadingBook] = useState(true);
	const [allBooksLoaded, setAllBooksLoaded] = useState(false);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [genres, setGenres] = useState<Genre[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [chapters, setChapters] = useState<Partial<ChaptersData>>({});
  const [permissions, setPermissions] = useState<Partial<StoriesPermissions>>({});
  const {
    showErrorToast,
  } = useShowToast();

  const role = getFromLocalStorage('role') as USER_ROLE;
  const isWriter = role === USER_ROLE.WRITER;

  const handleChangePage = useCallback(() => {
    setPage(prevState => prevState + 1);
  }, []);

  // #region Permissions

  const [canSubmitBook, { loading: submitPermissionLoading }] = useLazyQuery(CAN_CREATE_BOOK_MODERATION_REQUEST, {
    fetchPolicy: 'network-only',
  });

  const [canDelete, { loading: deletePermissionLoading }] = useLazyQuery(CAN_DELETE_BOOK, {
    fetchPolicy: 'network-only',
  });

  async function setStoryPermission(book: UserBook) {
    const { data: {
      canSubmit
    }} = await canSubmitBook({
      variables: { book_id: book.book_id},
    });

    const { data: {
      canDeleteBook
    }} = await canDelete({
      variables: { book_id: book.book_id},
    });
    const canCancelReview: boolean = book?.status === BOOK_STATUS.TO_MODERATION
      || book?.book_contract?.contract_status === CONTRACT_STATUS.WAITING_FOR_REVIEW;
  
    const canManageBook: boolean = (() => {
      if (!book?.deleted) {
        if (
          book?.book_contract &&
          (book?.book_contract?.contract_status ===
            CONTRACT_STATUS.IN_REVIEW
            || book?.book_contract?.contract_status ===
              CONTRACT_STATUS.APPROVED
            || book?.book_contract?.contract_status ===
              CONTRACT_STATUS.WAITING_FOR_REVIEW)
        ) {
          return false;
        }
  
        if (book?.status !== BOOK_STATUS.ON_MODERATION
            && book?.status !== BOOK_STATUS.TO_MODERATION) {
          return true;
        }
      }
  
      return false;
    })();

    const canSubmitToReview: boolean = Boolean(book) &&
      book?.status === BookStatus.DRAFT.value &&
      !book?.deleted;

    const shouldShowHardPreview: boolean = !!book
        && !!book?.book_contract
        && !!book?.book_contract?.contract_status
        && (book?.book_contract?.contract_status === CONTRACT_STATUS.IN_REVIEW
          || book?.status === BOOK_STATUS.ON_MODERATION);
  
    const canSubmitStory: string = (() => {
      if (canSubmitToReview && book && book.chapters?.length < MIN_CHAPTERS_COUNT) {
        return 'To submit a book for review, you need to add 21 or more chapters';
      }
  
      if (canSubmit.errors) {
        return canSubmit.errors[0].reason;
      }
  
      if (book?.status === BOOK_STATUS.APPROVED) {
        return 'Book is published';
      }
  
      if (book?.status === BOOK_STATUS.REJECTED) {
        return 'We have sent you the reasons of the reject. Make the necessary changes to resubmit.';
      }
  
      return '';
    })();
  
    const canReviewContractData: boolean = !!book && !!book?.book_contract
      && !!book?.book_contract?.contract_status
      && (book?.book_contract?.contract_status === CONTRACT_STATUS.IN_REVIEW
      || book?.book_contract?.contract_status === CONTRACT_STATUS.REJECTED
      );
  
    const shouldApplyForContract: boolean = !book?.book_contract
      || !book?.book_contract?.contract_status
      || book?.book_contract?.contract_status === CONTRACT_STATUS.REJECTED;
  
    const canDirectDeleteStory: boolean = canDeleteBook;

    const permissions = {
      canManageBook,
      canCancelReview,
      canSubmitStory,
      shouldApplyForContract,
      canReviewContractData,
      canDirectDeleteStory,
      shouldShowHardPreview,
    };
  
    setPermissions(curr => ({
      ...curr,
      [book.book_id]: permissions,
    }));

    return permissions;
  }

  const getStoryPermission = useCallback(async (book: UserBook) => {
    const localPermission = permissions[book.book_id];

    if (!!localPermission) {
      return localPermission;
    }

    const fetched = await setStoryPermission(book);

    return fetched;
  }, [permissions]);

  // #endregion

  // #region Pull book by id

  const [getBookById] = useLazyQuery(GET_BOOK,
    {
      fetchPolicy: 'network-only',
  });

  const refreshBookData = useCallback(async (book_id: string) => {
    const { data: {
      book
    }} = await getBookById({
      variables: {
        book_id,
      }
    });

    setBooks(curr => {
      if (book.deleted) {
        return curr.filter(item => item.book_id !== book.book_id);
      }

      if (!curr.find(item => item.book_id === book.book_id)) {
        return [...curr, book];
      }

      return curr.map((item) => {
        if (item.book_id !== book.book_id) {
          return item;
        }

        setStoryPermission(book);
        return ({
          ...book,
        });
      });
    });

  }, []);

  const handleBookById = useCallback(async (book_id: string) => {
    const fetchedBook = books.find(item => item.book_id === book_id);

    if (fetchedBook) {
      return fetchedBook;
    }

    const { data: {
      book
    }} = await getBookById({
      variables: {
        book_id,
      }
    });

    setBooks(curr => {
      if (!curr.find(item => item.book_id === book.book_id)) {
        return [...curr, book];
      }

      return curr.map((item) => {
        if (item.book_id !== book.book_id) {
          return item;
        }

        return ({
          ...book
        });
      });
    });

    setStoryPermission(book);

    return book ? book as UserBook : null;
  }, [books]);

  // #endregion

  // #region Chapters Data

  const [getChaptersByBookId] = useLazyQuery(GET_AUTHOR_CHAPTERS, {
    fetchPolicy: 'network-only',
  });

  const [deleteChapter, { loading: chapterDeleteLoading }] = useMutation(DELETE_CHAPTER);

  const [createChapter, { loading: chapterCreationLoading }] = useMutation(CREATE_CHAPTER);

  const [updateChaptersOrder, { loading: orderUpdateLoading }] = useMutation(UPDATE_CHAPTERS_ORDER);

  const [updateChapter, { loading: isUpdating }] = useMutation(UPDATE_CHAPTER);

  const updateChapters = useCallback(async (book_id: string) => {
    const { data: {
      chapters: fetched
    }} = await getChaptersByBookId({
      variables: {
        book_id,
      }
    });

    setChapters(curr => ({
      ...curr,
      [book_id]: fetched,
    }));

    return fetched as BaseChapter[];
  }, []);

  const handleUpdateChapter = useCallback(async (data: UpdateChapter, book_id: string) => {
    const { data: {
      chapter,
    } } = await updateChapter({
      variables: { chapter: { ...data } },
    });

    handleBookById(book_id);

    setChapters(curr => ({
      ...curr,
      [book_id]: (curr[book_id] as BaseChapter[]).map(chapt => {
        if (chapt.chapter_id !== chapter.chapter_id) {
          return chapt;
        }

        return chapter;
      }),
    }));
  }, []);

  const handleUpdateChaptersOrder = useCallback(async (data: BaseChapter[], book_id: string) => {
    const payload = data.map((item, index) => ({
      chapter_id: item.chapter_id,
      chapter_order: index,
    }));

    const reorderedItems = data.map((item, index) => ({
      ...item,
      chapter_order: index,
    }));

    setChapters(curr => ({
      ...curr,
      [book_id]: reorderedItems,
    }));

    const { data: {
      chapters,
    }} = await updateChaptersOrder({
      variables: { chapters: payload },
    });

    if (isEqual(chapters.sort((a: ChapterOrder, b: ChapterOrder) => a.chapter_order - b.chapter_order), payload)) {
      handleBookById(book_id);
    } else {
      showErrorToast({
        id: "reorderChaptersError",
        title: "Sorry something went wrong",
        reason: "Failed to reorder story chapters!",
      });
      setChapters(curr => ({
        ...curr,
        [book_id]: data,
      }));
    }
  }, []);

  const handleCreateChapter = useCallback(async (book_id: string) => {
    const newChapter = {
      book_id,
      title: '',
      text: '',
    };

    const {
      data: {
        chapter,
      },
    } = await createChapter({
      variables: { chapter: newChapter },
    });

    handleBookById(book_id);

    setChapters(curr => ({
      ...curr,
      [book_id]: curr[book_id] ? [...(curr[book_id] as BaseChapter[]), chapter] : [chapter],
    }));

    return (chapter as BaseChapter).chapter_id;
  }, []);

  const handleChaptersByBookId = useCallback(async (book_id: string) => {
    const localChapters = chapters[book_id];

    if (localChapters) {
      return localChapters;
    }

    const fetched = await updateChapters(book_id);

    return fetched;
  }, [chapters]);

  const deleteChapterById = useCallback(async (chapter_id: string, book_id: string) => {
    await deleteChapter({ variables: { chapter_id } });

    handleBookById(book_id);

    setChapters(curr => ({
      ...curr,
      [book_id]: curr[book_id]?.filter(chapter => chapter.chapter_id !== chapter_id)
        .sort((chapterA, chapterB) => chapterA.chapter_order - chapterB.chapter_order)
        .map((item, ind) => ({
          ...item,
          chapter_order: ind,
        })),
    }));
  }, []);

  const deleteChapterSilent = useCallback(async (chapter_id: string) => {
    await deleteChapter({ variables: { chapter_id } });
  }, []);

  // #endregion

  // #region Moderation

  const [cancelReview, { loading: cancelReviewLoading }] = useMutation(CANCEL_MODERATION_REQUEST);

  const [cancelContractReview, { loading: cancelContractReviewLoading }] = useMutation(CANCEL_BOOK_CONTRACT);

  const [requestReview, { loading: submitForReviewLoading }] = useMutation(CREATE_MODERATION_REQUEST);

  const submitStoryForReview = useCallback(async (book_id: string): Promise<void> => {
		await requestReview({ variables: { book_id } });
    refreshBookData(book_id);
    updateChapters(book_id);

    client.query({
      query: GET_NEW_USER_NOTIFICATIONS,
      fetchPolicy: 'network-only',
    });
	}, []);

  const cancelBookReview = useCallback(async (book: UserBook): Promise<void> => {
    if (book?.status === BOOK_STATUS.TO_MODERATION) {
      await cancelReview({ variables: { book_id: book.book_id } });
    }

    if (book?.book_contract?.contract_status === CONTRACT_STATUS.WAITING_FOR_REVIEW) {
      await cancelContractReview({ variables: { bookId: book.book_id } });
    }

    refreshBookData(book.book_id);
    updateChapters(book.book_id);

    client.query({
      query: GET_NEW_USER_NOTIFICATIONS,
      fetchPolicy: 'network-only',
    });
	}, []);
  
  // #endregion

  // #region Book update

  const [updateBook] = useMutation(UPDATE_BOOK);

  const handleBookUpdate = useCallback(async (bookData: Partial<UpdateBookData>): Promise<void> => {
    const { data: {
      book,
    }} = await updateBook({ variables: { book: bookData } });

    setBooks(curr => {
      return curr.map((item) => {
        if (item.book_id !== book.book_id) {
          return item;
        }

        const newBook = {
          ...item,
          ...book,
          description: bookData.description || item.description,
          title: bookData.title || item.title,
          genres: bookData.genres ? bookData.genres.map(genre => genres.find(el => el.genre_id === genre.genre_id)) : item.genres,
          tags: bookData.tags ? bookData.tags.map(tag => tags.find(el => el.tag_id === tag.tag_id)) : item.tags,
          language: bookData.language
            ? languages.find(language => language.language_id === bookData.language?.language_id)
            : item.language,
        };
  
        setStoryPermission(newBook);
        return newBook;
      });
    });
  }, [languages, genres, tags]);

  const handleUpdateBookCover = useCallback((book_id: string, cover_link: string) => {
    setBooks((curr) => curr.map((item) => {
      if (item.book_id !== book_id) {
        return item;
      }

      return ({
        ...item,
        cover_link,
      });
    }));
  }, []);

  // #endregion

  // #region Create book

  const [createBook, { loading: bookCreationLoading }] = useMutation(CREATE_BOOK);

  const handleBookCreate = useCallback(async (book: CreateBookData): Promise<string> => {
    const result = await createBook({ variables: { book } });

    await handleBookById(result?.data?.book?.book_id ?? '');

    return result?.data?.book?.book_id ?? '';
  }, []);

  // #endregion

  // #region deleteStory

  const [deleteStory, { loading: deleteStoryLoading }] = useMutation(DELETE_BOOK);

  const handleDeleteStory = useCallback(async (book_id: string, _title: string, reason = '') => {
		const { data: {
      book,
    } } = await deleteStory({
			variables: {
        book_id,
        reason,
      },
    });

    client.query({
      query: GET_NEW_USER_NOTIFICATIONS,
      fetchPolicy: 'network-only',
    });

    if (book.deleted) {
      setBooks(curr => {
        return curr.filter(item => item.book_id !== book.book_id);
      });

      return book.deleted;
    } else {
      await refreshBookData(book_id);

      return book.book_id === book_id;
    }
	}, []);

  // #endregion

  // #region discard/reset changes

  const [discardChanges] = useMutation(DISCARD_BOOK_CHANGES);

  const [restoreDraftStory] = useMutation(RESTORE_DRAFT_BOOK);

  const handleDiscardBookChanges = useCallback(async (book_id: string) => {
    const { data: {
      book
    }} = await discardChanges({ variables: { book_id } });

    if (book.hasOwnProperty('book_id')) {
      await refreshBookData(book_id);
      await updateChapters(book_id);
    }
  }, []);

  const handleRestoreDraftBook = useCallback(async (book_id: string) => {
    const { data: {
      book
    }} = await restoreDraftStory({ variables: { book_id } });

    if (!book.deleted) {
      await refreshBookData(book_id);
      await updateChapters(book_id);

      client.query({
        query: GET_NEW_USER_NOTIFICATIONS,
        fetchPolicy: 'network-only',
      });
    }
  }, []);

  // #endregion

  // #region fetch stories data

  const { loading: isLoading, data, fetchMore: fetchMoreBooks } = useQuery(
    isWriter ? GET_BOOKS_USER : GET_SOURCER_BOOKS, {
		variables: {
			limit: isWriter ? BOOK_LIMIT : SOURCERS_BOOK_LIMIT,
			page: 0,
		},
		fetchPolicy: 'cache-and-network',
	});

  const loadMoreBooks = useCallback(async () => {
    setLoadingBook(true);
		await fetchMoreBooks({
			variables: {
				page: page,
			},
			updateQuery: (_prev, { fetchMoreResult }) => {
				if (
					!fetchMoreResult || (fetchMoreResult?.books && fetchMoreResult?.books?.length === 0)
				) {
					setLoadingBook(false);
					setAllBooksLoaded(true);

					return ({ books });
				}

        const currentIds = books.map((book: UserBook) => book.book_id);
        const updatedBooks = {
          books: [...books, ...(fetchMoreResult.books as UserBook[]).filter((book: UserBook) => !currentIds.includes(book.book_id))],
        };

        if (fetchMoreResult.books.length < BOOK_LIMIT) {
					setLoadingBook(false);
          setAllBooksLoaded(true);
        }

        return updatedBooks;
			},
		});
	}, [fetchMoreBooks, page, books]);

  useEffect(() => {
    if (data?.books && !isEqual(data?.books, books)) {
      setBooks(data.books || []);
    }

    setLoadingBook(false);
	}, [data]);

  // #endregion

  // #region BookData

  const { data: allLanguages, loading: isLanguagesLoading } = useQuery(
		GET_ALL_LANGUAGES,
		{
			fetchPolicy: 'cache-first',
		},
	);

	useEffect(() => {
		if (allLanguages && !isLanguagesLoading) {
			setLanguages(allLanguages.getAllLanguages);
		}
	}, [isLanguagesLoading]);

  const { data: allGenres, loading: isGenresLoading } = useQuery(
		GET_ALL_GENRES,
		{
			fetchPolicy: 'cache-first',
		},
	);

	useEffect(() => {
		if (allGenres && !isGenresLoading) {
			setGenres(allGenres.getAllGenres);
		}
	}, [isGenresLoading]);

  const { data: allTags, loading: isTagsLoading } = useQuery(
    GET_ALL_TAGS,
    {
      fetchPolicy: 'cache-first',
    }
  );

	useEffect(() => {
		if (allTags && !isTagsLoading) {
			setTags(allTags.getAllTags);
		}
	}, [isTagsLoading]);

  // #endregion

  const contextValue = {
    isLoading,
    loadMoreBooks,
    books,
    allBooksLoaded,
    handleChangePage,
    loadingBook,
    handleDeleteStory,
    deleteStoryLoading,
    chapterDeleteLoading,
    handleBookById,
    createBook,
    bookCreationLoading,
    handleBookCreate,
    handleUpdateBookCover,
    handleBookUpdate,
    refreshBookData,
    languages,
    genres,
    tags,
    handleChaptersByBookId,
    chapters,
    deleteChapterSilent,
    updateChapters,
    chapterCreationLoading,
    handleCreateChapter,
    handleUpdateChaptersOrder,
    deleteChapterById,
    isUpdating,
    handleUpdateChapter,
    cancelReviewLoading,
    cancelContractReviewLoading,
    cancelBookReview,
    submitForReviewLoading,
    submitStoryForReview,
    getStoryPermission,
    permissions,
    submitPermissionLoading,
    deletePermissionLoading,
    handleDiscardBookChanges,
    handleRestoreDraftBook,
    orderUpdateLoading,
  };

  return (
    <StoriesContext.Provider value={contextValue}>
      {children}
    </StoriesContext.Provider>
  );
};