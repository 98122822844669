import { Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

type Props = {
  stage: string;
  description: string;
}

export const Subpoint: FC<Props> = ({
  stage,
  description,
}) => {
  return (
    <Flex ml="38px">
      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
        mr="16px"
      >
        {stage}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins16normal"
        letterSpacing="-0.016px"
        lineHeight="28px"
        textAlign="justify"
      >
        {description}
      </Text>
    </Flex>
  );
};
