import { gql } from '@apollo/client';

export const CAN_CREATE_BOOK_MODERATION_REQUEST = gql`
	query GetCanSubmitBookModerationRequest($book_id: String!) {
		canSubmit: canCreateBookModerationRequest(
			request: { book: { book_id: $book_id } }
		) {
			result
			warnings {
				id
				reason
			}
			errors {
				reason
			}
		}
	}
`;
