import { Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { NewPasswordForm } from '../NewPasswordForm';
import { FC } from 'react';
import { ResetPasswordResponce } from 'utils/types/user/reset-password/ResetPasswordResult';

type Props = {
  passwordResetResult: ResetPasswordResponce | undefined;
  handleFinalyzeResetPassword: (password: string) => void;
};

export const ThirdStep: FC<Props> = ({
  passwordResetResult,
  handleFinalyzeResetPassword,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading
        size="poppins25"
        variant="primary-black-text"
        mb="12px"
      >
        {t('Create new password')}
      </Heading>

      <Text
        maxW="360px"
        size="poppins16normal"
        variant="primary-black-text"
        lineHeight="28px"
        letterSpacing="-0.016px"
        textAlign="center"
        mb="24px"
      >
        {t('Your new password must be different from previously used password')}
      </Text>

      <NewPasswordForm
        passwordResetResult={passwordResetResult}
        handleFinalyzeResetPassword={handleFinalyzeResetPassword}
      />
    </>
  );
};
