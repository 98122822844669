import { Box, Flex } from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from 'shared/utils/chakra-theme/colors';
import { useMutation } from '@apollo/client';
import { RESET_USER_PASSWORD } from 'entites/user/reset-user-password.graphql';
import useQuery from 'hooks/useQuery';
import { SecondStep } from './SecondStep';
import { FirstStep } from './FirstStep';
import { ThirdStep } from './ThirdStep';
import { FINALYZE_RESET_USER_PASSWORD } from 'entites/user/finalyze-reset-user-password.graphql';
import hashPassword from 'utils/helpers/hash/hashPassword';
import { FinalStep } from './FinalStep';
import { BackButton } from 'components/BackButton';

export const ResetPassword: FC = () => {
  const { token } = useQuery();
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const isFirstStep = step === 1;
  const isSecondStep = step === 2;
  const isThirdStep = step === 3;
  const isFinalStep = step === 4;

  const [startResetPasswordFlow] = useMutation(RESET_USER_PASSWORD);
  const [
    finalyzeResetPasswordFlow,
    { data: passwordResetResult },
  ] = useMutation(FINALYZE_RESET_USER_PASSWORD);

  const handleRedirectOnLoginPage = useCallback(() => {
    navigate('/auth/log-in');
  }, []);

  const handleStartResetPassword = useCallback(async (email: string) => {
    const { data } = await startResetPasswordFlow({
      variables: {
        email,
      }
    });

    if (data) {
      if (data.result.success) {
        setStep(2);
      } else {
        setError(data.result.error);
      }
    }
  }, []);

  const handleFinalyzeResetPassword = useCallback(async (password: string) => {
    const hashedPassword: string = await hashPassword(password);

    await finalyzeResetPasswordFlow({
      variables: {
        input: {
          password: hashedPassword,
          token,
        }
      }
    });
  }, [hashPassword, token]);
  
  useEffect(() => {
    if (token) {
      setStep(3);
    }
  }, [token]);

  useEffect(() => {
    if (passwordResetResult
      && passwordResetResult.result.success
    ) {
      setStep(4);
    }
  }, [passwordResetResult]);

  return (
    <Flex
      direction="column"
      p="72px 100px 44px"
      align="center"
      borderRadius="8px"
      boxShadow="0px 5px 15px 0px rgba(36, 28, 88, 0.20)"
      bg={colors.white}
      h="707px"
      maxW="600px"
      w="100%"
      pos="relative"
    >
      {!isFinalStep && (
        <Box
          pos="absolute"
          top="24px"
          left="24px"
        >
          <BackButton onClick={handleRedirectOnLoginPage}/>
        </Box>
      )}

      {isFirstStep && (
        <FirstStep
          handleStartResetPassword={handleStartResetPassword}
          error={error}
        />
      )}

      {isSecondStep && (
        <SecondStep />
      )}

      {isThirdStep && (
        <ThirdStep
          passwordResetResult={passwordResetResult}
          handleFinalyzeResetPassword={handleFinalyzeResetPassword}
        />
      )}

      {isFinalStep && (
        <FinalStep />
      )}
    </Flex>
  );
};
