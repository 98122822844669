export enum REQUESTS_TABS {
  STORIES = 'stories',
  CONTRACTS = 'contracts',
};

export enum REVENUE_TABS {
  PERIODS = 'periods',
  REQUESTS = 'requests',
  AUTHORS = 'authors',
};

export enum CONTENT_TABS {
  CREATORS = 'creators',
  STORIES = 'stories',
  CONTRACTS = 'contracts',
};

export enum LOCALIZATION_TABS {
  STORIES = 'stories',
  GENRES = 'genres',
  TAGS = 'tags',
  SECTIONS = 'sections',
};

export enum INBOX_TABS {
  INBOX = 'inbox',
  SENT = 'sent',
};
