import { gql } from '@apollo/client';

export const GET_USER_BALANCES = gql`
	query UserBalances($page: Int!, $limit: Int!, $orders: [Ordering!]) {
		balances: userBalances(page: $page, limit: $limit, orders: $orders) {
			user {
				user_id
				first_name
				last_name
				photo_link
			}
			balance
			modified_by {
				user_id
				first_name
				last_name
				photo_link
			}
			updated_at
		}
	}
`;

export const GET_USER_BALANCE = gql`
	query UserBalance {
		balance: userBalance {
			user {
				user_id
				email
				first_name
				last_name
				photo_link
			}
			balance
			withdraw_status
			withdraw_status_description
			modified_by {
				user_id
				email
				first_name
				last_name
				photo_link
			}
			updated_at
		}
	}
`;

export const GET_USER_BALANCES_SEARCH = gql`
	query UserBalancesSearch($page: Int!, $limit: Int!, $search: String!) {
		balancesSearch: searchUserBalancesByUserName(
			page: $page
			limit: $limit
			search: $search
		) {
			user {
				user_id
				first_name
				last_name
				photo_link
			}
			balance
			modified_by {
				user_id
				first_name
				last_name
				photo_link
			}
			updated_at
		}
	}
`;

export const UPDATE_USER_BALANCE = gql`
	mutation UpdateBalance($balance: UpdateUsersBalanceDto!) {
		balance: updateUserBalance(balance: $balance) {
			user {
				user_id
				first_name
				last_name
				photo_link
			}
			balance
			updated_at
			modified_by {
				user_id
				first_name
				last_name
				photo_link
			}
		}
	}
`;
