import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { UserContext } from 'context/UserContext';
import { CSSProperties, FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { FullProps, NotifToastProps, ShortProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../Icon';
import { useNavigate } from 'react-router-dom';
import { BodyWithBook } from '../../UI/Toast/BodyWithBook';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';

export const DeleteApprovedToast: FC<Required<NotifToastProps>> = ({
  id,
  title,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          img="/images/notifications/story/tick-circle.svg"
          color={'linear-gradient(180deg, #B86BF8 0%, #7F5DD9 100%)'}
        />
      </Box>

      <BodyWithBook
        title={t('Delete book request approved')}
        textA={t('Your application has been approved! Your request to delete a story ')}
        textB={t(' has been approved by a moderator...')}
        bookTitle={title}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const DeleteApprovedShort: FC<ShortProps> = ({
  id,
  isSelected,
  user_readed,
  title,
  createdAt,
  toggleSelect,
  handleOpenNotification,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          img="/images/notifications/story/tick-circle.svg"
          color={'linear-gradient(180deg, #B86BF8 0%, #7F5DD9 100%)'}
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Delete book request approved')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('Your application has been approved! Your request to delete a story ')}
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins16normal"
            fontWeight={600}
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {t(title)}
          </Text>
          {t(' has been approved by a moderator...')}
        </Text>
      </Flex>
    </Flex>
  );
};

export const DeleteApprovedFull: FC<FullProps> = ({
  title,
  createdAt,
}) => {
  const { t } = useTranslation();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              img="/images/notifications/story/tick-circle.svg"
              color={'linear-gradient(180deg, #B86BF8 0%, #7F5DD9 100%)'}
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/admin/success.png"
        loading="eager"
        alt="delete approved"
      />

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={400}
        textAlign="center"
      >
        {t(`Hi, ${user?.first_name}!`)}
        <br />
        <Text
          as="span"
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={600}
        >
          {t('Your application has been approved!')}
        </Text>
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        letterSpacing={0}
        textAlign="center"
        maxW="578px"
        mb="20px"
      >
        {t('Your request to delete a story')}
        <br />
        <Text
          as="span"
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          letterSpacing={0}
          fontWeight={600}
        >
          {t(title)}
        </Text>
        <br />
        {t('has been approved by a moderator.')}
        <br />
        {t('The book has been removed from your catalog and is no longer published in the app.')}
      </Text>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};