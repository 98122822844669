import { FC, useCallback, useEffect, useState } from 'react';
import { GoogleButton } from '../LogIn/GoogleButton';
// import { FacebookButton } from '../LogIn/FacebookButton';
import { InfoWithTermsAndPolicy } from '../LogIn/InfoWithTermsAndPolicy';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { colors } from 'shared/utils/chakra-theme/colors';
import { useTranslation } from 'react-i18next';
import { SignUpForm } from './SignUpForm';
import { ReturnLoginLink } from './SignUpForm/ReturnLoginLink';
import { PersonalInfoForm } from './PersonalInfoForm';
import { BackButton } from 'components/BackButton';
import LoadingSpinner from 'components/UI/LoadingSpinner';
import { LoginCredentials, SignUpCredentials } from 'utils/types/user';

type Props = {
  credentials: LoginCredentials;
  handleSetCredentials: (data: LoginCredentials) => void;
  signUpStep: number;
  isUserCreationLoading: boolean;
  changeSignUpStep: (step: number) => void;
	loginViaGoogleHandler: () => void;
  loginViaFacebookHandler: () => void;
  handleCreateAccount: (values: SignUpCredentials) => void;
};

export const SignUp: FC<Props> = ({
  credentials,
  handleSetCredentials,
  signUpStep,
  changeSignUpStep,
  loginViaGoogleHandler,
  // loginViaFacebookHandler,
  handleCreateAccount,
  isUserCreationLoading,
}) => {
  const [isAcceptPolicy, setIsAcceptPolicy] = useState(false);
  const [hasPolicyError, setHasPolicyError] = useState(false);
  const { t } = useTranslation();
  const isFirstStep = signUpStep === 1;
  const isSecondStep = signUpStep === 2;

  const toggleConfirmRules = useCallback(() => {
    setIsAcceptPolicy(true);
    setHasPolicyError(false);
  }, []);

  const handleShowPolicyError = useCallback(() => {
    setHasPolicyError(true);
  }, []);

  useEffect(() => {
    if (credentials.email) {
      toggleConfirmRules();
    }
  }, [credentials]);

  useEffect(() => {
    const signupButton = document.querySelector<HTMLButtonElement>('.signup-button');
    const passwordField = document.getElementById('password');

    const checkEnterPress = (e: KeyboardEvent) => {
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (signupButton) {
          passwordField?.blur();
          signupButton.click();
        }
      }
    };


    if (passwordField) {
      passwordField.addEventListener('keypress', checkEnterPress);
    }

    return () => {
      passwordField?.removeEventListener('keypress', checkEnterPress);
    };
  });

  return (
    <Flex
      direction="column"
      p="72px 100px 44px"
      align="center"
      borderRadius="8px"
      boxShadow="0px 5px 15px 0px rgba(36, 28, 88, 0.20)"
      bg={colors.white}
      maxW="600px"
      w="100%"
      pos="relative"
    >
      {isFirstStep && (
        <>
          <Heading
            size="poppins25"
            variant="primary-black-text"
            mb="12px"
          >
            {t('Sign Up')}
          </Heading>

          <InfoWithTermsAndPolicy
            toggleConfirmRules={toggleConfirmRules}
            isAcceptPolicy={isAcceptPolicy}
            hasPolicyError={hasPolicyError}
          />

          <GoogleButton
            isSignUp={true}
            isAcceptPolicy={isAcceptPolicy}
            loginViaGoogleHandler={loginViaGoogleHandler}
            handleShowPolicyError={handleShowPolicyError}
          />

          {/* <FacebookButton loginViaFacebookHandler={loginViaFacebookHandler} /> */}

          <Text
            size="poppins18"
            variant="primary-black-text"
            mb="13px"
          >
            {t('OR')}
          </Text>

          <SignUpForm
            credentials={credentials}
            handleSetCredentials={handleSetCredentials}
            changeSignUpStep={changeSignUpStep}
            isAcceptPolicy={isAcceptPolicy}
            handleShowPolicyError={handleShowPolicyError}
          />
        </>
      )}

      {isSecondStep && (
        <>
          {isUserCreationLoading && <LoadingSpinner />}

          <Box
            pos="absolute"
            top="24px"
            left="24px"
          >
            <BackButton onClick={() => changeSignUpStep(1)} />
          </Box>

          <Heading
            size="poppins24"
            variant="primary"
            mb="12px"
          >
            {t('Almost done!')}
          </Heading>

          <PersonalInfoForm
            handleCreateAccount={handleCreateAccount}
          />
        </>
      )}

      {isFirstStep && <ReturnLoginLink />}
    </Flex>
  );
};
