import { FC } from 'react';

type Props = {
  definition: string;
  description: string;
}

export const Definitions: FC<Props> = ({
  definition,
  description,
}) => {
  return (
    <>
    <strong>{definition}</strong>{' '}{description}
    </>
  );
};
