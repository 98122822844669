import { Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const SignUpLink: FC = () => {
  const { t } = useTranslation();

  return (
    <Text
      size="poppins16"
      variant="primary-black-text"
      textAlign="center"
      lineHeight="22px"
      mb="12px"
    >
      {t(' Don`t have an account? ')}
      <Link
        href='/auth/sign-up'
        rel='noreferrer'
        variant="resetPassword"
        _hover={{ textDecoration: 'none' }}
      >
        {t('Join Passion Creators')}
      </Link>
    </Text>
  );
};
