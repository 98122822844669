import { setInLocalStorage } from 'utils/helpers/local-storage/localStorage';

export function handleLogOut(redirect = true) {
	setInLocalStorage('access_token', '');
	setInLocalStorage('refresh_token', '');
	setInLocalStorage('user_id', '');
	setInLocalStorage('role', '');

	window.db_userId = undefined;
	window.userEmail = undefined;

	if (redirect) {
		window.location.href = `${window.location.origin}/auth/sign-in`;
	}
}
