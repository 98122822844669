import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';

export const ChapterAutosaveToast = () => {
  const { t } = useTranslation();
  return (
    <Flex
      bg={colors['primary-emerald-60']}
      borderRadius="16px"
      boxShadow="0px 5px 15px 0px rgba(36, 28, 88, 0.20)"
      align="center"
      gap="16px"
      p="16px 44px 16px 17px"
    >
      <Box>
        <Image
          src="/images/story/Flash.png"
          alt="flash"
          loading="eager"
          w="40px"
          h="40px"
        />
      </Box>
      
      <Text
        size="poppins16"
        fontWeight={500}
        variant="primary-black-text"
        lineHeight="24px"
      >
        {t('Your text will be saved automatically every 30 seconds')}
      </Text>
    </Flex>
  );
};
