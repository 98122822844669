import { colors } from '../colors';

export const textStyle = {
	sizes: {
		poppins8: {
			fontWeight: 500,
			fontSize: '8px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
			lineHeight: '16px',
		},
		poppins12: {
			fontWeight: 600,
			fontSize: '12px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins14: {
			fontWeight: 400,
			fontSize: '14px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins16normal: {
			fontWeight: 400,
			fontSize: '16px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins16: {
			fontWeight: 500,
			fontSize: '16px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins18: {
			fontWeight: 500,
			fontSize: '18px',
			lineHeight: '22px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins20normal: {
			fontWeight: 400,
			fontSize: '20px',
			lineHeight: '30px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins24: {
			fontWeight: 600,
			fontSize: '24px',
			lineHeight: '34px',
			letterSpacing: '-0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins28: {
			fontWeight: 600,
			fontSize: '28px',
			lineHeight: '36px',
			fontFamily: `'Poppins', sans-serif`,
		},
		poppins38: {
			fontWeight: 600,
			fontSize: '38px',
			lineHeight: '48px',
			letterSpacing: '0.01em',
			fontFamily: `'Poppins', sans-serif`,
		},
		16: {
			fontSize: '16px',
		},
	},
	variants: {
		'primary-black-text': {
			color: colors['primary-black-text'],
		},
		'primary-violet-100': {
			color: colors['primary-violet-100'],
		},
		'primary-white-100': {
			color: colors.white,
		},
		'light-text-text-danger': {
			color: colors['light-text-text-danger'],
		},
		'pink-100': {
			color: colors['pink-100'],
		},
		'primary-gray-100': {
			color: colors['primary-gray-100'],
		},
		'primary-gray-60': {
			color: colors['primary-gray-60'],
		},
		'primary-gray-30': {
			color: colors['primary-gray-30'],
		},
		'button-hover': {
			color: colors['button-hover'],
		},
		'primary-blue-100': {
			color: colors['primary-blue-100'],
		},
		white: {
			color: '#f4f4f4',
		},
		'grey': {
			color: colors.grey,
		},
	},
	defaultProps: {
		size: '16',
		variant: 'white',
	},
};
