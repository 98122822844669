import { setInLocalStorage } from 'utils/helpers/local-storage/localStorage';
import { TokensDataDto } from 'utils/types/user';

export function handleSetTokens(tokensDataDto: TokensDataDto) {
  const {
    access_token,
    refresh_token,
  } = tokensDataDto;

  setInLocalStorage('access_token', `Bearer ${access_token}`);
	setInLocalStorage('refresh_token', refresh_token);
}
