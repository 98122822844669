import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import isEqual from 'lodash.isequal';
import { NOTIFICATION_UPDATE_ACTION } from 'utils/enums/notification-update-action.enum';
import { StoriesContext } from 'context/StoriesContext';
import { NOTIFICATION_TYPE } from 'utils/enums/notification-type.enum';
import { GET_USER_BALANCE } from 'entites/revenue/user-balance.graphql';
import { client } from 'components/hoc/WithApollo';
import {
  DELETE_NOTIFICATIONS,
  GET_NEW_USER_NOTIFICATIONS,
  GET_USER_NOTIFICATIONS,
  MARK_CHECKED,
  MARK_READED,
} from 'entites/notifications/notifications.graphql';
import { NOTIFICATION_STATUS } from 'utils/enums/notification-status.enum';
import { Notification } from 'utils/types/notifications';
import { GET_USER_INCOME_HISTORY, GET_USER_WITHDRAW_HISTORY, GET_WITHDRAW_REQUESTS } from 'entites/revenue/requests.graphql';
import { ITEMS_BATCH } from 'constants/admin/content';

type Props = {
  children: React.ReactNode;
};

type Context = {
  handleClear: (id: string) => Promise<void>;
  handleClearBatch: (ids: string[]) => Promise<void>;
  handleDeleteNotifications: (ids: string[]) => Promise<boolean>;
  handleMarkAsRead: (ids: string[]) => Promise<boolean>;
  notifications: Notification[];
  loading: boolean;
}

export const NotificationsContext = React.createContext<Context>({
  handleClear: async () => {},
  handleClearBatch: async () => {},
  handleDeleteNotifications: async () => false,
  handleMarkAsRead: async () => false,
  notifications: [],
  loading: false,
});

export const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const {
		refreshBookData,
		updateChapters,
	} = useContext(StoriesContext);

  const {
    data: allNotifications,
    loading: isAllLoading,
  } = useQuery(GET_USER_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    data: newNotifications,
    loading: isNewLoading,
    startPolling,
  } = useQuery(GET_NEW_USER_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
  });

  const dataLoading = isAllLoading || isNewLoading;


  const [markReaded] = useMutation(MARK_READED);

  const [markChecked] = useMutation(MARK_CHECKED);

  const [deleteNotifications] = useMutation(DELETE_NOTIFICATIONS);

  const handleUpdateOnBoard = useCallback((
    ids: string[],
    action: NOTIFICATION_UPDATE_ACTION,
  ) => {
    if (action === NOTIFICATION_UPDATE_ACTION.DELETE) {
      setNotifications(current => current.filter(notification => {
        if (!ids.includes(notification.notification_id)) {
          return true;
        }
  
        return false;
      }));
    }

    if (action === NOTIFICATION_UPDATE_ACTION.STATUS) {
      setNotifications(current => current.map(notification => {
        if (!ids.includes(notification.notification_id)) {
          return notification;
        }

        return ({
          ...notification,
          status: NOTIFICATION_STATUS.CHECKED,
        });
      }));
    }

    if (action === NOTIFICATION_UPDATE_ACTION.READ) {
      setNotifications(current => current.map(notification => {
        if (!ids.includes(notification.notification_id)) {
          return notification;
        }
  
        return ({
          ...notification,
          status: NOTIFICATION_STATUS.READED,
        });
      }));
    }
  }, []);

  const handleClearBatch = useCallback(async (ids: string[]) => {
    const result = await markChecked({
      variables: {
        ids,
      }
    });

    if (result.data.result) {
      handleUpdateOnBoard(ids, NOTIFICATION_UPDATE_ACTION.STATUS);
    }
  }, [handleUpdateOnBoard, markChecked]);

  const handleClear = useCallback(async (id: string) => {
    const result = await markChecked({
      variables: {
        ids: [id],
      }
    });

    if (result.data.result) {
      handleUpdateOnBoard([id], NOTIFICATION_UPDATE_ACTION.STATUS);
    }
  }, [handleUpdateOnBoard, markChecked]);

  const handleDeleteNotifications = useCallback(async (ids: string[]): Promise<boolean> => {
    const result = await deleteNotifications({
      variables: {
        ids,
      }
    });

    if (result.data.result) {
      handleUpdateOnBoard(ids, NOTIFICATION_UPDATE_ACTION.DELETE);
    }

    return result.data.result;
  }, [handleUpdateOnBoard]);

  const handleMarkAsRead = useCallback(async (ids: string[]): Promise<boolean> => {
    const result = await markReaded({
      variables: {
        ids,
      }
    });

    if (result.data.result) {
      handleUpdateOnBoard(ids, NOTIFICATION_UPDATE_ACTION.READ);
    }

    return result.data.result;
  }, [handleUpdateOnBoard]);

  const handleAddNotifications = useCallback((data: Notification[]) => {
    setNotifications(curr => {
      const combined = [...data, ...curr];

      const filtred = Array.from(
        new Set(combined.map(item => item.notification_id))
      ).map(id => (combined.find(item => item.notification_id === id) as Notification))
      .sort((messageA, messageB) => new Date(messageB.createdAt).getTime() - new Date(messageA.createdAt).getTime());

      return filtred;
    });
  }, []);

  const handleUpdateData = useCallback((data: Notification[]) => {
    data.forEach((notification: Notification) => {
      if (notification.status === NOTIFICATION_STATUS.UPLOADED) {
        if (notification.book) {
          refreshBookData(notification?.book?.book_id ?? '');
          updateChapters(notification?.book?.book_id ?? '');
        }
        
        if (notification.type === NOTIFICATION_TYPE.REVENUE_SENT
          || notification.type === NOTIFICATION_TYPE.REVENUE_UPDATE) {
          client.query({
            query: GET_USER_BALANCE,
            fetchPolicy: 'network-only',
          });
          client.query({
            query: GET_USER_INCOME_HISTORY,
            fetchPolicy: 'network-only',
          });
          client.query({
            query: GET_USER_WITHDRAW_HISTORY,
            fetchPolicy: 'network-only',
          });
        }

        if (notification.type === NOTIFICATION_TYPE.USER_WITHDRAWAL_REQUEST) {
          client.query({
            query: GET_WITHDRAW_REQUESTS,
            variables: {
              page: 0,
              limit: ITEMS_BATCH,
            },
            fetchPolicy: 'network-only',
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (newNotifications && !isNewLoading) {
      const { notifications } = newNotifications;

      if (notifications.length > 0) {
        handleAddNotifications(notifications);
        handleUpdateData(notifications);
      }
    }
  }, [newNotifications, isNewLoading]);

  useEffect(() => {
    if (allNotifications && !isAllLoading) {
      if (!isEqual(allNotifications.notifications, notifications)) {
        setNotifications(allNotifications.notifications);

        handleUpdateData(allNotifications.notifications);

        startPolling(60000);
      }
    }
  }, [allNotifications, isAllLoading]);

  const contextValue = {
    notifications,
    loading: dataLoading,
    handleClear,
    handleDeleteNotifications,
    handleMarkAsRead,
    handleClearBatch,
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  );
};