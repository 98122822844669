import { FC } from 'react';
import { ResetPasswordEmailForm } from '../ResetPasswordEmailForm';
import { Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleStartResetPassword: (email: string) => void;
  error: string;
};

export const FirstStep: FC<Props> = ({
  handleStartResetPassword,
  error,
}) => {
  const { t } = useTranslation();

  return (
    <>
    <Heading
      size="poppins25"
      variant="primary-black-text"
      mb="12px"
    >
      {t('Forgot password?')}
    </Heading>

    <Text
      maxW="360px"
      size="poppins14"
      variant="primary-black-text"
      lineHeight="24px"
      textAlign="center"
      mb="36px"
    >
      {t('Enter the email address you used when you joined')}
      {t('and we’ll send you instructions to reset your password')}
    </Text>

    <ResetPasswordEmailForm
      handleStartResetPassword={handleStartResetPassword}
      error={error}
    />
  </>
  );
};
