import { Flex, Text } from '@chakra-ui/react';
import { CSSProperties, FC } from 'react';
import { colors } from 'shared/utils/chakra-theme/colors';

type Props = {
  text: string | JSX.Element;
  title: string;
  handleReadFull: () => void;
};

export const Body: FC<Props> = ({
  text,
  title,
  handleReadFull,
}) => {
  return (
    <Flex
      direction="column"
      gap="5px"
      justify="space-between"
      h="100%"
    >
      <Text
        variant="primary-black-text"
        size="poppins16"
        lineHeight="22px"
        fontWeight={600}
        letterSpacing="-0.01em"
        _hover={{
          color: colors['button-hover']
        }}
        cursor="pointer"
        onClick={handleReadFull}
        display='-webkit-box'
        sx={{
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
        } as CSSProperties}
        overflow="hidden"
        textOverflow="ellipsis"
        wordBreak="break-word"
      >
        {title}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins14"
        lineHeight="21px"
        letterSpacing="-0.01em"
        w="100%"
        display='-webkit-box'
        sx={{
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        } as CSSProperties}
        overflow="hidden"
        textOverflow="ellipsis"
        wordBreak="break-word"
      >
        {text}
      </Text>
    </Flex>
  );
};
