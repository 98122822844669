import * as EmailValidator from 'email-validator';
import * as yup from 'yup';
import {
	DEBOUNCE_DELAY,
	MIN_PASSWORD_LENGTH,
} from 'constants/values';
import { AnalyticsEventType } from 'model/analytics';
import { client } from 'components/hoc/WithApollo';
import { CHECK_USER_EMAIL } from 'entites/user/check-user-email.graphql';
import debounce from 'lodash.debounce';
import hashPassword from 'utils/helpers/hash/hashPassword';
import { CHECK_USER_PASSWORD } from 'entites/user/check-user-password.graphql';
import { AUTH_STATE } from 'utils/enums/auth-state.enum';
import { NewPasswords } from 'utils/types/user/reset-password/NewPasswords';
import { CHECK_USER_PEN_NAME } from 'entites/user/check-user-pen-name.graphql';
import { LoginCredentials } from 'utils/types/user';

export const checkUserEmail = async (email: string): Promise<boolean> => {
	return client
		.mutate({
			mutation: CHECK_USER_EMAIL,
			variables: {
				email,
			},
		})
		.then((response) => {
			return response.data.checkUserEmail;
		});
};

const checkUserCredentials = async (
	email: string,
	password: string,
): Promise<boolean> => {
	return client
		.mutate({
			mutation: CHECK_USER_PASSWORD,
			variables: {
				credentials: {
					email,
					password,
				},
			},
		})
		.then((response) => {
			return response.data.checkUserCredentials;
		});
};

export const checkUserPenName = async (pen_name: string): Promise<boolean> => {
	return client
		.mutate({
			mutation: CHECK_USER_PEN_NAME,
			variables: {
				pen_name,
			},
		})
		.then((response) => {
			return response.data.checkUserPenName;
		});
};

type ResolveCallback = (value: boolean) => void;

export const validateEmail = async (
	value: string | undefined,
	resolve: ResolveCallback,
) => {
	if (value) {
		const valid = await checkUserEmail(value);

		return resolve(!valid);
	}

	return resolve(false);
};

const verifyPenName = async (
	value: string | undefined,
	resolve: ResolveCallback,
) => {
	if (value) {
		const valid = await checkUserPenName(value);

		return resolve(!valid);
	}

	return resolve(false);
};

export const debounceEmailValidation = debounce(validateEmail, DEBOUNCE_DELAY);
export const debouncePenNameVerification = debounce(verifyPenName, DEBOUNCE_DELAY);

export const AmpArgsByAuthState = {
	[AUTH_STATE.LOG_IN]: {
		PAGE_NAME: AUTH_STATE.LOG_IN,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
	[AUTH_STATE.SIGN_UP]: {
		PAGE_NAME: AUTH_STATE.SIGN_UP,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
	[AUTH_STATE.RESET_PASSWORD]: {
		PAGE_NAME: AUTH_STATE.RESET_PASSWORD,
		EVENT_NAME: AnalyticsEventType.PAGE_VIEW_OPENED,
	},
};

export const loginValidationSchema = (values: LoginCredentials) => yup.object({
	email: yup.string()
		.required('Please enter your email.')
		.test(
			'local valid',
			'Email address is not valid.',
			async email => {
				if (!email) {
					return false;
				}

				const valid = EmailValidator.validate(email);

				return valid;
			},
		),
		password: yup.string()
			.required('Please enter your password.')
			.test('email waiting', 'Please enter valid email first', async () => {
				const localValid = EmailValidator.validate(values.email);

				if (!values.email || !localValid) {
					return false;
				}

				return true;
			})
			.test('server valid', 'Wrong email or password, please check', async password => {
				const localValid = EmailValidator.validate(values.email);

				if (!values.email || !localValid || !password || password.length < 8) {
					return false;
				}

				const hashedPassword: string = await hashPassword(password);

				try {
					const valid = await checkUserCredentials(
						values.email,
						hashedPassword,
					);

					return valid;
				} catch (error) {
					return false;
				}
			}),
});

export const resetEmailValidationSchema = yup.object({
	email: yup.string()
		.required('Please enter your email.')
		.test(
			'local valid',
			'Invalid email address.',
			async email => {
				if (!email) {
					false;
				}

				const valid = EmailValidator.validate(email);

				return valid;
			},
		)
		.test(
			'server valid',
			"Invalid email address.",
			async email => {
				if (!email) {
					return false;
				}

				const valid = await checkUserEmail(email);

				return valid;
			},
		),
});

export const signUpValidationSchema = yup.object({
	email: yup.string()
		.required('Please enter your email.')
		.test(
			'local valid',
			'Invalid email address.',
			async value => {
				if (!value) {
					return false;
				}

				const valid = EmailValidator.validate(value);

				return valid;
			},
		)
		.test(
			'server valid',
			'An account with this email address already exists',
			value => new Promise(resolve => debounceEmailValidation(value, resolve))
		),
	password: yup.string()
		.min(MIN_PASSWORD_LENGTH, 'Password should be at least 8 characters.')
		.matches(
			/^(?=.*[0-9]+.*)(?=.*[a-zA-Zа-яА-Я]+.*)[0-9a-zA-Zа-яА-Я\S]{2,}$/gm,
			'Password should be at least one number, one letter and no spaces.',
		)
		.required(' Please enter your password.'),
});

export const createAccountValidationSchema = yup.object({
	first_name: yup.string()
		// .max(MAX_FIRST_NAME_LENGTH, 'Must be 15 characters or less')
		.required('Required'),
	last_name: yup.string()
		// .max(MAX_LAST_NAME_LENGTH, 'Must be 20 characters or less')
		.required('Required'),
	pen_name: yup.string()
		// .max(MAX_LAST_NAME_LENGTH, 'Must be 20 characters or less')
		.required('Required')
		.test(
			'server valid',
			'An account with this pen name already exists.',
			value => {
				if (!value.trim()) {
					return true;
				}

				return new Promise(resolve => debouncePenNameVerification(value, resolve));
			}
		),
});

export const newPasswordValidationSchema = (values: NewPasswords) => yup.object({
	password: yup.string()
		.min(MIN_PASSWORD_LENGTH, 'Password should be at least 8 characters.')
		.matches(
			/^(?=.*[0-9]+.*)(?=.*[a-zA-Zа-яА-Я]+.*)[0-9a-zA-Zа-яА-Я\S]{2,}$/gm,
			'Password should be at least one number, one letter and no spaces.',
		)
		.required('Please enter your new password.'),
	repeated: yup.string()
		.test('compare passwords',
			'Passwords do not match.',
			value => value === values.password,
			)
		.required('Please repeat your new password.'),
});
