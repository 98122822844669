import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import {
  Flex,
  Input,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
} from '@chakra-ui/react';
import { FC, useCallback, useMemo, useState } from 'react';
import { colors } from 'shared/utils/chakra-theme/colors';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

type Props = {
  passwordShown: boolean;
  togglePasswordShow: () => void;
	htmlForId: string;
  showError?: boolean;
  handleRemoveError?: () => void;
	name: string;
	[x: string]: any;
};

export const PasswordField: FC<Props> = ({
  passwordShown,
  togglePasswordShow,
  handleRemoveError,
  htmlForId,
  showError,
  ...props
}) => {
	const [field, meta, helpers] = useField(props);
	const { t } = useTranslation();
	const [focus, setFocus] = useState(false);
	const isInvalid = useMemo(() => {
		return meta.touched && meta.error;
	}, [meta]);

	const handleFocus = useCallback(() => {
    helpers.setError(undefined);
		setFocus(curr => !curr);

    if (handleRemoveError) {
      handleRemoveError();
    }
	}, [helpers, handleRemoveError]);

	return (
		<Flex
      pos="relative"
      direction="column"
    >
      <InputGroup>
        <Input
          type={passwordShown ? 'text' : 'password'}
          placeholder="Password"
          variant={isInvalid && !focus && showError ? 'loginFieldError' : 'loginField'}
          id={htmlForId}
          {...field}
          {...props}
          onFocus={handleFocus}
          onBlur={(e) => {
            handleFocus();
            field.onBlur(e);
          }}
        />

        <InputRightElement
          right="18px"
          top="12px"
          w="24px"
          h="24px"
        >
          <Icon
            as={passwordShown ? AiOutlineEye : AiOutlineEyeInvisible}
            fill={colors['primary-violet-100']}
            w="24px"
            h="24px"
            cursor="pointer"
            onClick={togglePasswordShow}
          />
        </InputRightElement>
      </InputGroup>

			{isInvalid && !focus && showError && (
				<Text
					size="poppins14"
					variant="light-text-text-danger"
					lineHeight='20px'
					pos="absolute"
					bottom="-4px"
					transform="translateY(100%)"
          data-testid="logIn__password-error"
				>
					{t(meta.error ? meta.error[0] : '')}
				</Text>
			)}
		</Flex>
	);
};
