import { Flex, Heading, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { GoogleButton } from './GoogleButton';
// import { FacebookButton } from './FacebookButton';
import { LogInForm } from './LogInForm';
import { SignUpLink } from './SignUpLink';
import LoadingSpinner from 'components/UI/LoadingSpinner';
import { LoginCredentials } from 'utils/types/user';

type Props = {
	loginViaGoogleHandler: () => void;
  loginViaFacebookHandler: () => void;
  handleLogin: (values: LoginCredentials) => void;
  isLoginLoading: boolean;
};

export const LogIn: FC<Props> = ({
  loginViaGoogleHandler,
  // loginViaFacebookHandler,
  handleLogin,
  isLoginLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      direction="column"
      p="72px 100px 44px"
      align="center"
      borderRadius="8px"
      boxShadow="0px 5px 15px 0px rgba(36, 28, 88, 0.20)"
      bg={colors.white}
      maxW="600px"
      w="100%"
      pos="relative"
    >
      {isLoginLoading && <LoadingSpinner />}

      <Heading
        size="poppins25"
        variant="primary-black-text"
        mb="56px"
      >
        {t('Welcome to Passion Creators!')}
      </Heading>

      <GoogleButton loginViaGoogleHandler={loginViaGoogleHandler} />

      {/* <FacebookButton loginViaFacebookHandler={loginViaFacebookHandler} /> */}

      <Text
        size="poppins18"
        variant="primary-black-text"
        mb="13px"
      >
        {t('OR')}
      </Text>

      <LogInForm
        handleLogin={handleLogin}
      />

      <SignUpLink />
    </Flex>
  );
};
