import { colors } from '../colors';

export const googleButton = {
	width: '100%',
	padding: '22px 111px',
	border: '1px solid #d9d8d8',
	borderRadius: '8px',
	backgroundColor: colors.white,
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '100%',
	color: colors['button-black'],
	fontFamily: `'Poppins', sans-serif`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '13px',
	marginBottom: '12px',
	':hover': {
		backgroundColor: colors.white,
	},
};

export const facebookButton = {
	...googleButton,
	color: colors.white,
	backgroundColor: colors.facebook,
	':hover': {
		backgroundColor: colors.facebook,
	},
};

export const defaultButton = {
	width: '204px',
	borderRadius: '8px',
	height: '48px',
	backgroundColor: colors['primary-violet-100'],
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '20px',
	color: colors.white,
	fontFamily: `'Poppins', sans-serif`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'all 0.3s easy-out',
	':hover': {
		backgroundColor: colors['button-hover'],
	},
	':focus-visible': {
		outline: 'none',
		boxShadow: 'none',
	},
	":disabled": {
		opacity: 1,
		backgroundColor: colors['primary-gray-60'],
		':hover': {
			backgroundColor: colors['primary-gray-60'],
		},
	}
};

export const closeAllButton = {
	width: 'fit-content',
	borderRadius: '8px',
	p: '0 24px',
	h: '44px',
	backgroundColor: colors['primary-blue-100'],
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '20px',
	color: colors.white,
	fontFamily: `'Poppins', sans-serif`,
	display: 'flex',
	gap: '12px',
	alignItems: 'center',
	justifyContent: 'center',
	transition: 'all 0.3s easy-out',
	':hover': {
		backgroundColor: colors['primary-blue-120'],
	},
	':focus-visible': {
		outline: 'none',
		boxShadow: 'none',
	},
	":disabled": {
		opacity: 1,
		backgroundColor: colors['primary-gray-60'],
		':hover': {
			backgroundColor: colors['primary-gray-60'],
		},
	}
};

export const outlineButton = {
	width: '204px',
	padding: '12px 59px',
	borderRadius: '8px',
	border: `1px solid ${colors['primary-violet-100']}`,
	height: '48px',
	backgroundColor: colors.white,
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '20px',
	letterSpacing: '-0.016px',
	color: colors['primary-violet-100'],
	fontFamily: `'Poppins', sans-serif`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	outline: 'unset',
	transition: 'all 0.3s easy-out',
	':hover': {
		borderColor: colors['button-hover'],
		color: colors['button-hover'],
	},
	':focus-visible': {
		outline: 'none',
  	boxShadow: 'none',
	},
	":disabled": {
		opacity: 1,
		backgroundColor: colors['primary-gray-60'],
		color: colors.white,
		borderColor: colors['primary-gray-60'],
		':hover': {
			backgroundColor: colors['primary-gray-60'],
		},
	}
};

export const textButton = {
	width: '204px',
	padding: '12px 59px',
	height: '48px',
	backgroundColor: colors.white,
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '20px',
	letterSpacing: '-0.016px',
	color: colors['primary-violet-100'],
	fontFamily: `'Poppins', sans-serif`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	outline: 'unset',
	transition: 'all 0.3s easy-out',
	':hover': {
		color: colors['button-hover'],
	},
	':focus-visible': {
		outline: 'none',
  	boxShadow: 'none',
	},
	":disabled": {
		opacity: 1,
		backgroundColor: colors['primary-gray-60'],
		color: colors.white,
		':hover': {
			backgroundColor: colors['primary-gray-60'],
		},
	}
};
