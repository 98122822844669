import { useField } from 'formik';
import {
  Flex,
  Input,
	Text,
} from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	htmlForId: string;
	placeholder: string;
	handleRemoveError: () => void;
	name: string;
	[x: string]: any;
};

export const TextInput: FC<Props> = ({
  htmlForId,
	handleRemoveError,
	placeholder,
  ...props
}) => {
	const [field, meta, helpers] = useField(props);
	const { t } = useTranslation();
	const isInvalid = useMemo(() => {
		return meta.touched && !Boolean(meta.error?.includes('Required'));
	}, [meta]);

	const isPenNameField = props.name === 'pen_name';

	const handleFocus = useCallback(() => {
		helpers.setError(undefined);
		handleRemoveError();
	}, [helpers, handleRemoveError]);

	return (
		<Flex
      pos='relative'
      direction='column'
			width="400px"
    >
      <Input
				type="text"
				placeholder={placeholder}
				variant="loginField"
				id={htmlForId}
				onFocus={handleFocus}
				{...field}
				onBlur={e => {
					helpers.setValue(e.target.value.trim());
					field.onBlur(e);
				}}
				{...props}
			/>

			{isInvalid && isPenNameField && (
				<Text
					size="poppins14"
					variant="light-text-text-danger"
					lineHeight='20px'
					pos="absolute"
					bottom="-4px"
					transform="translateY(100%)"
				>
					{t(meta.error ? meta.error[0] : '')}
				</Text>
			)}
		</Flex>
	);
};
