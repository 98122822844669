const monthNames = [
	'Jan',
	'Feb',
	'Mar',
	'Apr',
	'May',
	'Jun',
	'Jul',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

const MILISEC_IN_SEC = 1000;
const SEC_IN_MIN = 60;
const MIN_IN_HOUR = 60;
const HOUR_IN_DAY = 24;
const DAY_IN_MONTH = 30;
const MONTH_IN_YEAR = 12;
const TWO = 2;

export const localeDateFormatter = date => {
	const inputDate = new Date(date);
	const dayMonthYear = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }).format(inputDate);
  const time = inputDate.getHours() * 60 + inputDate.getMinutes();
	const hours = Math.trunc(time / 60);
	const minutes = time - hours * 60;

  return `${dayMonthYear}, ${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export const notificationDateFormatter = date => {
	const inputDate = new Date(date);
	const dateNow = new Date();

	const parsedInputDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  }).format(inputDate);

	const time = inputDate.getHours() * 60 + inputDate.getMinutes();
	const hours = Math.trunc(time / 60);
	const minutes = time - hours * 60;

	const parsedNowDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  }).format(dateNow);

	if (parsedInputDate === parsedNowDate) {
		return `Today, ${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
	} else {
		const day = inputDate.getDate('numeric');
		const month = inputDate.getMonth() + 1;
		const year = inputDate.getFullYear();

		return `${day > 9 ? day : `0${day}`}.${month > 9 ? month : `0${month}`}.${year}`;
	}
};

export const updateTimeFormatter = date => {
	const inputDate = new Date(date);
  const time = inputDate.getHours() * 60 + inputDate.getMinutes();
	const hours = Math.trunc(time / 60);
	const minutes = time - hours * 60;

  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
};

export const localeChapterDateFormatter = date => {
	const inputDate = new Date(date);
	const day = inputDate.getDate('numeric');
	const month = inputDate.getMonth() + 1;
	const year = inputDate.getFullYear();
  const time = inputDate.getHours() * 60 + inputDate.getMinutes();
	const hours = Math.trunc(time / 60);
	const minutes = time - hours * 60;
	const seconds = inputDate.getSeconds();

  return `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}:${seconds > 9 ? seconds : `0${seconds}`} | ${Number(day) > 9 ? day : `0${day}`}.${Number(month) > 9 ? month : `0${month}`}.${year}`;
};

export const chaptersDateFormatter = date => {
	const inputDate = new Date(date);
	const parsedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  }).format(inputDate).split('/');

  return `${Number(parsedDate[1]) > 9 ? parsedDate[1] : `0${parsedDate[1]}` }.${Number(parsedDate[0]) > 9 ? parsedDate[0] : `0${Number(parsedDate[0])}`}.${parsedDate[2]}`;
};

export const splitDate = date => {
	const inputDate = new Date(date);
	const parsedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: 'numeric',
  }).format(inputDate).split('/');

  return ({
		year: +parsedDate[2],
		month: +parsedDate[0],
		day: +parsedDate[1],
	});
};

export const dateFormatter = date => {
	const dateObj = new Date(date);
	return `${
		monthNames[dateObj.getMonth()]
	} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
};

export const dateFormatterWithTime = date => {
	const dateObj = new Date(date);
	return `${dateObj.toLocaleTimeString()} ${
		monthNames[dateObj.getMonth()]
	}, ${dateObj.getDate()} ${dateObj.getFullYear()}`;
};

export const dateModeratorFormater = lastUpdateDate => {
	const dateNow = new Date();
	const dateLastUpdated = new Date(lastUpdateDate);
	const diffTime = Math.abs(dateNow - dateLastUpdated);
	const diffDays = Math.ceil(
		diffTime / (MILISEC_IN_SEC * SEC_IN_MIN * MIN_IN_HOUR * HOUR_IN_DAY),
	);
	const diffMonths = Math.floor(diffDays / DAY_IN_MONTH);
	const diffYears = Math.floor(diffMonths / MONTH_IN_YEAR);

	if (diffYears) {
		if (diffYears < TWO) return diffYears + ' year ago';
		return diffYears + ' years ago';
	}
	if (diffMonths) {
		if (diffMonths < TWO) return diffMonths + ' month ago';
		return diffMonths + ' months ago';
	}
	if (diffDays) {
		if (diffDays < TWO) return diffDays + ' day ago';
		return diffDays + ' days ago';
	}
};
