import { useMutation } from '@apollo/client';
import LoadingSpinner from 'components/UI/LoadingSpinner';
import { VERIFY_USER_EMAIL } from 'entites/user/verify-user-email.graphql';
import useShowToast from 'hooks/useShowToast';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const REDIRECT_TIMEOUT = 3500;

const VerificationPage = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { token } = useParams();
	const {
		showErrorToast,
		showSuccessToast,
	} = useShowToast();
	const navigate = useNavigate();

	const [canVerifyUserEmail] = useMutation(VERIFY_USER_EMAIL);

	const verifyUserEmail = useCallback(async () => {
		const { data: {
			response,
		}} = await canVerifyUserEmail({
			variables: { token },
		});

		if (response.error) {
			showErrorToast({
				id: "emailValidationError",
				title: "Sorry something went wrong",
				reason: response.error,
			});
			setIsLoading(false);
			setTimeout(() => {
				navigate('writer');
			}, REDIRECT_TIMEOUT);
		}

		if (response.success) {
			showSuccessToast({
				id: "successfullyValidateMessage",
				title: `Success`,
				description: response.success,
				img: "/images/story/cancel.png",
			});
			setIsLoading(false);
			setTimeout(() => {
				navigate('writer');
			}, REDIRECT_TIMEOUT);
		}
	}, [canVerifyUserEmail, token]);

	useEffect(() => {
		if (token) {
			verifyUserEmail();
		}
	}, [verifyUserEmail, token]);

	return <>{isLoading && <LoadingSpinner />}</>;
};

export default VerificationPage;
