import { gql } from '@apollo/client';

// #region STORY LOCALES

export const GET_BOOK_LOCALES = gql`
	query BookLocales(
		$book_id: String!
	) {
		list: bookLocalesAdmin(
			book: { book_id: $book_id },
		) {
			locales
		}
	}
`;

export const GET_BOOK_CHAPTERS_LOCALES = gql`
	query ChapterLocales(
		$book_id: String!
	) {
		list: chapterLocalesAdmin(
			book: { book_id: $book_id },
		) {
			chapter_id
			localeLink
			localeTitle
			originLink
			originTitle
		}
	}
`;

export const UPDATE_LOCALE_CHAPTER = gql`
	mutation UpdateLocalizedChapter($chapter: UpdateLocalizedChapter!) {
		chapter: updateLocalizedChapter(chapter: $chapter) {
			chapter_id
			title
			cdn_link
			bucket_link
		}
	}
`;

export const REMOVE_LOCALE_CHAPTER = gql`
	mutation RemoveLocalizedChapter($chapter: RemoveLocalizedChapter!) {
		chapter: removeLocalizedChapter(chapter: $chapter) {
			chapter_id
		}
	}
`;

export const UPDATE_LOCALE_STORY = gql`
	mutation UpdateLocalizedChapter($book: UpdateLocalizedBook!) {
		book: updateLocalizedBook(book: $book) {
			book_id
		}
	}
`;

export const DELETE_STORY_LOCALE = gql`
	mutation RemoveLocalizedBook($book: RemoveLocalizedBook!) {
		book: removeLocalizedBook(book: $book) {
			book_id
		}
	}
`;

export const DELETE_FULL_STORY_LOCALE = gql`
	mutation FullRemoveLocalizedBook($book: RemoveLocalizedBook!) {
		book: fullRemoveLocalizedBook(book: $book) {
			book_id
		}
	}
`;

// #endregion

// #region GENRES LOCALES

export const GET_GENRES_LOCALES = gql`
	query GetLocalizedGenres {
		items: getLocalizedGenres {
			genre_id
			title
			locales
		}
	}
`;

export const UPDATE_LOCALE_GENRE = gql`
	mutation UpdateLocalizedGenre($genre: UpdateLocalizedGenre!) {
		item: updateLocalizedGenre(genre: $genre) {
			genre_id
		}
	}
`;

export const REMOVE_LOCALE_GENRE = gql`
	mutation RemoveLocalizedGenre($genre: RemoveLocalizedGenre!) {
		item: removeLocalizedGenre(genre: $genre) {
			genre_id
		}
	}
`;

// #endregion

// #region TAGS LOCALES

export const GET_TAGS_LOCALES = gql`
	query GetLocalizedTags {
		items: getLocalizedTags {
			tag_id
			title
			locales
		}
	}
`;

export const UPDATE_LOCALE_TAG = gql`
	mutation UpdateLocalizedTag($tag: UpdateLocalizedTag!) {
		item: updateLocalizedTag(tag: $tag) {
			tag_id
		}
	}
`;

export const REMOVE_LOCALE_TAG = gql`
	mutation RemoveLocalizedTag($tag: RemoveLocalizedTag!) {
		item: removeLocalizedTag(tag: $tag) {
			tag_id
		}
	}
`;

// #endregion

// #region SECTIONS LOCALES

export const GET_SECTIONS_LOCALES = gql`
	query GetLocalizedLists {
		items: getLocalizedLists {
			list_id
			title
			locales
		}
	}
`;

export const UPDATE_LOCALE_SECTION = gql`
	mutation UpdateLocalizedList($list: UpdateLocalizedList!) {
		item: updateLocalizedList(list: $list) {
			list_id
		}
	}
`;

export const REMOVE_LOCALE_SECTION = gql`
	mutation RemoveLocalizedList($list: RemoveLocalizedList!) {
		item: removeLocalizedList(list: $list) {
			list_id
		}
	}
`;

// #endregion

// #region APPLICATIONS LOCALES

export const GET_ISO_LOCALES = gql`
	query AvailableISOLanguages {
		data: availableISOLanguages {
			locales
		}
	}
`;

export const GET_APP_LOCALES = gql`
	query AvailableLocales {
		data: availableLocales {
			code
		}
	}
`;

export const ADD_APP_LOCALE = gql`
	mutation AddNewLocale($locale: LocaleDto!) {
		item: addNewLocale(locale: $locale) {
			code
		}
	}
`;

export const REMOVE_APP_LOCALE = gql`
	mutation DeleteLocale($locale: LocaleIdentity!) {
		result: deleteLocale(locale: $locale)
	}
`;

// #endregion