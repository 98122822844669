import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';
import { AdminProvider } from 'context/AdminContext';
import { UserProvider } from 'context/UserContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { NotificationsLayout } from './NotificationsLayout';

const AdminLayout: FC = () => {
  return (
    <UserProvider>
      <AdminProvider>
        <NotificationsProvider>
          <Flex
            w="100vw"
            h="100vh"
            pos="relative"
            overflow="hidden"
          >
            <Outlet />

            <NotificationsLayout />
          </Flex>
        </NotificationsProvider>
      </AdminProvider>
    </UserProvider>
  );
};

export default AdminLayout;