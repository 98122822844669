import { colors } from '../colors';
import { webKitStyle } from './input';

export const selectStyles = {
	baseStyle: {},
	variants: {
		default: {
			icon: {
				right: '-4px',
				fontSize: '21px',
			},
			field: {
				p: '0 0 4px 4px',
				h: '26.3px',
				width: '100%',
				fontSize: '14px',
				lineHeight: '110%',
				borderRadius: 'none',
				color: '#0b080f',
				borderBottom: '1px solid #dbdbdb',
				_hover: {
					outline: 'none',
					borderBottom: '1px solid var(--color--blue)',
				},
				_autofill: webKitStyle,
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
			},
		},
		contract: {
			icon: {
				right: '10px',
				fontSize: '21px',
			},
			field: {
				h: '72px',
				p: '20px 44px 20px 18px',
				width: '500px',
				fontSize: '14px',
				lineHeight: '110%',
				borderRadius: '5px',
				color: colors['primary-black-text'],
				bg: colors['primary-violet-10'],
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
				border: '1px solid #dbdbdb',
				_hover: {
					outline: 'none',
					border: '1px solid var(--color--blue)',
				},
				_placeholder: {
					color: colors['primary-gray-100'],
					fontFamily: `'Poppins', sans-serif`,
					fontSize: '18px',
					lineHeight: '32px',
					letterSpacing: '-0.018px',
				},
				_autofill: webKitStyle,
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
			},
		},
	},
	defaultProps: {
		variant: 'default',
	},
};
