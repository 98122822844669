import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import CheckBoxIcon from 'components/Icons/ADMIN/CheckBoxIcon';
import FilledCheckBoxIcon from 'components/Icons/ADMIN/FilledCheckBoxIcon';
import { UserContext } from 'context/UserContext';
import { CSSProperties, FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'shared/utils/chakra-theme/colors';
import { notificationDateFormatter } from 'utils/helpers/date/dateFormatter';
import { ContractRejectFullProps, NotifToastProps, RejectShortProps } from '../../../../../utils/types/notifications/templates';
import { OneColorIcon } from '../../Story/Icon';
import { useNavigate } from 'react-router-dom';
import { UserBook } from 'utils/types/books/Books';
import { StoriesContext } from 'context/StoriesContext';
import { ToastWrapper } from '../../UI/Wrapper/ToastWrapper';
import { BodyWithBook } from '../../UI/Toast/BodyWithBook';

export const ReviewContractRejectToast: FC<Required<NotifToastProps>> = ({
  id,
  title,
  handleClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReadFull = () => {
    navigate(`/writer/notifications/${id}`);
  };

  return (
    <ToastWrapper
      handleClose={() => handleClose(id)}
    >
      <Box mr="12px">
        <OneColorIcon
          img="/images/notifications/story/reject.svg"
          color={'linear-gradient(180deg, #FC2762 0%, #C41241 100%)'}
        />
      </Box>

      <BodyWithBook
        title={t('Story rejecting')}
        textA={t('Unfortunately, your book ')}
        textB={t(' has been rejected by the moderator. Here`s our rejection reason...')}
        bookTitle={title}
        handleReadFull={handleReadFull}
      />
    </ToastWrapper>
  );
};

export const ReviewContractRejectShort: FC<RejectShortProps> = ({
  id,
  isSelected,
  user_readed,
  title,
  createdAt,
  toggleSelect,
  handleOpenNotification,
  reject_reason,
}) => {
  const { t } = useTranslation();
  const [bg, border] = useMemo(() => {
    if (isSelected) {
      return ['rgba(229, 243, 255, 0.25)', colors['primary-blue-100']];
    }

    return user_readed ? [colors.white, colors['primary-gray-30']] : [colors['notification-new'], colors['primary-violet-100']];
  }, [isSelected, user_readed]);

  const date = notificationDateFormatter(createdAt);

  return (
    <Flex
      p="24px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      borderRadius="6px"
      w="100%"
      border={`1px solid ${border}`}
      bg={bg}
    >
      <Box
        p="0 12px"
        mr="8px"
        cursor="pointer"
        onClick={() => toggleSelect(id, isSelected)}
      >
        {isSelected ? FilledCheckBoxIcon() : CheckBoxIcon()}
      </Box>

      <Box mr="24px">
        <OneColorIcon
          img="/images/notifications/story/reject.svg"
          color={'linear-gradient(180deg, #FC2762 0%, #C41241 100%)'}
        />
      </Box>

      <Flex
        gap="12px"
        direction="column"
        width="100%"
        cursor="pointer"
        onClick={() => handleOpenNotification(id)}
      >
        <Flex
          justify="space-between"
          align="center"
        >
          <Heading
            size="poppins24"
            variant="primary-black-text"
            lineHeight="38px"
          >
            {t('Story rejecting')}
          </Heading>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {date}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
          maxW="670px"
          display='-webkit-box'
          sx={{
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
          } as CSSProperties}
          overflow="hidden"
          textOverflow="ellipsis"
          wordBreak="break-word"
        >
          {t('Unfortunately, your book ')}
          <Text
            as="span"
            variant="primary-black-text"
            size="poppins16normal"
            fontWeight={600}
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            {t(title)}
          </Text>
          {` has been rejected by the moderator. Here\`s our rejection reason: ${reject_reason}`}
        </Text>
      </Flex>
    </Flex>
  );
};

export const ReviewContractRejectFull: FC<ContractRejectFullProps> = ({
  title,
  createdAt,
  book_id,
  reject_reason
}) => {
  const [book, setBook] = useState<UserBook | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const date = notificationDateFormatter(createdAt);
  const { user } = useContext(UserContext);
  const {
    books,
    handleBookById,
  } = useContext(StoriesContext);

  async function getBookData(book_id: string) {
    const bookFromStore = await handleBookById(book_id);

    setBook(bookFromStore);
  };

  const handleCheckStatus = () => {
    if (!!book?.book_contract?.external_contract_description) {
      navigate(`/writer/story/${book_id}/contract/published?step=4`);
    } else {
      navigate(`/writer/story/${book_id}/contract/not-published?step=4`);
    }
  };

  useEffect(() => {
    if (book_id) {
      getBookData(book_id);
    }
  }, [book_id, books]);

  return (
    <Flex
      p="24px 24px 44px"
      align="center"
      pos="relative"
      boxShadow="0px 2px 5px 0px rgba(37, 85, 255, 0.05)"
      bg={colors.white}
      borderRadius="6px"
      w="100%"
      border={`1px solid ${colors['primary-gray-30']}`}
      gap="24px"
      direction="column"
      h="fit-content"
    >
      <Flex
        justify="space-between"
        pb="24px"
        borderBottom={`1px solid ${colors['primary-gray-30']}`}
        w="100%"
        align="center"
      >
        <Flex gap="24px" align="center">
          <Box>
            <OneColorIcon
              img="/images/notifications/story/reject.svg"
              color={'linear-gradient(180deg, #FC2762 0%, #C41241 100%)'}
            />
          </Box>

          <Text
            variant="primary-black-text"
            size="poppins16normal"
            lineHeight="28px"
            letterSpacing="-0.01em"
          >
            <Text
              as="span"
              variant="primary-black-text"
              size="poppins16normal"
              fontWeight={800}
              lineHeight="28px"
              letterSpacing="-0.01em"
            >
              {t('Passion Team')}
            </Text>
            {' <support@passionapp.co>'}
          </Text>
        </Flex>

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
          letterSpacing="-0.01em"
        >
          {date}
        </Text>
      </Flex>

      <Image
        w="80px"
        h="80px"
        src="/images/admin/stamp.png"
        loading="eager"
        alt="new story reject"
      />

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="36px"
        letterSpacing={0}
        fontWeight={400}
      >
        {t(`Hi, ${user?.first_name}!`)}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins24"
        lineHeight="38px"
        letterSpacing={0}
        fontWeight={400}
        textAlign="center"
        maxW="600px"
      >
        {t('Unfortunately, your book')}
        <br />
        <Text
          as="span"
          variant="primary-black-text"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={600}
        >
          {t(title)}
        </Text>
        <br />
        <Text
          as="span"
          variant="light-text-text-danger"
          size="poppins24"
          lineHeight="36px"
          letterSpacing={0}
          fontWeight={500}
        >
          {t('has been rejected by the moderator')}
        </Text>
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        letterSpacing={0}
        textAlign="center"
        maxW="578px"
      >
        <Text
          as="span"
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="32px"
          letterSpacing={0}
          fontWeight={600}
        >
          {t('Here`s our rejection reason:')}
        </Text>
        <br />
        {t(reject_reason)}
      </Text>

      <Text
        variant="primary-black-text"
        size="poppins20normal"
        lineHeight="32px"
        letterSpacing={0}
        textAlign="center"
        maxW="578px"
      >
        {t('Please, make changes to your data and resubmit for review')}
      </Text>

      <Button
        variant="defaultButton"
        onClick={handleCheckStatus}
      >
        {t('Check status')}
      </Button>

      <Flex align="center" direction="column">
        <Image
          w="40px"
          h="40px"
          src="/images/hero/heart.svg"
          loading="eager"
          alt="heart"
        />

        <Text
          variant="primary-black-text"
          size="poppins16normal"
          lineHeight="28px"
        >
          {t('Best Regards,')}
        </Text>

        <Text
          variant="primary-black-text"
          size="poppins20normal"
          lineHeight="28px"
          fontWeight={500}
        >
          {t('Passion Team')}
        </Text>
      </Flex>
    </Flex>
  );
};
