export const getFromLocalStorage = (key: string): string => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key) ?? '');
  }

  return localStorage.getItem(key) ?? '';
};

export const setInLocalStorage = (key: string, item: any) => {
  localStorage.setItem(key, JSON.stringify(item));
};
