import { LoginCredentials, NotificationUser } from 'utils/types/user';

export const initialLogInValues: LoginCredentials = {
  email: '',
  password: '',
};

export const AuthenticateUserType = Object.freeze({
	LOGIN: 'login',
	REGISTER: 'register',
});

export const MAX_DESCRIPTION_LENGTH = 1000;
export const AVATAR_IMAGE_WEIGHT = 2097152 * 5;
export const ASPECT_RATIO = 100;

export const selectAll: NotificationUser = {
  user_id: 'select-all',
  first_name: 'Send',
  last_name: ' to all',
  email: '',
};
